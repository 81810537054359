import React from "react"
import { useField } from "formik"

import { InputSelect, Input } from "../.."
import { inputSelectType, reactSelectOptionType } from "../../../../../types"

type Props = inputSelectType & {
  resetFieldValue: string
}

export const InputSelectOther = ({ name, label, options, ...props }: Props) => {
  const [field, meta, helpers] = useField(name)
  const [otherShown, setOtherShown] = React.useState(false)

  React.useEffect(() => {
    if (field.value === "Other") {
      setOtherShown(true)
      helpers.setValue("")

      if (meta.error) {
        helpers.setError("")
      }
    } else if (
      options.some(
        (option: reactSelectOptionType) => field.value === option.value
      )
    ) {
      setOtherShown(false)
    }
  }, [options, meta.error, helpers, field.value])
  return (
    <>
      <InputSelect
        name={name}
        label={label}
        options={[...options, { value: "Other", label: "Other" }]}
        {...props}
      />
      {otherShown ? <Input name={name} label={`'Other' ${label}`} /> : <div />}
    </>
  )
}
