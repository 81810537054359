import React from "react"
import ReactSelect from "react-select"
import { useField } from "formik"

import { Label } from "../.."
import { buttonGroupOptionType, inputSelectType } from "../../../../../types"

export const InputSelect = ({
  children = null,
  label,
  loading = false,
  name,
  optional = false,
  options,
  placeholder,
}: inputSelectType) => {
  const [field, meta, helpers] = useField(name)

  return (
    <div className={meta.touched && meta.error ? " form-error" : ""}>
      <Label field={name} optional={optional} extras={children}>
        {label}
      </Label>
      <ReactSelect
        isLoading={loading}
        placeholder={placeholder ? placeholder : "Please select..."}
        styles={{
          option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isSelected
              ? "#ffae33 !important"
              : state.isFocused
              ? "#f2f2f5"
              : "white",
          }),
        }}
        {...field}
        type="select"
        className={`input-select${
          meta.touched && meta.error ? " input-select-error" : ""
        }`}
        classNamePrefix="react-select"
        options={options}
        onChange={(opt: buttonGroupOptionType) => helpers.setValue(opt.value)}
        value={options.find(
          (opt: buttonGroupOptionType) => opt.value === field.value
        )}
      />
      <span className="input-error-text">{meta.touched ? meta.error : ""}</span>
    </div>
  )
}
