import React from "react"
import PropTypes from "prop-types"

import { Tag } from ".."

export const ResultTypeCard = ({
  illustration,
  title,
  paragraph,
  note,
  children,
}) => (
  <section className="result-type-card">
    <div className="result-type-card-illustration">{illustration}</div>
    <h2 className="heading-small">{title}</h2>
    <p className="result-type-card-paragraph">{paragraph}</p>
    {note && <Tag type="info">{note}</Tag>}
    <div className="result-type-card-buttons">{children}</div>
  </section>
)

ResultTypeCard.defaultProps = {
  note: "",
}

ResultTypeCard.propTypes = {
  illustration: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  note: PropTypes.string,
  children: PropTypes.node.isRequired,
}
