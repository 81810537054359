import { immunizationRecordType } from "../../../types"
import { immunizations } from "../../../constants"

export const formatImmunizationDoseAsText = ({
  immunizationRecord,
}: {
  immunizationRecord: immunizationRecordType
}) => {
  const immunization = immunizations.find(
    (immunization) =>
      immunization.diseaseTargeted === immunizationRecord.diseaseTargeted
  )
  const vaccine = immunization?.vaccines?.find(
    (vaccine) => vaccine.name === immunizationRecord.name
  )
  const vaccineManufacturer = vaccine?.manufacturers.find(
    (manufacturer) =>
      manufacturer.name === immunizationRecord.vaccineManufacturer
  )

  if (immunizationRecord.dose) {
    return `${immunizationRecord.dose} of ${vaccineManufacturer?.doses}`
  } else if (immunizationRecord.booster) {
    return "Booster"
  }
}
