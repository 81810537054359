import React from "react"
import PropTypes from "prop-types"
import moment from "moment-timezone"
import Calendar from "react-calendar"
import axios from "axios"

import {
  Button,
  FormLegend,
  FormSection,
  ModalBody,
  ModalFooter,
} from "../../../../shared"
import { handleAPIError, convertDateToTimezone } from "../../../../../utilities"
import { Slot } from "./Slot"

export const Step2 = ({ data, setData, addToast }) => {
  const earliestPossibleTestApp = moment(data.data.departureDateTime).subtract(
    data.data.destinationTravelWindow,
    "hours"
  )

  const [loading, setLoading] = React.useState(false)
  const [calendar, setCalendar] = React.useState({
    date: data.data.appointmentDate,
    month: data.data.departureDate,
    loading: false,
  })
  const [slots, setSlots] = React.useState({
    options: [],
    selected: {
      start: data.data.appointment.start,
      end: data.data.appointment.end,
    },
    loading: false,
    error: "",
  })

  const onSubmit = async () => {
    if (slots.selected.start === "" || slots.selected.end === "") {
      setSlots((slots) => ({
        ...slots,
        error: "Please choose an appointment",
      }))
    } else {
      setLoading(true)

      try {
        const response = await axios({
          method: "post",
          url: "/appointment/create",
          data: {
            appointment: {
              patient: data.data.patient.id,
              startTime: slots.selected.start.toISOString(),
              endTime: slots.selected.end.toISOString(),
              forTravel: data.data.forTravel,
              departureDateTime: data.data.departureDateTime,
              destinationTravelWindow:
                data.data.destinationTravelWindow.toString(),
            },
          },
        })
        setData((data) => ({
          step: data.step + 1,
          data: {
            ...data.data,
            appointmentDate: calendar.date,
            appointment: response.data,
          },
        }))
      } catch (error) {
        setLoading(false)
        addToast(handleAPIError(error, "save appointment"), {
          appearance: "error",
        })
      }
    }
  }

  const checkIfUnavailable = (date) => {
    const today = new Date()

    // buildSlots({ date }).length === 0 ||

    if (data.data.forTravel) {
      // Date is after or same as earliestPossibleDate && date is before or same as departureDate
      if (
        (moment(date).isAfter(earliestPossibleTestApp, "day") ||
          moment(date).isSame(earliestPossibleTestApp, "day")) &&
        (moment(date).isBefore(data.data.departureDateTime, "day") ||
          moment(date).isSame(data.data.departureDateTime, "day"))
      ) {
        if (moment(today).isAfter(date, "day")) {
          // Date is in the past
          return true // Unavailable
        } else {
          return false // Available
        }
      } else {
        return true // Unavailable
      }
    } else {
      if (
        //calendar.month.getMonth() !== date.getMonth() || // Date is not in current calendar month
        moment(today).isAfter(date, "day")
      ) {
        // Date is in the past
        return true // Unavailable
      } else {
        return false // Available
      }
    }
  }

  const updateSlots = async ({ date }) => {
    const daySlots = buildSlots({ date })

    setSlots((slots) => ({
      ...slots,
      options: daySlots,
    }))
  }

  const withinOpenHours = (datetime) => {
    const datetimeHours = convertDateToTimezone(
      datetime,
      "America/New_York"
    ).getHours() // Reflective hours in NY compared with datetime
    const datetimeMinutes = convertDateToTimezone(
      datetime,
      "America/New_York"
    ).getMinutes() // Minutes left over from reflective NY hours

    if (datetimeHours >= 7 && datetimeHours <= 18) {
      if (datetimeHours === 7 && datetimeMinutes <= 20) {
        return false
      } else if (datetimeHours === 18 && datetimeMinutes >= 40) {
        return false
      } else {
        // Is within opening hours
        return true
      }
    } else {
      return false
    }
  }

  const buildSlots = ({ date }) => {
    const slotLength = 40 // time in minutes
    let start = new Date(date)
    const end = new Date(date.setHours(23, 59))
    let newSlots = []

    while (end > start) {
      start = new Date(start.getTime() + slotLength * 60 * 1000)

      if (start > new Date() && withinOpenHours(start)) {
        // Date is in the future
        if (data.data.forTravel) {
          // start + slotLength = appointment end time, if appointment end time is earlier than departureTime
          // if slot is after earliestPossibleTestApp
          if (
            moment(start).add(slotLength, "minutes") <
              data.data.departureDateTime &&
            moment(start).isAfter(earliestPossibleTestApp)
          ) {
            newSlots.push({
              start: start,
              end: moment(start).add(slotLength, "minutes"),
            })
          }
        } else {
          newSlots.push({
            start: start,
            end: moment(start).add(slotLength, "minutes"),
          })
        }
      }
    }
    return newSlots
  }

  return (
    <>
      <ModalBody>
        <FormLegend>
          <p>
            Our video verification service is available 7am - 7pm (Eastern Time)
          </p>
          {data.data.forTravel && data.data.departureDateTime && (
            <>
              <p>
                We'd recommend booking your test between{" "}
                {`${moment(earliestPossibleTestApp).format(
                  "MM/DD/YYYY hh:mm A"
                )} (${data.data.localTimeZone})`}
                {" - "}
                {`${moment(data.data.departureDateTime).format(
                  "MM/DD/YYYY hh:mm A"
                )} (${data.data.localTimeZone})`}
                <br />
              </p>
              <Button
                onClick={() =>
                  setData((data) => ({
                    step: data.step - 1,
                    data: { ...data.data },
                  }))
                }
                variant="secondary button-secondary-form-legend"
              >
                Change departure information
              </Button>
            </>
          )}
        </FormLegend>
        <FormSection>
          <div
            className={`scheduler${
              calendar.loading ? " scheduler-loading" : ""
            }`}
          >
            <Calendar
              activeStartDate={calendar.month}
              onActiveStartDateChange={(newMonth) =>
                setCalendar((calendar) => ({
                  ...calendar,
                  month: newMonth.activeStartDate,
                }))
              }
              value={calendar.date}
              onChange={(date) => {
                setCalendar((calendar) => ({
                  ...calendar,
                  date: date,
                }))
                updateSlots({ date })
              }}
              view="month"
              showFixedNumberOfWeeks={true}
              prevLabel={<span className="icon-chevron-left" />}
              nextLabel={<span className="icon-chevron-right" />}
              tileDisabled={({ date }) => checkIfUnavailable(date)}
            />
            <span className="scheduler-loader" />
          </div>
          <div
            className={`scheduler-slots${
              slots.error ? " scheduler-slots-error" : ""
            }${slots.loading ? " scheduler-slots-loading" : ""}`}
          >
            <h2 className="scheduler-slots-heading">
              {calendar.date && moment(calendar.date).format("dddd, Do MMMM")}
            </h2>
            {calendar.date && slots.options.length > 0 ? (
              <div className="slots">
                {slots.options.map((option) => (
                  <Slot
                    key={option.start + option.end}
                    selected={
                      option.start === slots.selected.start &&
                      option.end === slots.selected.end
                    }
                    onClick={() => {
                      setSlots((slots) => ({
                        ...slots,
                        selected: option,
                        error: "",
                      }))
                    }}
                  >
                    {moment(option.start).format("hh:mm A") +
                      " - " +
                      moment(option.end).format("hh:mm a")}
                    {` (${data.data.localTimeZone})`}
                  </Slot>
                ))}
                <span className="scheduler-slots-loader" />
              </div>
            ) : (
              <div className="scheduler-slots-placeholder">
                <span className="scheduler-slots-placeholder-text">
                  {calendar.date
                    ? "Sorry, there are no appointments available on the selected date"
                    : "Please choose a date to load available appointments"}
                </span>
              </div>
            )}
          </div>
          <div />
          <div className="scheduler-error">
            <span className="input-error-text">{slots.error}</span>
          </div>
        </FormSection>
      </ModalBody>
      <ModalFooter
        backFunc={() =>
          setData((data) => ({
            step: data.step - 1,
            data: { ...data.data },
          }))
        }
      >
        <Button
          type="button"
          loading={loading}
          disabled={loading}
          onClick={onSubmit}
        >
          Enter payment details
        </Button>
      </ModalFooter>
    </>
  )
}

Step2.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
}
