import React from "react"
import PropTypes from "prop-types"
import moment from "moment-timezone"

import { FormLegend } from "../../../shared"

export const AppointmentReview = ({ title, dates, patient }) => (
  <FormLegend>
    <div className="appointment-review">
      <p className="appointment-review-type">{title}</p>
      <h2 className="heading-extra-small">
        {dates.startTime && dates.endTime ? (
          <>
            {moment(dates.startTime).format("MMMM Do YYYY hh:mm A")} -{" "}
            {moment(dates.endTime).format("hh:mm A")}
            {` (${moment.tz(moment.tz.guess()).zoneAbbr()})`}
          </>
        ) : (
          "Invalid date"
        )}
      </h2>
      <p className="appointment-review-patient">{patient}</p>
    </div>
  </FormLegend>
)

AppointmentReview.propTypes = {
  title: PropTypes.string.isRequired,
  dates: PropTypes.shape({
    startTime: PropTypes.instanceOf(Date).isRequired,
    endTime: PropTypes.instanceOf(Date).isRequired,
  }).isRequired,
  patient: PropTypes.string.isRequired,
}
