import React from "react"

interface Props {
  link: string
  name: string
  icon?: string
}

export const CardUploadedFile = ({ link, name, icon = "file" }: Props) => (
  <a
    className="uploaded-file card-uploaded-file"
    href={link}
    target="_blank"
    rel="noreferrer"
  >
    <span className={`icon-${icon} uploaded-file-icon`} />
    <span className="uploaded-file-data">
      <span className="uploaded-file-data-name">{name}</span>
    </span>
  </a>
)
