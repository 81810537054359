import React from "react"

import NoImage from "../../../../assets/no-image-available.jpg"

interface Props {
  src?: string
  alt: string
}

export const CardImage = ({ src, alt }: Props) => {
  const [loaded, setLoaded] = React.useState(false)

  if (src) {
    return (
      <div className="card-image">
        <div
          className={`card-image-shape${
            loaded ? "" : " card-image-shape-loading"
          }`}
        ></div>
        <img
          src={src}
          alt={alt}
          onLoad={() => setLoaded(true)}
          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
            e.currentTarget.onerror = null
            e.currentTarget.src = NoImage
          }}
        />
      </div>
    )
  } else {
    return (
      <div className="card-image">
        <div
          className={`card-image-shape${
            loaded ? "" : " card-image-shape-loading"
          }`}
        ></div>
        <img src={NoImage} onLoad={() => setLoaded(true)} alt={alt} />
      </div>
    )
  }
}
