import React from "react"
import { Router } from "@reach/router"

import { useAuth, ProvidePlan } from "../../hooks"
import { VerifyEmail } from "./VerifyEmail"
import { CreateUserPatient } from "./CreateUserPatient"
import { ChoosePlan } from "./ChoosePlan"
import { Login, ScrollToTop } from ".."

export const CreateAccount = () => {
  const authHook = useAuth()

  return (
    <ProvidePlan>
      {authHook?.user && authHook?.creatingAccount ? (
        <Router>
          <ScrollToTop path="/">
            <VerifyEmail default path="/confirm-email" />
            <ChoosePlan path="/choose-plan" />
            <CreateUserPatient path="/create-patient" />
          </ScrollToTop>
        </Router>
      ) : (
        <Login />
      )}
    </ProvidePlan>
  )
}
