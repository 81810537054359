import { freePlanId, premiumPlanId, familyPlanId } from ".."

export const generatePlanButtonText = (
  currentPlan: number,
  planId: string,
  planName: string
) => {
  const currentPlanText = "Your current plan"
  const unknownPlanText = "Unknown plan"

  if (currentPlan === 0) {
    // Current on Free
    if (planId === freePlanId) {
      return currentPlanText
    } else if (planId === premiumPlanId || planId === familyPlanId) {
      return `Upgrade to ${planName}`
    } else {
      return unknownPlanText
    }
  } else if (currentPlan === 1) {
    // Currently on Premium
    if (planId === freePlanId) {
      return `Downgrade to ${planName}`
    } else if (planId === premiumPlanId) {
      return currentPlanText
    } else if (planId === familyPlanId) {
      return `Upgrade to ${planName}`
    } else {
      return unknownPlanText
    }
  } else if (currentPlan === 2) {
    // Currently on Family
    if (planId === freePlanId || planId === premiumPlanId) {
      return `Downgrade to ${planName}`
    } else if (planId === familyPlanId) {
      return currentPlanText
    } else {
      return unknownPlanText
    }
  } else {
    // Unknown plan
    return unknownPlanText
  }
}
