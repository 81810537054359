import React from "react"

interface Props {
  name: string
  description: string
  originalCost: number
  discountedCost: number
  variant?: "sign-up" | "manage-subscription"
  enterprise?: boolean
}

export const PlanHeader = ({
  name,
  description,
  originalCost,
  discountedCost,
  variant,
  enterprise = false,
}: Props) =>
  enterprise ? (
    <header className={`plan-header plan-header-${variant}`}>
      <div>
        <h2 className="sub-heading plan-header-title">Enterprise</h2>
        <p className="plan-header-description">
          Your employer pays for your GotTheTest subscription
        </p>
      </div>
    </header>
  ) : (
    <header className={`plan-header plan-header-${variant}`}>
      <div>
        <h2 className="sub-heading plan-header-title">{name}</h2>
        <p className="plan-header-description">{description}</p>
      </div>

      <div>
        <div className="plan-header-price">
          <span
            className={`plan-header-price-value${
              discountedCost !== 0 ? " plan-header-price-value-discounted" : ""
            }`}
          >
            ${originalCost}
          </span>
          <span className="plan-header-price-period">/mo</span>
        </div>
        {discountedCost !== 0 && (
          <div className="plan-header-price">
            <span className="plan-header-price-value plan-header-price-value-discount">
              ${discountedCost}
            </span>
            <span className="plan-header-price-period">/mo</span>
          </div>
        )}
      </div>
    </header>
  )
