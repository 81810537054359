import React from "react"
import PropTypes from "prop-types"
import TimePicker from "react-time-picker"

import { Label } from ".."

export const InputTimePicker = ({ label, name, value, setValue, hint }) => (
  <div className="input-time-picker">
    <Label field={name} hint={hint}>
      {label}
    </Label>
    <TimePicker
      value={value}
      onChange={setValue}
      clearIcon={null}
      clockIcon={null}
      disableClock={true}
      amPmAriaLabel={"Select AM/PM"}
    />
  </div>
)

InputTimePicker.defaultProps = {
  hint: "",
}

InputTimePicker.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  hint: PropTypes.string,
}
