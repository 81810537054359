import React from "react"
import { useToasts } from "react-toast-notifications"

import { Button, ButtonWithIcon, Filter } from "../.."
import { useQuery, useOnClickOutside } from "../../../../hooks"
import { handleAPIError } from "../../../../utilities"
import { DateRange } from "./DateRange"
import {
  filterStateTypeWithType,
  patientType,
  reactSelectOptionType,
} from "../../../../types"

interface Props {
  filters: filterStateTypeWithType
  setFilters: any
  setMobileFiltersVisibility: (arg0: boolean) => void
  mobileFiltersVisible: boolean
  dateRangeTouched: { before: boolean; after: boolean }
  setDateRangeTouched: any
  setTouched: (arg0: boolean) => void
  variant: "immunizations" | "diagnostics"
}

export const ResultFiltersWithType = ({
  filters,
  setFilters,
  setMobileFiltersVisibility,
  mobileFiltersVisible,
  dateRangeTouched,
  setTouched,
  setDateRangeTouched,
  variant,
}: Props) => {
  const { addToast } = useToasts()
  const { data, error, loading } = useQuery({
    url: "/patient/all",
  })
  const filtersDropdownRef = React.useRef<HTMLDivElement | null>(null)
  useOnClickOutside(filtersDropdownRef, () => setMobileFiltersVisibility(false))

  React.useEffect(() => {
    const createPatientFilters = (rawPatients: Array<patientType>) => {
      let formattedPatients: Array<reactSelectOptionType> = []

      rawPatients.forEach((patient) =>
        formattedPatients.push({ value: patient.id, label: patient.fullName })
      )
      return formattedPatients
    }

    if (data && data.length > 0) {
      setFilters((filters: filterStateTypeWithType) => ({
        ...filters,
        patient: { all: createPatientFilters(data), selected: "" },
      }))
    }
  }, [data, setFilters])

  React.useEffect(() => {
    if (error) {
      addToast(handleAPIError(error, "get all patients"), {
        appearance: "error",
      })
    }
  }, [error, addToast])

  const activeFilters =
    filters.patient.selected.value !== undefined ||
    filters?.type?.selected.value !== undefined ||
    filters.rangeStart !== null ||
    filters.rangeEnd !== null
  const emptyFilter = [{ value: "", label: "All" }]

  return (
    <div
      className={`result-filters${activeFilters ? " active" : ""}`}
      ref={filtersDropdownRef}
    >
      <div className="result-filter-button">
        <ButtonWithIcon
          onClick={() => setMobileFiltersVisibility(!mobileFiltersVisible)}
          icon="filter"
          iconPosition="left"
          variant="secondary"
        >
          Filters
        </ButtonWithIcon>
      </div>
      <div
        className={`result-filter-options${
          mobileFiltersVisible ? " active" : ""
        }`}
      >
        <Filter
          placeholder="Filter by patient"
          name="patient"
          options={emptyFilter.concat(filters.patient.all)}
          loading={loading}
          onChange={(opt) => {
            setFilters((filters: filterStateTypeWithType) => ({
              ...filters,
              patient: { ...filters.patient, selected: opt },
            }))
            setTouched(true)
          }}
          value={
            filters.patient.selected.value === undefined
              ? 0
              : filters.patient.selected
          }
        />
        {filters.type && (
          <Filter
            placeholder={`Filter by ${
              variant === "diagnostics"
                ? "test type"
                : variant === "immunizations"
                ? "manfuacturer"
                : ""
            }`}
            name="type"
            options={emptyFilter.concat(filters.type.all)}
            onChange={(opt) => {
              setFilters((filters: filterStateTypeWithType) => ({
                ...filters,
                type: { ...filters.type, selected: opt },
              }))
              setTouched(true)
            }}
            value={
              filters.type.selected.value === undefined
                ? 0
                : filters.type.selected
            }
          />
        )}
        <DateRange
          setFilters={setFilters}
          filters={filters}
          setDateRangeTouched={setDateRangeTouched}
          setTouched={setTouched}
        />
        {activeFilters ? (
          <Button
            onClick={() => {
              setFilters((filters: filterStateTypeWithType) => ({
                type: {
                  ...filters.type,
                  selected: { value: undefined, label: undefined },
                },
                patient: {
                  ...filters.patient,
                  selected: { value: undefined, label: undefined },
                },
                rangeStart: null,
                rangeEnd: null,
              }))
              setDateRangeTouched({
                before: false,
                after: false,
              })
              setTouched(false)
            }}
            variant="secondary"
            formCard={true}
          >
            Clear all filters
          </Button>
        ) : null}
      </div>
    </div>
  )
}
