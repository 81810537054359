import React from "react"
import { Router } from "@reach/router"

import { ExistingAccount } from "./ExistingAccount"
import { NewAccount } from "./NewAccount"

export const AcceptInvitation = () => (
  <Router>
    <ExistingAccount path="/existing-account" />
    <NewAccount path="/new-account" />
  </Router>
)
