import React from "react"

interface Props {
  heading: string
  children: React.ReactNode
  columns?: number
}

export const Card = ({ heading, children, columns = 1 }: Props) => (
  <div className={`card card-columns-${columns}`}>
    <h2 className="heading-small card-heading">{heading}</h2>
    <section className="card-body">{children}</section>
  </div>
)
