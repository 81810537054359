import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import moment from "moment-timezone"

import {
  Button,
  ModalBody,
  FormLegend,
  ModalFooter,
} from "../../../../../../shared"
import { handleAPIError } from "../../../../../../../utilities"
import { useSelfTest } from "../.."
import { ProgressCircle } from "./ProgressCircle"

export const Step5 = ({ addToast }) => {
  const { testData, setTestData } = useSelfTest()
  const [loading, setLoading] = React.useState(false)
  const [timer, setTimer] = React.useState({
    start: new Date(moment(testData.data.startedTime)),
    time: 0,
  })

  const logResultReadTime = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "post",
        url: "/tests/update",
        data: {
          testId: testData.data.id,
          updates: {
            resultsReadTime: new Date().toISOString(),
          },
        },
      })
      const updatedTest = response.data

      setTestData((testData) => ({
        step: testData.step + 1,
        verifiedResult: false,
        data: { ...updatedTest },
      }))
      // setLoading not used to prevent 'memory leak'
    } catch (error) {
      addToast(handleAPIError(error, "save result read time"), {
        appearance: "error",
      })
      setLoading(false)
    }
  }

  React.useEffect(() => {
    let interval = null

    interval = setInterval(() => {
      setTimer((timer) => ({
        ...timer,
        time: Date.now() - timer.start,
      }))
    }, 100)

    return () => {
      clearInterval(interval)
      setTimer({
        start: new Date(moment(testData.data.startedTime)),
        time: 0,
      })
    }
  }, [testData.data.startedTime])

  return (
    <>
      <ModalBody>
        <FormLegend>
          {testData?.data?.manufacturer === "Orasure Inteliswab" ? (
            <p>Please wait 30 minutes for your InteliSwab test result</p>
          ) : (
            <p>
              Please wait for your test result according to the length of time
              recommended by your test manufacturer
            </p>
          )}
        </FormLegend>
        <div className="timer">
          <div className="timer-single">
            <ProgressCircle
              radius={100}
              stroke={4}
              progress={
                parseInt(
                  ("0" + (Math.floor(timer.time / 60000) % 60)).slice(-2)
                ) / 60
              }
            />
            <div className="timer-single-metric">
              <span className="timer-single-metric-unit">
                {("0" + (Math.floor(timer.time / 60000) % 60)).slice(-2)}
              </span>
              <span className="timer-single-metric-name">mins</span>
            </div>
            <span className="timer-single-background" />
          </div>
          <div className="timer-single">
            <ProgressCircle
              radius={100}
              stroke={4}
              progress={
                parseInt(
                  ("0" + (Math.floor(timer.time / 1000) % 60)).slice(-2)
                ) / 60
              }
            />
            <div className="timer-single-metric">
              <span className="timer-single-metric-unit">
                {("0" + (Math.floor(timer.time / 1000) % 60)).slice(-2)}
              </span>
              <span className="timer-single-metric-name">secs</span>
            </div>
            <span className="timer-single-background" />
          </div>
        </div>
      </ModalBody>
      <ModalFooter
        backFunc={() =>
          setTestData((testData) => ({
            step: testData.step - 1,
            verifiedResult: false,
            data: { ...testData.data },
          }))
        }
      >
        <Button
          loading={loading}
          disabled={loading}
          onClick={() => logResultReadTime()}
        >
          Record result
        </Button>
      </ModalFooter>
    </>
  )
}

Step5.propTypes = {
  addToast: PropTypes.func.isRequired,
}
