import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useToasts } from "react-toast-notifications"

import { Modal } from "../../../../shared"
import { SEOTitleTemplate } from "../../../../../constants"
import { Step1 } from "./Step1"
import { Step2 } from "./Step2"
import { Confirmation } from "./Confirmation"
import { checkFeaturePermission } from "../../../../../utilities"
import { useAuth } from "../../../../../hooks"

import ExampleTestResults from "../../../../../assets/self-test/antigen/test-results.jpg"
import { ReactComponent as InteliResults } from "../../../../../assets/self-test/antigen/InteliSwab/results.svg"
import { DiagnosticsUpgradeHint } from "../../DiagnosticsUpgradeHint"

const AntigenTestContext = React.createContext()

export const useAntigenTest = () => {
  return React.useContext(AntigenTestContext)
}

const defaultState = {
  step: 1,
  data: {
    batch: "",
    createdTime: "",
    id: "",
    manufacturer: "Orasure Inteliswab",
    patient: "",
    sampleType: "Nasal Swab",
    sampledTime: "",
    selfTest: true,
    type: "",
  },
}

const useProvideAntigenTest = () => {
  const [testData, setTestData] = React.useState(defaultState)
  // Move addToast into context?
  const resetTestData = () => {
    setTestData(defaultState)
  }
  return { testData, setTestData, resetTestData }
}

export const AntigenTestModal = ({ modalVisible, dismissFunc, setTests }) => {
  const { addToast } = useToasts()
  const { planTier } = useAuth()
  const testData = useProvideAntigenTest()
  const closeModal = () => {
    dismissFunc()
    testData.resetTestData()
  }

  return (
    <AntigenTestContext.Provider value={testData}>
      <Modal
        isVisible={modalVisible}
        title="Upload Rapid Antigen Test Result"
        dismissFunc={closeModal}
        header={testData.testData.step !== 3}
        size={testData.testData.step === 3 ? "medium" : "large"}
      >
        <Helmet title={SEOTitleTemplate({ title: "New self-test" })} />
        {checkFeaturePermission({
          planTier: planTier,
          feature: "diagnostics-results",
        }) ? (
          testData.testData.step === 1 ? (
            <Step1 addToast={addToast} />
          ) : testData.testData.step === 2 ? (
            <Step2
              addToast={addToast}
              setTests={setTests}
              closeModal={closeModal}
              image={ExampleTestResults}
              inteliSwabImage={<InteliResults />}
            />
          ) : testData.testData.step === 3 ? (
            <Confirmation closeModal={closeModal} />
          ) : null
        ) : (
          <DiagnosticsUpgradeHint variant="Uploading past Rapid Antigen test results" />
        )}
      </Modal>
    </AntigenTestContext.Provider>
  )
}

AntigenTestModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  dismissFunc: PropTypes.func.isRequired,
  setTests: PropTypes.func.isRequired,
}
