import React from "react"

interface Props {
  images: Array<{
    src: string
    alt: string
  }>
  wide: boolean
  svg: boolean
}

export const ModalImageContainer = ({
  images,
  wide = false,
  svg = false,
}: Props) => (
  <div
    className={`modal-image-container ${
      wide ? "modal-image-container-wide" : ""
    }`}
  >
    {images &&
      images.map((image, index) => (
        <div className="modal-image-container-image" key={index}>
          {svg ? image.src : <img src={image.src} alt={image.alt} />}
        </div>
      ))}
  </div>
)
