import React from "react"
import { navigate } from "@reach/router"
import { Formik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { Helmet } from "react-helmet"
import { useToasts } from "react-toast-notifications"
import { getAuth } from "firebase/auth"
import { useQueryParam, StringParam } from "use-query-params"

import { useAuth } from "../../../hooks"
import { handleAPIError } from "../../../utilities"
import { SEOTitleTemplate, firebaseApp } from "../../../constants"
import { Button, Form, Input } from "../../shared"

export const VerifyEmail = () => {
  const [code] = useQueryParam("code", StringParam)
  const { addToast } = useToasts()
  const authHook = useAuth()
  const [reSendLoading, setReSendLoading] = React.useState(false)
  const auth = getAuth(firebaseApp)

  const onSubmit = async (
    values: { code: string },
    { setSubmitting }: { setSubmitting: (arg0: boolean) => void }
  ) => {
    try {
      await axios({
        method: "post",
        url: "/user/verify-email",
        data: values,
      })
      setSubmitting(false)
      navigate("/create-account/choose-plan")
    } catch (error) {
      addToast(handleAPIError(error, "verify email address"), {
        appearance: "error",
      })
      setSubmitting(false)
    }
  }

  const reSendCode = async () => {
    setReSendLoading(true)
    try {
      await axios({
        method: "post",
        url: "/user/generate-email-verification-code",
      })
      addToast(`New verification code sent to ${authHook?.user.email}`, {
        appearance: "success",
      })
    } catch (error) {
      addToast(handleAPIError(error, "resend email verification code"), {
        appearance: "error",
      })
    }
    setReSendLoading(false)
  }

  return (
    <>
      <Helmet title={SEOTitleTemplate({ title: "Verify your email" })} />
      <Formik
        initialValues={{
          code: code ? code : "",
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string()
            .matches(/^[0-9]+$/, "Please enter numbers only")
            .required("Please enter your 6 code")
            .test("len", "Please enter a code that is 6 digits", (val) =>
              val ? val.length === 6 : false
            ),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form variant="card">
            <h1 className="heading-extra-small form-card-heading-left">
              Please enter the code sent to your email
            </h1>
            <p className="form-card-paragraph">
              The code sent to {authHook?.user.email} will expire in 1 hour.
              Please check your SPAM folder before re-sending a new code.
            </p>
            <Input name="code" />
            <Button
              type="submit"
              formCard={true}
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Verify email
            </Button>
            <Button
              type="button"
              onClick={() => reSendCode()}
              variant="secondary"
              formCard={true}
              disabled={reSendLoading}
              loading={reSendLoading}
            >
              Resend code
            </Button>
            <Button
              type="button"
              onClick={() => auth.signOut()}
              variant="secondary"
              formCard={true}
            >
              Log out
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}
