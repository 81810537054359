import React from "react"
import { useLocation } from "@reach/router"

import { convertFullNameToInitials } from "../../../utilities"
import { useAuth, useOnClickOutside } from "../../../hooks"
import { NavLink } from "./NavLink"

export { NavLink } from "./NavLink"

export const NavAvatar = () => {
  const auth = useAuth()
  const location = useLocation()

  React.useEffect(() => {
    setAccountDropdownVisibility(false)

    return () => {
      setAccountDropdownVisibility(false)
    }
  }, [location.pathname]) // eslint-disable-line

  const [accountDropdownVisible, setAccountDropdownVisibility] =
    React.useState(false)
  const [userInitials, setUserInitials] = React.useState("")

  const accountDropdownRef = React.useRef()
  useOnClickOutside(accountDropdownRef, () =>
    setAccountDropdownVisibility(false)
  )

  const signOut = () => {
    auth.signOut()
  }

  React.useEffect(() => {
    if (auth.user && auth.user.displayName) {
      setUserInitials(convertFullNameToInitials(auth.user.displayName))
    }
  }, [auth])

  return (
    <div className="nav-item" ref={accountDropdownRef}>
      <button
        className={`nav-account-button${
          accountDropdownVisible ? " active" : ""
        }`}
        onClick={() => setAccountDropdownVisibility((current) => !current)}
      >
        <span className="nav-account-button-initials">
          <span translate="no" className="nav-account-button-initials-text">
            {userInitials}
          </span>
        </span>
        <span className="icon-wedge nav-account-button-icon"></span>
      </button>
      <ul
        className={`nav-link-group${accountDropdownVisible ? " active" : ""}`}
      >
        <li className="nav-link-group-section">
          <ul>
            <NavLink to="/account/patients" tabIndex={accountDropdownVisible}>
              Your patients
            </NavLink>
            <NavLink
              to="/account/status-reporting"
              tabIndex={accountDropdownVisible}
            >
              Status reporting
            </NavLink>
            <NavLink
              to="/account/manage-subscription"
              tabIndex={accountDropdownVisible}
            >
              Manage subscription
            </NavLink>
            <NavLink
              to="/account/change-email"
              tabIndex={accountDropdownVisible}
            >
              Change email
            </NavLink>
            <NavLink
              to="/account/change-password"
              tabIndex={accountDropdownVisible}
            >
              Change password
            </NavLink>
          </ul>
        </li>
        <li className="nav-link-group-section">
          <button
            className="nav-log-out-button"
            onClick={() => signOut()}
            tabIndex={accountDropdownVisible ? 0 : -1}
          >
            <span className="icon-arrow-right" />
            <span>Log out</span>
          </button>
        </li>
      </ul>
    </div>
  )
}
