import React from "react"
import PropTypes from "prop-types"

import { Button, Dialog } from "../../../../shared"
import { AppointmentReview } from "../../AppointmentReview"

export const NewAppointmentConfirmation = ({
  data,
  closeModal,
  openViewAppointment,
  setViewAppointmentData,
}) => {
  const appointment = data.data.appointment
  return (
    <Dialog
      title="Appointment booked"
      footer={
        <>
          <Button
            onClick={() => {
              setViewAppointmentData({
                start: new Date(appointment.startTime),
                end: new Date(appointment.endTime),
                resource: {
                  id: appointment.id,
                  mendAppointment: {
                    ...appointment.mendAppointment,
                  },
                  patient: {
                    ...data.data.patient,
                  },
                },
                originalTitle:
                  "Rapid Antigen COVID-19 Test Result Video Verification",
              })
              openViewAppointment()
              closeModal()
            }}
          >
            View appointment
          </Button>
          <Button variant="secondary" onClick={() => closeModal()}>
            Back to appointments
          </Button>
        </>
      }
    >
      <AppointmentReview
        title="Rapid Antigen COVID-19 Test Result Video Verification"
        dates={{
          startTime: new Date(appointment.startTime),
          endTime: new Date(appointment.endTime),
        }}
        patient={data.data.patient.fullName}
      />
    </Dialog>
  )
}

NewAppointmentConfirmation.propTypes = {
  data: PropTypes.object.isRequired,
}
