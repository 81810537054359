import React from "react"
import PropTypes from "prop-types"

import { Button } from "../.."

export const PassCodeDecision = ({ hasPassCodeFunc, noPassCodeFunc }) => (
  <section className="pass-code-decision">
    <h1 className="heading-small">Do you have an employer code?</h1>
    <div className="pass-code-decision-buttons">
      <Button type="button" onClick={hasPassCodeFunc}>
        Yes, I have a code
      </Button>
      <Button type="button" variant="secondary" onClick={noPassCodeFunc}>
        No, I'm paying for myself
      </Button>
    </div>
  </section>
)

PassCodeDecision.propTypes = {
  hasPassCodeFunc: PropTypes.func.isRequired,
  noPassCodeFunc: PropTypes.func.isRequired,
}
