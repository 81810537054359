import React from "react"
import PropTypes from "prop-types"
import { Formik } from "formik"
import * as Yup from "yup"
import { Link } from "@reach/router"
import moment from "moment-timezone"

import {
  Button,
  ButtonWithIcon,
  Checkbox,
  Form,
  FormLegend,
  FormSection,
  InputSelect,
  InputDatePicker,
  InputTimePicker,
  ModalBody,
  ModalFooter,
} from "../../../../shared"
import {
  handleAPIError,
  checkFeaturePermission,
} from "../../../../../utilities"
import { useAuth } from "../../../../../hooks"

export const Step1 = ({
  data,
  setData,
  addToast,
  patients,
  rawPatients,
  loading,
  error,
}) => {
  const { planTier } = useAuth()
  const [departureDate, setDepartureDate] = React.useState(
    data.data.departureDate
  )
  const [departureTime, setDepartureTime] = React.useState(
    data.data.departureTime
  )

  const onSubmit = async (values) => {
    const departureDateTime = new Date(
      addDepartureTimeToDate(departureTime, departureDate)
    )

    let patient

    if (
      !checkFeaturePermission({
        planTier: planTier,
        feature: "multiple-patients",
      })
    ) {
      patient = rawPatients.find((patient) => patient.accountHolder)
    } else {
      patient = rawPatients.find((patient) => patient.id === values.patient)
    }

    if (patient && patient.mendProfile && patient.mendProfile.id) {
      setData((data) => ({
        step: data.step + 1,
        data: {
          ...data.data,
          patient: patient,
          forTravel: values.forTravel,
          departureDate: departureDate,
          departureTime: departureTime,
          departureDateTime: departureDateTime,
          destinationTravelWindow: values.destinationTravelWindow,
        },
      }))
    } else {
      addToast(
        "Could not set up patient appointment data, please use a different patient",
        {
          appearance: "error",
        }
      )
    }
  }

  const addDepartureTimeToDate = (departureTime, departureDate) => {
    const departureTimeArr = departureTime.split(":")

    return new Date(
      departureDate.setHours(
        parseInt(departureTimeArr[0]),
        parseInt(departureTimeArr[1]),
        0,
        0
      )
    ).toISOString()
  }

  React.useEffect(() => {
    if (error) {
      addToast(handleAPIError(error, "load patient options"), {
        appearance: "error",
      })
    }
  }, [error]) // eslint-disable-line

  return (
    <Formik
      initialValues={{
        patient: data.data.patient.id,
        forTravel: data.data.forTravel,
        destinationTravelWindow: data.data.destinationTravelWindow,
      }}
      validationSchema={Yup.object().shape({
        patient: checkFeaturePermission({
          planTier: planTier,
          feature: "multiple-patients",
        })
          ? Yup.string().required("Please choose a patient")
          : Yup.string(),
        forTravel: Yup.boolean(),
        destinationTravelWindow: Yup.string(),
      })}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <ModalBody>
            <FormLegend variant="button">
              <p>You must bring your own test to your video appointment</p>
              <ButtonWithIcon
                href="https://store.gotthetest.com/"
                variant="secondary"
                icon="external-link"
                iconPosition="left"
              >
                Need a test?
              </ButtonWithIcon>
            </FormLegend>
            <FormSection>
              {checkFeaturePermission({
                planTier: planTier,
                feature: "multiple-patients",
              }) && (
                <>
                  <InputSelect
                    loading={loading}
                    label="Patient"
                    name="patient"
                    options={patients}
                  >
                    <Link className="label-link" to="/account/patients">
                      New patient
                    </Link>
                  </InputSelect>{" "}
                  <div />
                </>
              )}
              <Checkbox
                name="forTravel"
                label="This test is needed before a specific date/time"
              />
            </FormSection>
            {values.forTravel ? (
              <div>
                <FormLegend>
                  <p>
                    You are currently in {data.data.localTimeZone} timezone.
                    Please make sure all dates and times are in your current
                    timezone.
                  </p>
                  <p>
                    Testing for travel?{" "}
                    <a
                      href="https://testfortravel.com/covid19-country-travel-requirements/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Check current international requirements
                    </a>
                  </p>
                </FormLegend>
                <FormSection>
                  <InputDatePicker
                    name="departureDate"
                    label="Date test needed"
                    value={departureDate}
                    setValue={setDepartureDate}
                    tileDisabled={(date) =>
                      moment(new Date()).isAfter(date, "day") ? true : false
                    }
                  />
                  <InputTimePicker
                    name="departureTime"
                    label={`Time test needed (${data.data.localTimeZone})`}
                    value={departureTime}
                    setValue={setDepartureTime}
                  />
                  <InputSelect
                    name="destinationTravelWindow"
                    label="Time allowed before test needed"
                    options={[
                      {
                        value: 24,
                        label: "24-hours",
                      },
                      {
                        value: 48,
                        label: "48-hours",
                      },
                      {
                        value: 72,
                        label: "72-hours",
                      },
                    ]}
                  />
                </FormSection>
              </div>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Choose appointment times
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}

Step1.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
}
