import React from "react"
import PropTypes from "prop-types"

import {
  Button,
  ModalBody,
  FormLegend,
  ModalFooter,
  ModalImageContainer,
} from "../../../../../../shared"
import { useSelfTest } from "../.."

export const Step2 = ({ images, inteliSwabImages }) => {
  const { testData, setTestData } = useSelfTest()

  return (
    <>
      <ModalBody>
        {testData?.data?.manufacturer === "Orasure Inteliswab" ? (
          <>
            <FormLegend>
              <p>
                When you’re ready, please open your InteliSwab test and set up
                your extraction tube holder
              </p>
            </FormLegend>
            <ModalImageContainer
              svg={true}
              images={inteliSwabImages}
              wide={true}
            />
          </>
        ) : (
          <>
            <FormLegend>
              <p>
                When you’re ready, please open and prepare your test kit
                according to manufacturer instructions
              </p>
            </FormLegend>
            <ModalImageContainer images={images} />
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() =>
            setTestData((testData) => ({
              step: testData.step + 1,
              verifiedResult: false,
              data: { ...testData.data },
            }))
          }
        >
          Collect your sample
        </Button>
      </ModalFooter>
    </>
  )
}

Step2.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  inteliSwabImages: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.object.isRequired,
      alt: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}
