export const antigenTestManufacturers = [
  { value: "Abbott BinaxNOW", label: "Abbott BinaxNOW" },
  { value: "Access Bio CareStart", label: "Access Bio CareStart" },
  {
    value: "Becton Dickson (BD) Veritor",
    label: "Becton Dickson (BD) Veritor",
  },
  { value: "Ellume Home Test", label: "Ellume Home Test" },
  { value: "Orasure Inteliswab", label: "Orasure Inteliswab" },
  { value: "Quidel QuickVue", label: "Quidel QuickVue" },
  { value: "Siemans CLINITEST", label: "Siemans CLINITEST" },
  { value: "iHealth Rapid Test", label: "iHealth Rapid Test" },
]

export const pcrTestManufacturers = [
  { value: "Abbott", label: "Abbott" },
  { value: "Amazon", label: "Amazon" },
  { value: "Color", label: "Color" },
  { value: "Cue", label: "Cue" },
  { value: "Detect", label: "Detect" },
  { value: "DNA Genotek", label: "DNA Genotek" },
  { value: "Everlywell", label: "Everlywell" },
  { value: "LabCorp", label: "LabCorp" },
  { value: "LetsGetChecked", label: "LetsGetChecked" },
  { value: "Quidel", label: "Quidel" },
  { value: "Roche", label: "Roche" },
]
