export const calculatePercentageOff = (
  value: number,
  discountPercentage: number
) => {
  const discountDecimal = discountPercentage / 100
  const discount = value * discountDecimal
  const discountedValue = value - discount

  return discountedValue
}
