import { DropdownOption, DropdownOptionType } from "./DropdownOption"

interface Props {
  isOpen: boolean
  options: Array<DropdownOptionType>
  additionalClasses?: string
}

export const Dropdown = ({ isOpen, options, additionalClasses }: Props) => (
  <div
    className={`dropdown${isOpen ? " dropdown-open" : ""}${
      additionalClasses ? ` ${additionalClasses}` : ""
    }`}
  >
    {options.map((option) => (
      <DropdownOption
        key={option.name}
        icon={option.icon}
        name={option.name}
        guidance={option.guidance}
        onClick={option.onClick}
      />
    ))}
  </div>
)
