import { getAuth, signOut } from "firebase/auth"

import { firebaseApp } from "../../constants"

export const handleAPIError = (error: any, action: string) => {
  const auth = getAuth(firebaseApp)
  //console.log(error, error.response)
  if (error && action) {
    if (error.response && error.response.status) {
      const code = error.response.status

      if (code === 400) {
        // Bad data
        if (error.response.data) {
          return `Could not ${action}, ${error.response.data.message}`
        } else {
          return `Could not ${action}, please try different values and try again`
        }
      } else if (code === 401) {
        // Unauthorised
        signOut(auth)
        return `Could not ${action}, your login session has expired`
      } else if (code === 402) {
        // Payment required
        return `Could not ${action}, please use a different payment method`
      } else if (code === 404) {
        // Not found
        return `Server not found, please contact customer service`
      } else if (code === 500) {
        // Server Error
        return `Server error, please contact customer service`
      } else {
        // Unknown error
        if (error.response.data) {
          return `Could not ${action}, ${error.response.data}`
        } else {
          return `Could not ${action}, unknown error`
        }
      }
    } else if (error.request) {
      // No response from the server
      return `Could not ${action}, no response from the server`
    } else {
      // not axios error
      if (error.message) {
        if (error.message === "Network Error") {
          return `Could not ${action}, please make sure you have a stable internet connection`
        } else {
          return `Could not ${action}, ${error.message}`
        }
      } else {
        return `Something went wrong, please refresh the page and try again`
      }
    }
  } else {
    return `Something went wrong, please refresh the page and try again`
  }
}
