import React from "react"
import moment from "moment-timezone"

import { CardData } from "../../../shared"

interface Props {
  sampledTime: Date
  resultsReadTime: Date
  expiry: Date
  appointmentStart: Date
  appointmentEnd: Date
  orderingPhysician: string
  npi: string
  observingAssistantName: string
}

export const AppointmentCard = ({
  sampledTime,
  resultsReadTime,
  expiry,
  appointmentStart,
  appointmentEnd,
  orderingPhysician,
  npi,
  observingAssistantName,
}: Props) => (
  <div className="appointment-card">
    {appointmentStart && appointmentEnd && (
      <CardData label="Appointment">
        {`${moment(appointmentStart).format("MM/DD/YYYY hh:mm A")} - ${moment(
          appointmentEnd
        ).format("hh:mm A")}`}
      </CardData>
    )}
    {sampledTime && (
      <CardData label="Sample taken">
        {moment(sampledTime).format("MM/DD/YYYY hh:mm A")}
      </CardData>
    )}
    {resultsReadTime && (
      <CardData label="Result read time">
        {moment(resultsReadTime).format("MM/DD/YYYY hh:mm A")}
      </CardData>
    )}
    {expiry && (
      <CardData label="Expiry time">
        {moment(expiry).format("MM/DD/YYYY hh:mm A")}
      </CardData>
    )}
    {orderingPhysician && npi && (
      <CardData label="Ordering Physician">
        {orderingPhysician} ({npi})
      </CardData>
    )}
    {observingAssistantName && (
      <CardData label="Observing Assistant">{observingAssistantName}</CardData>
    )}
  </div>
)
