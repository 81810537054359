import React from "react"
import PropTypes from "prop-types"
import moment from "moment-timezone"

import { ListCard } from "../../../shared"

export const DiagnosticsCards = ({ data }) => (
  <div className="list-cards">
    {data.map((result) => {
      const reading = result?.testResult?.reading?.data
      return (
        <ListCard
          key={result.id}
          id={result.id}
          title={`${moment(result.sampledTime).format("MM/DD/YYYY hh:mm A")}`}
          dateTime={result.patient && result.patient.fullName}
          data={result.type}
          link={`/diagnostics/${result.id}`}
          icon={
            reading === "Negative" || reading === "Not detected"
              ? "tick-circle-fill"
              : reading === "Positive" ||
                reading === "Invalid" ||
                reading === "Detected"
              ? "alert-circle-fill"
              : reading === "Pending"
              ? "clock"
              : null
          }
        />
      )
    })}
  </div>
)

DiagnosticsCards.propTypes = {
  data: PropTypes.array.isRequired,
}
