import React from "react"
import { Link } from "@reach/router"

interface Props {
  to?: string
  onClick?: () => void
  icon?: "arrow-left"
  iconPosition?: "left" | "right"
  children: React.ReactNode
}

export const ButtonLinkWithIcon = ({
  to,
  onClick,
  icon = "arrow-left",
  iconPosition = "left",
  children,
}: Props) => {
  if (to) {
    return (
      <Link
        className={`button-link-with-icon button-link-with-icon-${iconPosition}`}
        to={to}
      >
        <span className={`icon-${icon} button-link-with-icon-icon`}></span>
        <span className="button-link-with-icon-text">{children}</span>
      </Link>
    )
  } else if (onClick) {
    return (
      <button
        className={`button-link-with-icon button-link-with-icon-${iconPosition}`}
        onClick={onClick}
        type="button"
      >
        <span className={`icon-${icon} button-link-with-icon-icon`}></span>
        <span className="button-link-with-icon-text">{children}</span>
      </button>
    )
  } else {
    return null
  }
}
