import DatePicker from "react-date-picker"

import { filterStateType } from "../../../../../types"

interface Props {
  setFilters: any
  filters: filterStateType
  setDateRangeTouched: any
  setTouched: (arg0: boolean) => void
}

export const DateRange = ({
  setFilters,
  filters,
  setDateRangeTouched,
  setTouched,
}: Props) => {
  return (
    <div className="date-range">
      <DatePicker
        clearIcon={null}
        value={filters.rangeStart}
        onChange={(date: Date) => {
          setFilters((filters: filterStateType) => ({
            ...filters,
            rangeStart: date,
          }))
          setDateRangeTouched(
            (dateRangeTouched: { after: boolean; before: boolean }) => ({
              ...dateRangeTouched,
              after: true,
            })
          )
          setTouched(true)
        }}
        calendarIcon={null}
        prevLabel={<span className="icon-chevron-left" />}
        format="MM/dd/y"
        nextLabel={<span className="icon-chevron-right" />}
        showFixedNumberOfWeeks={true}
        dayPlaceholder="dd"
        monthPlaceholder="mm"
        yearPlaceholder="yyyy"
      />
      <span className="date-range-divider">-</span>
      <DatePicker
        clearIcon={null}
        value={filters.rangeEnd}
        onChange={(date: Date) => {
          setFilters((filters: filterStateType) => ({
            ...filters,
            rangeEnd: date,
          }))
          setDateRangeTouched(
            (dateRangeTouched: { after: boolean; before: boolean }) => ({
              ...dateRangeTouched,
              before: true,
            })
          )
          setTouched(true)
        }}
        calendarIcon={null}
        prevLabel={<span className="icon-chevron-left" />}
        format="MM/dd/y"
        nextLabel={<span className="icon-chevron-right" />}
        showFixedNumberOfWeeks={true}
        dayPlaceholder="dd"
        monthPlaceholder="mm"
        yearPlaceholder="yyyy"
      />
    </div>
  )
}
