import { immunizationType, reactSelectOptionsType } from "../../../types"

import { immunizations } from "../../../constants"

export const calculateImmunizationDiseasesTargeted = () => {
  let formattedDiseasesTargeted: reactSelectOptionsType = []

  immunizations.forEach((immunization: immunizationType) => {
    formattedDiseasesTargeted.push({
      value: immunization.diseaseTargeted,
      label: immunization.diseaseTargeted,
    })
  })

  return formattedDiseasesTargeted
}
