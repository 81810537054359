import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import { Formik } from "formik"
import * as Yup from "yup"
import { getStorage, ref, uploadBytes } from "firebase/storage"

import {
  Button,
  Form,
  FormLegend,
  FormSection,
  ImageUploader,
  InputSelect,
  ModalBody,
  ModalFooter,
} from "../../../../../../shared"
import { useSelfTest } from "../.."
import {
  handleAPIError,
  updateLatestStatusReportingGroup,
} from "../../../../../../../utilities"
import { useAuth } from "../../../../../../../hooks"
import { antigenResultOptions } from "../../../../../../../constants"

import { ReactComponent as AntigenTest } from "../../../../../../../assets/rapid-antigen-test.svg"

export const Step6 = ({ addToast, setTests, image, inteliSwabImage }) => {
  const storage = getStorage()
  const auth = useAuth()
  const { testData, setTestData } = useSelfTest()
  const [photos, setPhotos] = React.useState([])

  const onSubmit = async (values, { setSubmitting }) => {
    let requestResult = {
      reading: {
        type: "neg-pos",
        data: values.reading,
      },
    }
    try {
      if (photos.length > 0) {
        const date = new Date().toISOString()
        const filePath = `users/${auth.user.uid}/images/${date}-${photos[0].file.name}`
        const storageRef = ref(storage, filePath)
        await uploadBytes(storageRef, photos[0].file)
        requestResult.images = [filePath]
      }

      const response = await axios({
        method: "post",
        url: "/tests/record-result",
        data: {
          testId: testData.data.id,
          result: requestResult,
        },
      })
      const testWithTestResult = response.data

      setTests((tests) => [testWithTestResult, ...tests])
      updateLatestStatusReportingGroup({
        userId: auth.user.uid,
        addToast: addToast,
        setFitForWorkReport: auth.setFitForWorkReport,
      })
      setTestData((testData) => ({
        step: testData.step + 1,
        verifiedResult: false,
        data: { ...testWithTestResult },
      }))
    } catch (error) {
      addToast(handleAPIError(error, "save result"), {
        appearance: "error",
      })
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={{
        reading: "",
      }}
      validationSchema={Yup.object().shape({
        reading: Yup.string().required(
          "Please enter the result reading of your test"
        ),
      })}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <ModalBody>
            <FormLegend>
              <p>
                Please enter the reading shown on your test result. You can also
                upload a photo of your test to improve accuracy (Optional)
              </p>
            </FormLegend>
            <FormSection>
              <div>
                <ImageUploader
                  photos={photos}
                  updatePhotos={setPhotos}
                  illustration={<AntigenTest />}
                  variant="self-test"
                />
                <span className="input-error-text image-uploader-error"></span>
                <InputSelect
                  label="Reading shown"
                  name="reading"
                  options={antigenResultOptions}
                />
              </div>
              {testData?.data?.manufacturer === "Orasure Inteliswab" ? (
                inteliSwabImage
              ) : (
                <img
                  className="image-uploader-example"
                  src={image}
                  alt="Antigen Test Results"
                />
              )}
            </FormSection>
          </ModalBody>
          <ModalFooter
            backFunc={() =>
              setTestData((testData) => ({
                step: testData.step - 1,
                verifiedResult: false,
                data: { ...testData.data },
              }))
            }
          >
            <Button
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Save result
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}

Step6.propTypes = {
  setTests: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
  inteliSwabImage: PropTypes.string.isRequired,
}
