export const immunizations = [
  {
    diseaseTargeted: "COVID-19",
    vaccines: [
      {
        name: "SARS Cov-2 mRNA",
        manufacturers: [
          {
            name: "I'm not sure",
            doses: 2,
            boosters: true,
          },
          {
            name: "Janssen/Johnson & Johnson",
            doses: 1,
            boosters: true,
          },
          {
            name: "Novavax",
            doses: 1,
            boosters: true,
          },
          {
            name: "Moderna",
            doses: 2,
            boosters: true,
          },
          {
            name: "Oxford/AstraZeneca",
            doses: 2,
            boosters: true,
          },
          {
            name: "Pfizer/BioNTech",
            doses: 2,
            boosters: true,
          },
        ],
      },
    ],
  },
  {
    diseaseTargeted: "Influenza (Flu)",
    vaccines: [
      {
        name: "Influenza (Flu)",
        manufacturers: [
          {
            name: "I'm not sure",
            doses: 0,
            boosters: true,
          },
          {
            name: "Afluria Quadrivalent/Seqirus Pty. Ltd.",
            doses: 0,
            boosters: true,
          },
          {
            name: "Fluad Quadrivalent/Seqirus, Inc.",
            doses: 0,
            boosters: true,
          },
          {
            name: "Fluarix Quadrivalent/GlaxoSmithKline Biologicals",
            doses: 0,
            boosters: true,
          },
          {
            name: "Flublok Quadrivalent/Protein Sciences Corporation",
            doses: 0,
            boosters: true,
          },
          {
            name: "Flucelvax Quadrivalent/Seqirus, Inc.",
            doses: 0,
            boosters: true,
          },
          {
            name: "FluLaval Quadrivalent/ID Biomedical Corporation of Quebec",
            doses: 0,
            boosters: true,
          },
          {
            name: "FluMist Quadrivalent/MedImmune, LLC",
            doses: 0,
            boosters: true,
          },
          {
            name: "Fluzone High Dose Quadrivalent/Sanofi Pasteur, Inc",
            doses: 0,
            boosters: true,
          },
          {
            name: "Fluzone Quadrivalent/Sanofi Pasteur, Inc.",
            doses: 0,
            boosters: true,
          },
        ],
      },
    ],
  },
]
