import { freePlanId } from ".."

interface Props {
  plan: any
  allPlans: any
}

export const getFreePlanMissedFeatures = ({ plan, allPlans }: Props) => {
  let missedFeatures: any = []
  const freeTierFeatures = allPlans?.find(
    (plan: any) => plan?.sys?.id === freePlanId
  ).fields?.permissions
  const planFeatures = plan?.fields?.permissions

  planFeatures.forEach((permission: any) => {
    // If permission does not match any permission in free tier
    if (
      freeTierFeatures.some(
        (feature: any) => feature?.sys?.id === permission?.sys?.id
      )
    ) {
    } else {
      missedFeatures.push(permission?.fields?.name)
    }
  })
  return missedFeatures
}
