export type DropdownOptionType = {
  icon: string
  name: string
  guidance: string
  onClick: () => void
}

export const DropdownOption = ({
  icon,
  name,
  guidance,
  onClick,
}: DropdownOptionType) => (
  <button className="dropdown-option" onClick={onClick}>
    <div className="dropdown-option-graphic">
      <span className={`icon-${icon} dropdown-option-graphic-icon`} />
    </div>
    <div className="dropdown-option-text">
      <span className="dropdown-option-name">{name}</span>
      <span className="dropdown-option-guidance">{guidance}</span>
    </div>
  </button>
)
