import { View, Text } from "@react-pdf/renderer"

import { PDFStyles } from ".."

interface Props {
  header: string
}

export const TableHeaderCell = ({ header }: Props) => (
  <View style={PDFStyles.tableHeaderCell}>
    <Text style={PDFStyles.tableHeaderCellLabel}>{header}</Text>
  </View>
)
