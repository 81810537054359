import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { Link } from "@reach/router"

import {
  Input,
  Button,
  ModalBody,
  FormSection,
  InputButtonList,
  Form,
  FormLegend,
  ModalFooter,
  AddressFinder,
  InputDateOfBirth,
  InputSelect,
} from "../../../../shared"
import { usePatients, useAuth } from "../../../../../hooks"
import { dateRegex } from "../../../../../constants"
import {
  handleAPIError,
  checkFeaturePermission,
  calculateImmunizationDoses,
  calculateImmunizationVaccines,
  calculateImmunizationVaccineManufacturers,
  calculateImmunizationDiseasesTargeted,
  updateLatestStatusReportingGroup,
} from "../../../../../utilities"

export const Step1 = ({
  vaccinationData,
  setVaccinationData,
  setVaccinations,
  addToast,
}) => {
  const { formattedPatients, rawPatients, error, loading } = usePatients()
  const { planTier, user, setFitForWorkReport } = useAuth()

  const [searchedAddress, setSearchedAddress] = React.useState()
  const [manualAddressVisible, setManualAddressVisibility] =
    React.useState(false)
  const [searchedAddressError, setSearchedAddressError] = React.useState("")

  const onSubmit = async (values, { setSubmitting }) => {
    if (values.medicalProvider === "") {
      setSearchedAddressError("Please enter where the vaccine was received")
      setManualAddressVisibility(true)
    } else {
      let { patient } = values
      delete values.patient
      if (
        !checkFeaturePermission({
          planTier: planTier,
          feature: "multiple-patients",
        })
      ) {
        patient = rawPatients.find((patient) => patient.accountHolder)?.id
      }

      if (values.dose === "Booster") {
        values.booster = true
        delete values.dose
      }

      try {
        const response = await axios({
          method: "post",
          url: "/immunization-records/create",
          data: {
            patientId: patient,
            immunization: values,
            _expand: ["patient"],
          },
        })
        if (vaccinationData.attestation) {
          setVaccinationData((vaccinationData) => ({
            ...vaccinationData,
            step: 4,
            data: { ...response.data },
          }))
          setVaccinations((vaccinations) => [
            { ...response.data },
            ...vaccinations,
          ])
          updateLatestStatusReportingGroup({
            userId: user.uid,
            addToast: addToast,
            setFitForWorkReport: setFitForWorkReport,
          })
        } else {
          setVaccinationData((vaccinationData) => ({
            ...vaccinationData,
            step: vaccinationData.step + 1,
            data: { ...response.data },
          }))
        }
      } catch (error) {
        addToast(handleAPIError(error, "save vaccination record"), {
          appearance: "error",
        })
        setSubmitting(false)
      }
    }
  }

  React.useEffect(() => {
    if (error) {
      addToast(handleAPIError(error, "load patient options"), {
        appearance: "error",
      })
    }
  }, [error]) // eslint-disable-line

  return (
    <Formik
      initialValues={{
        patient: "",
        diseaseTargeted: "COVID-19",
        name: "",
        vaccineManufacturer: "",
        vaccineManufacturerBatch: "",
        vaccinationDate: "",
        dose: "",
        medicalProvider: "",
      }}
      validationSchema={Yup.object().shape({
        patient: checkFeaturePermission({
          planTier: planTier,
          feature: "multiple-patients",
        })
          ? Yup.string().required("Please choose a patient")
          : Yup.string(),
        diseaseTargeted: Yup.string().required(
          "Please enter the target disease of the vaccine"
        ),
        name: Yup.string().required("Please enter which vaccine was received"),
        vaccineManufacturer: Yup.string().required(
          "Please enter the manufacturer of the vaccine"
        ),
        vaccineManufacturerBatch: Yup.string(),
        vaccinationDate: Yup.string()
          .required("Please enter the date of vaccination")
          .matches(dateRegex, "Please enter a date in the format mm/dd/yyyy"),
        dose: Yup.string().required("Please choose a vaccine dose"),
        medicalProvider: Yup.string(),
      })}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Form>
          <ModalBody>
            {!vaccinationData.attestation && (
              <FormLegend>
                <p>
                  You will need to take a photo of your vaccination record card
                  as part of this form
                </p>
              </FormLegend>
            )}
            <FormSection>
              {checkFeaturePermission({
                planTier: planTier,
                feature: "multiple-patients",
              }) && (
                <>
                  <InputSelect
                    loading={loading}
                    label="Patient"
                    name="patient"
                    options={formattedPatients}
                  >
                    <Link className="label-link" to="/account/patients">
                      New patient
                    </Link>
                  </InputSelect>
                  <div />
                </>
              )}
              <InputSelect
                name="diseaseTargeted"
                label="Disease targeted"
                options={calculateImmunizationDiseasesTargeted()}
              />
            </FormSection>
            <FormSection>
              <InputSelect
                label="Vaccine"
                name="name"
                options={calculateImmunizationVaccines({
                  diseaseTargeted: values.diseaseTargeted,
                })}
                placeholder={
                  values.diseaseTargeted === ""
                    ? "Please choose a Disease Targeted first"
                    : ""
                }
              />
              <div />
              <InputSelect
                label="Manufacturer"
                name="vaccineManufacturer"
                options={calculateImmunizationVaccineManufacturers({
                  diseaseTargeted: values.diseaseTargeted,
                  vaccine: values.name,
                })}
                placeholder={
                  values.name === "" ? "Please choose a Vaccine first" : ""
                }
              />
              <InputButtonList
                name="dose"
                label="Dose"
                options={calculateImmunizationDoses({
                  diseaseTargeted: values.diseaseTargeted,
                  vaccine: values.name,
                  manufacturer: values.vaccineManufacturer,
                })}
                placeholder="Please choose a manufacturer"
              />
              <Input
                label="Batch number"
                name="vaccineManufacturerBatch"
                optional={true}
              />
            </FormSection>
            <FormSection>
              <InputDateOfBirth name="vaccinationDate" label="Date" />
              <div />
              <AddressFinder
                label="Administering centre"
                searchedAddress={searchedAddress}
                onChange={(address) => {
                  setSearchedAddress(address)
                  setFieldValue(
                    "medicalProvider",
                    address && address.label ? address.label : ""
                  )
                }}
                error={searchedAddressError}
              >
                {manualAddressVisible ? (
                  ""
                ) : (
                  <button
                    onClick={() => setManualAddressVisibility(true)}
                    className="label-link"
                  >
                    Enter manually
                  </button>
                )}
              </AddressFinder>
              <div className={manualAddressVisible ? "" : "not-visible"}>
                <Input
                  name="medicalProvider"
                  label="Enter address"
                  placeholder="Your full address"
                />
              </div>
            </FormSection>
          </ModalBody>
          <ModalFooter
            backFunc={() =>
              setVaccinationData((current) => ({
                ...current,
                step: 1,
              }))
            }
          >
            <Button
              disabled={isSubmitting}
              type="submit"
              loading={isSubmitting}
            >
              {vaccinationData.attestation
                ? "Save attestation"
                : "Upload record card photo"}
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}
