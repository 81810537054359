import React from "react"
import moment from "moment-timezone"

export const CustomToolbar = ({ label, onNavigate }) => (
  <div className="rbc-toolbar">
    <span className="rbc-btn-group">
      <button
        className="calendar-toolbar-text-button"
        type="button"
        onClick={() => onNavigate("TODAY")}
      >
        Today
      </button>
      <div className="calendar-toolbar-controls">
        <button
          className="calendar-toolbar-icon-button"
          type="button"
          onClick={() => onNavigate("PREV")}
          aria-label="Previous week"
        >
          <span className="icon-chevron-left calendar-toolbar-icon-button-icon" />
        </button>
        <button
          className="calendar-toolbar-icon-button"
          type="button"
          onClick={() => onNavigate("NEXT")}
          aria-label="Next week"
        >
          <span className="icon-chevron-right calendar-toolbar-icon-button-icon" />
        </button>
      </div>
    </span>
    <span className="rbc-toolbar-label">
      {label} ({moment.tz(moment.tz.guess()).zoneAbbr()})
    </span>
  </div>
)
