import React from "react"
import PropTypes from "prop-types"

export const Tag = ({ variant, children, icon, type }) => {
  if (icon) {
    return (
      <span className={`tag tag-${type} tag-icon tag-${variant}`}>
        <span
          className={`icon-${
            type === "success" ? "tick-circle-fill" : "alert-circle-outline"
          } tag-icon-icon`}
        />
        <span className="tag-icon-text">{children}</span>
      </span>
    )
  } else {
    return <span className={`tag tag-${type} tag-${variant}`}>{children}</span>
  }
}

Tag.defaultProps = {
  icon: false,
  type: "success",
  variant: "background",
}

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.bool,
  type: PropTypes.oneOf(["warning", "error", "info", "success", "white"])
    .isRequired,
  variant: PropTypes.oneOf(["background", "no-background"]).isRequired,
}
