import React from "react"
import { Helmet } from "react-helmet"
import axios from "axios"

import {
  Button,
  ButtonWithIcon,
  Query,
  ResultFiltersWithType,
} from "../../shared"
import { SEOTitleTemplate } from "../../../constants"
import { calculateImmunizationVaccineManufacturers } from "../../../utilities"
import { immunizationRecordType, filterStateTypeWithType } from "../../../types"

import { ImmunizationsTable } from "./ImmunizationsTable"
import { NewImmunizationModal } from "./NewImmunizationModal"
import { ImmunizationsCards } from "./ImmunizationsCards"

export const ImmunizationsList = () => {
  const [error, setError] = React.useState(null)
  const [data, setData] = React.useState<Array<immunizationRecordType> | null>(
    null
  )
  const [loading, setLoading] = React.useState(true)
  const [NewImmunizationModalVisible, setNewImmunizationModalVisibility] =
    React.useState(false)

  // Filters
  const [touched, setTouched] = React.useState(false)
  const [mobileFiltersVisible, setMobileFiltersVisibility] =
    React.useState(false)
  const [filters, setFilters] = React.useState<filterStateTypeWithType>({
    patient: {
      selected: {
        value: undefined,
        label: undefined,
      },
      all: [],
    },
    type: {
      selected: {
        value: undefined,
        label: undefined,
      },
      all: calculateImmunizationVaccineManufacturers({
        diseaseTargeted: undefined,
        vaccine: undefined,
      }),
    },
    rangeStart: null,
    rangeEnd: null,
  })
  const [dateRangeTouched, setDateRangeTouched] = React.useState({
    before: false,
    after: false,
  })

  React.useEffect(() => {
    const query = async () => {
      setLoading(true)
      setMobileFiltersVisibility(false)
      try {
        const response = await axios({
          method: "post",
          url: "/immunization-records/all",
          data: {
            patientId: filters.patient.selected.value,
            manufacturer: filters.type.selected.value,
            afterTime: filters.rangeStart
              ? filters.rangeStart.toISOString()
              : "",
            beforeTime: filters.rangeEnd ? filters.rangeEnd.toISOString() : "",
            _expand: ["patient"],
          },
        })
        setData(response.data)
      } catch (error: any) {
        setError(error)
      }
      setLoading(false)
    }
    query()

    return () => {
      setError(null)
      setData(null)
      setLoading(false)
    }
  }, [
    filters.patient.selected,
    filters.type.selected,
    filters.rangeStart,
    filters.rangeEnd,
  ])

  return (
    <>
      <Helmet title={SEOTitleTemplate({ title: "Your immunizations" })} />
      <header className="list-page-header">
        <h1 className="heading-extra-large">Immunizations</h1>
        <ButtonWithIcon
          icon="plus"
          iconPosition="left"
          onClick={() => setNewImmunizationModalVisibility(true)}
        >
          New immunization
        </ButtonWithIcon>
      </header>
      <ResultFiltersWithType
        mobileFiltersVisible={mobileFiltersVisible}
        setMobileFiltersVisibility={setMobileFiltersVisibility}
        filters={filters}
        setTouched={setTouched}
        setFilters={setFilters}
        setDateRangeTouched={setDateRangeTouched}
        dateRangeTouched={dateRangeTouched}
        variant="immunizations"
      />
      {loading || error ? (
        <Query
          additionalClasses="list-page-loader"
          loading={loading}
          error={error}
          action="load vaccinations"
        />
      ) : data && data.length > 0 ? (
        <>
          <ImmunizationsCards data={data} />
          <ImmunizationsTable data={data} />
        </>
      ) : touched ? (
        <div className="list-page-empty">
          <p className="list-page-empty-description">
            Sorry, there are results matching your criteria
          </p>
          <Button
            onClick={() => {
              setFilters((filters: filterStateTypeWithType) => ({
                type: {
                  ...filters.type,
                  selected: { value: undefined, label: undefined },
                },
                patient: {
                  ...filters.patient,
                  selected: { value: undefined, label: undefined },
                },
                rangeStart: null,
                rangeEnd: null,
              }))
              setDateRangeTouched({
                before: false,
                after: false,
              })
            }}
            variant="secondary"
          >
            Clear all filters
          </Button>
        </div>
      ) : (
        <div className="list-page-empty">
          <p className="list-page-empty-description">
            You haven't uploaded any vaccinations yet
          </p>
          <Button onClick={() => setNewImmunizationModalVisibility(true)}>
            Upload your first vaccination
          </Button>
        </div>
      )}
      <NewImmunizationModal
        dismissFunc={() => setNewImmunizationModalVisibility(false)}
        modalVisible={NewImmunizationModalVisible}
        setVaccinations={setData}
      />
    </>
  )
}
