import React from "react"
import PropTypes from "prop-types"

import {
  Button,
  ModalBody,
  FormLegend,
  ModalFooter,
  ModalImageContainer,
} from "../../../../../../shared"
import { usePCRTest } from "../.."

export const Step2 = ({ images }) => {
  const { setTestData } = usePCRTest()

  return (
    <>
      <ModalBody>
        <FormLegend>
          <p>
            When you’re ready, please open and prepare your PCR test kit
            according to manufacturer instructions
          </p>
        </FormLegend>
        <ModalImageContainer images={images} svg={true} />
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() =>
            setTestData((testData) => ({
              step: testData.step + 1,
              data: { ...testData.data },
            }))
          }
        >
          Collect your sample
        </Button>
      </ModalFooter>
    </>
  )
}

Step2.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.object.isRequired,
      alt: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}
