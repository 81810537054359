import React from "react"
import PropTypes from "prop-types"

import { Button } from "../../../shared"
import { ReactComponent as LockedFeature } from "../../../../assets/self-test/feature-locked.svg"

export const DiagnosticsUpgradeHint = ({ variant }) => (
  <div className="diagnostics-upgrade-hint">
    <div className="diagnostics-upgrade-hint-illustration">
      <LockedFeature />
    </div>
    <h2 className="heading-small">Upgrade required</h2>
    <p className="diagnostics-upgrade-hint-text">
      {variant} is a GotTheTest Premium feature.
    </p>
    <p className="diagnostics-upgrade-hint-text">
      Upgrade today and complete, upload, and share unlimited tests results.
    </p>
    <Button to="/change-plan">Upgrade to Premium</Button>
  </div>
)

DiagnosticsUpgradeHint.propTypes = {
  variant: PropTypes.string.isRequired,
}
