import React from "react"
import { Router } from "@reach/router"

import {
  Layout,
  Diagnostics,
  Appointments,
  Immunizations,
  Accounts,
  ScrollToTop,
  ChangePlan,
  Surveys,
} from "./components"

export const ProtectedApp = () => (
  <Router>
    <ScrollToTop path="/">
      <Layout path="/">
        <Diagnostics path="/*" />
        <Accounts path="/account/*" />
        <Appointments path="/appointments" />
        <Immunizations path="/immunizations/*" />
        <Surveys path="/surveys/*" />
      </Layout>
      <ChangePlan path="/change-plan" />
    </ScrollToTop>
  </Router>
)
