import React from "react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { Helmet } from "react-helmet"
import { useNavigate } from "@reach/router"

import { Loader, PlanPaymentForm, PlanHeader, Button } from "../../shared"
import {
  freePlanId,
  calculatePercentageOff,
  familyPlanId,
  premiumPlanId,
} from "../../../utilities"
import { SEOTitleTemplate } from "../../../constants"
import { usePlan, useAuth } from "../../../hooks"
import { ChoosePlanModal } from "./ChoosePlanModal"

const stripe = loadStripe(process.env.REACT_APP_STRIPE)

export const ChoosePlan = () => {
  const { plan, allPlans, selectPlan, loading } = usePlan()
  const authHook = useAuth()
  const navigate = useNavigate()

  const [modalVisible, setModalVisible] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  const [planCost, setPlanCost] = React.useState({
    original: 0,
    discounted: 0,
  })
  const [coupon, setCoupon] = React.useState({
    visible: false,
    value: "",
    error: "",
    loading: false,
    succeeded: false,
    id: "",
    amount_off: 0,
    percent_off: 0,
  })

  // Use query params to get plan, if none set plan to free

  React.useEffect(() => {
    if (plan && plan.fields) {
      setPlanCost((current) => ({
        ...current,
        original: plan?.fields?.cost,
      }))
    }
    return () => {
      setPlanCost({
        original: 0,
        discounted: 0,
      })
    }
  }, [plan])

  React.useEffect(() => {
    if (allPlans) {
      const initialPlan = localStorage.getItem("plan")
      if (initialPlan) {
        if (initialPlan === "2") {
          selectPlan(
            allPlans.find((plan: any) => plan?.sys?.id === familyPlanId)
          )
        } else if (initialPlan === "1") {
          selectPlan(
            allPlans.find((plan: any) => plan?.sys?.id === premiumPlanId)
          )
        } else {
          selectPlan(allPlans.find((plan: any) => plan?.sys?.id === freePlanId))
        }
      } else {
        selectPlan(allPlans.find((plan: any) => plan?.sys?.id === freePlanId))
      }
    }

    return () => {
      localStorage.removeItem("plan")
    }
  }, []) //eslint-disable-line

  React.useEffect(() => {
    if (coupon.amount_off) {
      setPlanCost((current) => ({
        ...current,
        discounted: current.discounted - coupon.amount_off,
      }))
    } else if (coupon.percent_off) {
      setPlanCost((current) => ({
        ...current,
        discounted: calculatePercentageOff(
          current.original,
          coupon.percent_off
        ),
      }))
    } else {
      setPlanCost((current) => ({ ...current, discounted: 0 }))
    }

    return () => {
      setPlanCost((current) => ({ ...current, discounted: 0 }))
    }
  }, [coupon.amount_off, coupon.percent_off])

  React.useEffect(() => {
    setModalVisible(false)
    return () => {
      setModalVisible(false)
    }
  }, [selectPlan])

  React.useEffect(() => {
    if (submitted) {
      navigate("/create-account/create-patient")
    }
  }, [submitted]) // eslint-disable-line

  return (
    <>
      <Helmet title={SEOTitleTemplate({ title: "Your plan" })} />
      <div className="form-card">
        <h1 className="heading-large form-card-heading-central">Your plan</h1>
        <p className="form-card-paragraph text-center">
          Please confirm the GotTheTest plan you'd like to use
        </p>
        <div className="choose-plan-header">
          <PlanHeader
            variant="sign-up"
            name={plan?.fields?.name}
            description={plan?.fields?.description}
            originalCost={planCost.original}
            discountedCost={planCost.discounted}
          />
          <Button variant="secondary" onClick={() => setModalVisible(true)}>
            Change Plan
          </Button>
        </div>
        {loading ? (
          <Loader />
        ) : plan?.sys?.id === freePlanId ? (
          <>
            <Button formCard onClick={() => setSubmitted(true)}>
              Sign up with Free
            </Button>
          </>
        ) : (
          <Elements stripe={stripe}>
            <PlanPaymentForm
              variant="sign-up"
              setSubmitted={setSubmitted}
              planCost={planCost}
              setCoupon={setCoupon}
              coupon={coupon}
              resetCoupon={() =>
                setCoupon({
                  visible: false,
                  value: "",
                  error: "",
                  id: "",
                  loading: false,
                  succeeded: false,
                  amount_off: 0,
                  percent_off: 0,
                })
              }
            />
          </Elements>
        )}
        <Button
          formCard
          variant="secondary"
          onClick={() => authHook?.signOut()}
        >
          Log out
        </Button>
        {plan?.sys?.id !== freePlanId && (
          <p className="text-small plan-payment-form-disclaimer">
            Today you’ll be charged $
            {planCost.original !== null
              ? planCost.discounted
                ? planCost.discounted.toFixed(2)
                : planCost.original.toFixed(2)
              : ""}
            . You may cancel or change your subscription at any time by visiting
            &#39;Manage Subscription&#39; in your account Settings.
          </p>
        )}
      </div>
      <ChoosePlanModal
        isVisible={modalVisible}
        dismissFunc={() => setModalVisible(false)}
      />
    </>
  )
}
