import React from "react"
import PropTypes from "prop-types"

import { Step1 } from "./Step1"
import { Step2 } from "./Step2"
import { Step3 } from "./Step3"
import { Step4 } from "./Step4"
import { Step5 } from "./Step5"
import { Step6 } from "./Step6"
import { Confirmation } from "./Confirmation"
import { useSelfTest } from ".."

// importing images in parent component to give time for them to load ahead of future steps <- Doesn't work, [need to update]
import ExtractionTubeDropper from "../../../../../../assets/self-test/antigen/step-1.jpg"
import NasalSample from "../../../../../../assets/self-test/antigen/step-2-1.jpg"
import InsertSample from "../../../../../../assets/self-test/antigen/step-2-2.jpg"
import RemoveSample from "../../../../../../assets/self-test/antigen/step-3-1.jpg"
import DropLiquidOnTest from "../../../../../../assets/self-test/antigen/step-3-2.jpg"
import ExampleTestResults from "../../../../../../assets/self-test/antigen/test-results.jpg"
// InteliSwab Images
import { ReactComponent as InteliSwabLeft } from "../../../../../../assets/self-test/antigen/InteliSwab/swab-left.svg"
import { ReactComponent as InteliSwabRight } from "../../../../../../assets/self-test/antigen/InteliSwab/swab-right.svg"
import { ReactComponent as InteliSwirl } from "../../../../../../assets/self-test/antigen/InteliSwab/swirl.svg"
import { ReactComponent as InteliResults } from "../../../../../../assets/self-test/antigen/InteliSwab/results.svg"
import { ReactComponent as InteliPlaceTube } from "../../../../../../assets/self-test/antigen/InteliSwab/place-tube.svg"
import { ReactComponent as InteliOpenPackaging } from "../../../../../../assets/self-test/antigen/InteliSwab/open-packaging.svg"

export const UnverifiedResult = ({ addToast, setTests, closeModal }) => {
  const { testData } = useSelfTest()

  return testData.step === 2 ? (
    <Step1 addToast={addToast} />
  ) : testData.step === 3 ? (
    <Step2
      images={[
        {
          src: ExtractionTubeDropper,
          alt: "Insert liquid into extraction tube",
        },
      ]}
      inteliSwabImages={[
        {
          src: <InteliOpenPackaging />,
          alt: "Remove InteliSwab Extraction Tube from packaging",
        },
        {
          src: <InteliPlaceTube />,
          alt: "Set up InteliSwab Extraction Tube holder",
        },
      ]}
    />
  ) : testData.step === 4 ? (
    <Step3
      addToast={addToast}
      images={[
        {
          src: NasalSample,
          alt: "Take a nasal sample",
        },
        {
          src: InsertSample,
          alt: "Insert sample into extraction tube",
        },
      ]}
      inteliSwabImages={[
        {
          src: <InteliSwabLeft />,
          alt: "Insert swab into your left nostril and rotate 15 times",
        },
        {
          src: <InteliSwabRight />,
          alt: "Insert swab into your right nostril and rotate 15 times",
        },
      ]}
    />
  ) : testData.step === 5 ? (
    <Step4
      addToast={addToast}
      images={[
        {
          src: RemoveSample,
          alt: "Remove nasal sample from extraction tube",
        },
        {
          src: DropLiquidOnTest,
          alt: "Drop sample liquid onto test strip",
        },
      ]}
      inteliSwabImages={[
        {
          src: <InteliSwirl />,
          alt: "Insert your sample into the InteliSwab extraction tube and swirl 10 times",
        },
      ]}
    />
  ) : testData.step === 6 ? (
    <Step5 addToast={addToast} />
  ) : testData.step === 7 ? (
    <Step6
      addToast={addToast}
      setTests={setTests}
      closeModal={closeModal}
      image={ExampleTestResults}
      inteliSwabImage={<InteliResults />}
    />
  ) : testData.step === 8 ? (
    <Confirmation closeModal={closeModal} />
  ) : null
}

UnverifiedResult.propTypes = {
  addToast: PropTypes.func.isRequired,
  setTests: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}
