import { BulletsIconBullet } from "./BulletsIconBullet"

interface Props {
  bullets: Array<Bullet>
  large?: boolean
}

interface Bullet {
  icon: "tick-circle-fill" | "cross-circle-fill"
  text: string
}

export const BulletsIcon = ({ bullets, large = false }: Props) => (
  <ul className={`bullets-icon${large ? " bullets-icon-large" : ""}`}>
    {bullets.map((bullet, index) => (
      <li key={index}>
        <BulletsIconBullet icon={bullet.icon} large={large}>
          {bullet.text}
        </BulletsIconBullet>
      </li>
    ))}
  </ul>
)
