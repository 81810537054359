import { freePlanId, premiumPlanId, familyPlanId } from ".."
import { planType, allPlansType } from "../../../types"

interface Props {
  planTier: number
  allPlans: allPlansType
}

export const getPlanName = ({ planTier, allPlans }: Props) => {
  const plan = allPlans.find((item: planType) => {
    if (planTier === 0) {
      return item?.sys?.id === freePlanId
    } else if (planTier === 1) {
      return item?.sys?.id === premiumPlanId
    } else if (planTier === 2) {
      return item?.sys?.id === familyPlanId
    } else {
      return {}
    }
  })
  return plan?.fields?.name
}
