import React from "react"
import PropTypes from "prop-types"
import moment from "moment-timezone"

import { ListCard } from "../../../shared"

export const SurveyResponsesCards = ({ data }) => (
  <div className="list-cards">
    {data.map((surveyResponse) => (
      <ListCard
        key={surveyResponse.id}
        id={surveyResponse.id}
        title={surveyResponse.patient && surveyResponse.patient.fullName}
        dateTime={moment(surveyResponse.completed).format("MM/DD/YYYY hh:mm A")}
        data={surveyResponse.surveyDefinition.name}
        link={`/surveys/${surveyResponse.id}`}
        icon={
          surveyResponse.outcome === "No symptoms"
            ? "tick-circle-fill"
            : "alert-circle-fill"
        }
      />
    ))}
  </div>
)

SurveyResponsesCards.propTypes = {
  data: PropTypes.array.isRequired,
}
