import React from "react"

import { Plan } from ".."
import { usePlan } from "../../../../hooks"
import { planType } from "../../../../types"

interface Props {
  variant: "sign-up" | "change-plan"
}

export const ChoosePlan = ({ variant }: Props) => {
  const { loading, allPlans } = usePlan()
  return (
    <>
      <header className="plans-info">
        <h1 className="heading-extra-large">Choose your plan</h1>
        <p>
          Manage your COVID-19 tests stress-free with a plan that suits your
          needs.
          <br /> Need a plan to manage tests for your employees?{" "}
          <a
            href="https://calendly.com/gotthetest/intro-call-demo"
            target="_blank"
            rel="noreferrer"
          >
            Contact us.
          </a>
        </p>
      </header>
      <main className="plans">
        {!loading &&
          allPlans.map((plan: planType) => (
            <Plan variant={variant} key={plan?.sys?.id} plan={plan} />
          ))}
      </main>
    </>
  )
}
