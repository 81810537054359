import React from "react"

interface Props {
  label: string
  children: React.ReactNode
  colSpan?: number
}

export const CardData = ({ label, children, colSpan }: Props) => (
  <div
    className={`card-data ${colSpan !== 1 ? `card-data-span-${colSpan}` : ""}`}
  >
    <span className="card-data-label">{label}</span>
    <span className="card-data-body">{children}</span>
  </div>
)
