import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import { useToasts } from "react-toast-notifications"
import { Helmet } from "react-helmet"
import axios from "axios"
import { navigate } from "@reach/router"
import { useQueryParam, StringParam } from "use-query-params"

import {
  Form,
  Input,
  InputPassword,
  Button,
  Checkbox,
  ButtonLink,
} from "../shared"
import { useAuth } from "../../hooks"
import { SEOTitleTemplate } from "../../constants"

interface SignUpFormValues {
  email: string
  password: string
  terms: boolean
}

export const SignUp = () => {
  const { addToast } = useToasts()
  const [plan] = useQueryParam("plan", StringParam)
  const authHook = useAuth()
  const [loading, setLoading] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)

  const onSubmit = async (
    values: SignUpFormValues,
    { setSubmitting }: { setSubmitting: (arg0: boolean) => void }
  ) => {
    try {
      setSubmitted(true)
      await authHook?.signUp({ email: values.email, password: values.password })
    } catch (error) {
      addToast("Something went wrong, please try again", {
        appearance: "error",
      })
      setSubmitting(false)
    }
  }

  React.useEffect(() => {
    if (plan) {
      localStorage.setItem("plan", plan)
    }
  }, [plan])

  React.useEffect(() => {
    const createAccount = async () => {
      setLoading(true)
      try {
        await axios({
          method: "post",
          url: "/user/create",
        })
        authHook?.setCreatingAccount(true)
        navigate("/create-account/confirm-email")
      } catch (error) {
        addToast("Something went wrong, please try again", {
          appearance: "error",
        })
      }
      setLoading(false)
    }

    if (authHook?.user && submitted) {
      createAccount()
    }
    return () => {
      setSubmitted(false)
    }
  }, [authHook?.user]) // eslint-disable-line

  return (
    <>
      <Helmet title={SEOTitleTemplate({ title: "Sign up" })} />
      <Formik
        initialValues={{
          email: "",
          password: "",
          terms: false,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Please enter a valid email address")
            .required("Please enter your email"),
          password: Yup.string().required("Please enter a password"),
          terms: Yup.boolean().oneOf(
            [true],
            "Please accept the GotTheTest terms of use before creating your account"
          ),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form variant="card">
            <h1 className="heading-large form-card-heading-central">Sign up</h1>
            <Input label="Email" name="email" />
            <InputPassword label="Password" name="password" />
            <Checkbox name="terms" label="I agree to the GotTheTest">
              <ButtonLink href="https://www.gotthetest.com/legal-pages/terms-of-use/">
                terms &amp; conditions
              </ButtonLink>
            </Checkbox>
            <Button
              type="submit"
              disabled={isSubmitting || loading}
              loading={isSubmitting || loading}
              formCard={true}
            >
              Sign up
            </Button>
            <Button variant="tertiary" to="/" formCard={true}>
              Already have an account?
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}
