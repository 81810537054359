import React from "react"
import PropTypes from "prop-types"

import { Tab } from "./Tab"

export const Tabs = ({ tabs }) => (
  <div className="tabs">
    {tabs.map((tab) => (
      <Tab key={tab.name} name={tab.name} url={tab.url} />
    ))}
  </div>
)

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired
  ),
}
