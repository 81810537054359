import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useToasts } from "react-toast-notifications"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import moment from "moment-timezone"

import { Modal } from "../../../shared"
import { SEOTitleTemplate } from "../../../../constants"
import { Step1 } from "./Step1"
import { Step2 } from "./Step2"
import { Step3 } from "./Step3"
import { NewAppointmentConfirmation } from "./NewAppointmentConfirmation"
import { usePatients } from "../../../../hooks"

const stripe = loadStripe(process.env.REACT_APP_STRIPE)

export const initialDate = new Date()

const defaultState = {
  step: 1,
  data: {
    patient: {
      id: "",
      fullName: "",
    },
    forTravel: true,
    localTimeZone: moment.tz(moment.tz.guess()).zoneAbbr(),
    departureDate: initialDate,
    departureTime: `${initialDate.getHours()}:${initialDate.getMinutes()}`,
    departureDateTime: null,
    destinationTravelWindow: 72,
    appointmentDate: null,
    appointment: {
      start: "",
      end: "",
    },
  },
}

export const NewAppointmentModal = ({
  modalVisible,
  closeModal,
  openViewAppointment,
  setViewAppointmentData,
  setEvents,
}) => {
  const { addToast } = useToasts()
  const { formattedPatients, rawPatients, error, loading } = usePatients()

  const [data, setData] = React.useState(defaultState)

  const dismissFunc = () => {
    setData(defaultState)
    closeModal()
  }

  return (
    <Modal
      isVisible={modalVisible}
      title="New appointment"
      dismissFunc={dismissFunc}
      header={data.step !== 4}
      size={data.step === 4 ? "medium" : "large"}
    >
      <Helmet title={SEOTitleTemplate({ title: "New appointment" })} />
      {data.step === 1 ? (
        <Step1
          data={data}
          setData={setData}
          addToast={addToast}
          patients={formattedPatients}
          rawPatients={rawPatients}
          loading={loading}
          error={error}
        />
      ) : data.step === 2 ? (
        <Step2 data={data} setData={setData} addToast={addToast} />
      ) : data.step === 3 ? (
        <Elements stripe={stripe}>
          <Step3
            data={data}
            setData={setData}
            addToast={addToast}
            setEvents={setEvents}
          />
        </Elements>
      ) : data.step === 4 ? (
        <NewAppointmentConfirmation
          data={data}
          closeModal={dismissFunc}
          openViewAppointment={openViewAppointment}
          setViewAppointmentData={setViewAppointmentData}
        />
      ) : null}
    </Modal>
  )
}

NewAppointmentModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}
