import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import "yup-phone"
import axios from "axios"
import { getAuth, updateProfile } from "firebase/auth"

import {
  AddressFinder,
  Button,
  Form,
  FormSection,
  Input,
  InputDateOfBirth,
  InputSelect,
  Modal,
  ModalBody,
  ModalFooter,
  FormLegend,
} from "../../../shared"
import {
  genderOptions,
  raceOptions,
  dateRegex,
  firebaseApp,
} from "../../../../constants"
import { handleAPIError } from "../../../../utilities"
import { patientType, patientFormType, toastType } from "../../../../types"

interface Props {
  accountHolder?: boolean
  addToast: toastType
  address: string
  dateOfBirth: string
  dismissFunc: () => void
  firstName: string
  gender: string
  id: string
  isVisible: boolean
  lastName: string
  phone: string
  race: string
  relationshipToAccountHolder?: string
  setPatients: (arg0: any) => void
}

export const UpdatePatientModal = ({
  accountHolder,
  addToast,
  address,
  dateOfBirth,
  dismissFunc,
  firstName,
  gender,
  id,
  isVisible,
  lastName,
  phone,
  race,
  relationshipToAccountHolder,
  setPatients,
}: Props) => {
  const [searchedAddress, setSearchedAddress] = React.useState()
  const [searchedAddressError, setSearchedAddressError] = React.useState("")
  const [manualAddressVisible, setManualAddressVisibility] =
    React.useState(true)
  const auth = getAuth(firebaseApp)
  const user = auth.currentUser

  const onSubmit = async (
    values: patientFormType,
    { setSubmitting }: { setSubmitting: any }
  ) => {
    if (searchedAddress === undefined && values.address === "") {
      setSearchedAddressError("Please find or enter an address")
      setManualAddressVisibility(true)
    } else {
      try {
        const response = await axios({
          method: "post",
          url: "/patient/update",
          data: { patientId: id, updates: values },
        })
        // check for accountHolder =  true ?
        await updateDisplayName(values.firstName + " " + values.lastName)
        addToast("Patient updated", {
          appearance: "success",
        })

        const updatedPatient = response.data
        setPatients((patients: Array<patientType>) => {
          const index = patients.findIndex(
            (patient) => patient.id === updatedPatient.id
          )

          const start = patients.slice(0, index)
          const end = patients.slice(index + 1)
          return [...start, updatedPatient, ...end]
        })

        dismissFunc()
      } catch (error) {
        addToast(handleAPIError(error, "update patient"), {
          appearance: "error",
        })
        setSubmitting(false)
      }
    }
  }

  const updateDisplayName = async (newName: string) => {
    try {
      if (user) {
        await updateProfile(user, {
          displayName: newName,
        })
      } else {
        addToast("Could not update patient profile, account not found", {
          appearance: "error",
        })
      }
    } catch (error) {
      addToast(handleAPIError(error, "update patient profile"), {
        appearance: "error",
      })
    }
  }

  return (
    <Modal
      dismissFunc={dismissFunc}
      isVisible={isVisible}
      title="Update patient"
    >
      <Formik
        initialValues={{
          relationshipToAccountHolder: relationshipToAccountHolder,
          firstName: firstName,
          lastName: lastName,
          dateOfBirth: dateOfBirth,
          gender: gender,
          race: race,
          address: address,
          phone: phone,
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required(
            "Please enter the patient's first name"
          ),
          lastName: Yup.string().required(
            "Please enter the patient's last name"
          ),
          relationshipToAccountHolder: Yup.string(),
          dateOfBirth: Yup.string()
            .required("Please enter the patient's date of birth")
            .matches(dateRegex, "Please enter a date in the format mm/dd/yyyy"),
          gender: Yup.string().required("Please select your gender"),
          race: Yup.string().required("Please select your race"),
          phone: Yup.string()
            .phone(
              "US",
              true,
              "Please enter a US phone number including the country code '+1'"
            )
            .required("Please your phone number"),
          address: Yup.string().required("Please choose a patient address"),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <ModalBody>
              {accountHolder && (
                <FormLegend>
                  <p>This is your information</p>
                </FormLegend>
              )}
              <FormSection>
                <Input name="firstName" label="First name" />
                <Input name="lastName" label="Last name" />
                <InputDateOfBirth name="dateOfBirth" label="Date of birth" />
                {!accountHolder ? (
                  <Input
                    name="relationshipToAccountHolder"
                    label="Relationship to you"
                  />
                ) : (
                  <div />
                )}
                <InputSelect
                  name="gender"
                  label="Gender"
                  options={genderOptions}
                />
                <InputSelect name="race" label="Race" options={raceOptions} />
                <Input name="phone" label="Mobile" placeholder="+11234567890" />
              </FormSection>
              <FormSection>
                <AddressFinder
                  searchedAddress={searchedAddress}
                  label="Find new address"
                  onChange={(address) => {
                    setSearchedAddress(address)
                    setFieldValue(
                      "address",
                      address && address.label ? address.label : ""
                    )
                  }}
                  error={searchedAddressError}
                >
                  {manualAddressVisible ? (
                    ""
                  ) : (
                    <button
                      onClick={() => setManualAddressVisibility(true)}
                      className="label-link"
                    >
                      Enter manually
                    </button>
                  )}
                </AddressFinder>
                <div className={manualAddressVisible ? "" : "not-visible"}>
                  <Input
                    name="address"
                    label="Your address"
                    placeholder="Full address"
                  />
                </div>
              </FormSection>
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Update patient
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
