import React from "react"
import PropTypes from "prop-types"
import axios from "axios"

import {
  Button,
  ModalBody,
  FormLegend,
  InputSelectResult,
  ModalFooter,
  ModalImageContainer,
} from "../../../../../../shared"
import { handleAPIError } from "../../../../../../../utilities"
import { usePCRTest } from "../.."

export const Step4 = ({ images, setTests }) => {
  const { testData, setTestData, addToast } = usePCRTest()
  const [loading, setLoading] = React.useState(false)

  const logSampleTime = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "post",
        url: "/tests/record-result",
        data: {
          testId: testData.data.id,
          result: {
            reading: {
              type: "PCR",
              data: "Pending",
            },
          },
        },
      })
      const updatedTest = response.data
      setTests((tests) => [updatedTest, ...tests])

      setTestData((testData) => ({
        step: testData.step + 1,
        data: { ...updatedTest },
      }))
      // setLoading not used to prevent 'memory leak'
    } catch (error) {
      addToast(handleAPIError(error, "save result"), {
        appearance: "error",
      })
      setLoading(false)
    }
  }
  return (
    <>
      <ModalBody>
        <FormLegend>
          <p>
            Please place the swab in the sterile tube and snap off the end of
            the swab at the break line. Place the cap on the tube and screw down
            tightly to prevent leakage.
          </p>
          <p>
            Place the sealed biohazard bag (with tube containing swab inside)
            into your prepaid package.
          </p>
        </FormLegend>
        <ModalImageContainer images={images} svg={true} />
        <InputSelectResult label="Your test ID">
          {testData?.data.id}
        </InputSelectResult>
      </ModalBody>
      <ModalFooter
        backFunc={() =>
          setTestData((testData) => ({
            step: testData.step - 1,
            data: { ...testData.data },
          }))
        }
      >
        <Button
          loading={loading}
          disabled={loading}
          onClick={() => logSampleTime()}
        >
          Save test
        </Button>
      </ModalFooter>
    </>
  )
}

Step4.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.object.isRequired,
      alt: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  setTests: PropTypes.func.isRequired,
}
