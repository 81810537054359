import React from "react"

import {
  ModalBody,
  FormLegend,
  ButtonWithIcon,
  Button,
  ResultTypeCard,
} from "../../../../../shared"
import { useSelfTest } from ".."

import { ReactComponent as VerifiedResult } from "../../../../../../assets/self-test/verified-result.svg"
import { ReactComponent as UnverifiedResult } from "../../../../../../assets/self-test/unverified-result.svg"

export const AntigenTestIntroduction = () => {
  const { setTestData } = useSelfTest()

  const nextStep = ({ verifiedResult }) => {
    setTestData((testData) => ({
      step: testData.step + 1,
      verifiedResult: verifiedResult,
      data: { ...testData.data },
    }))
  }

  return (
    <ModalBody>
      <FormLegend variant="button">
        <p>This form is for patients who already have a test.</p>
        <ButtonWithIcon
          href="https://store.gotthetest.com/"
          variant="secondary"
          icon="external-link"
          iconPosition="left"
        >
          Need a test?
        </ButtonWithIcon>
      </FormLegend>
      <section className="result-type-cards">
        <ResultTypeCard
          illustration={<UnverifiedResult />}
          title="Unverified result"
          paragraph="Photograph your test yourself and share your result instantly."
          note="Great for workplace testing"
        >
          <Button onClick={() => nextStep({ verifiedResult: false })}>
            I don’t need a verified result
          </Button>
        </ResultTypeCard>
        <ResultTypeCard
          illustration={<VerifiedResult />}
          title="Verified result"
          paragraph="Conduct your test on a video call with a medical professional."
          note="Required for international travel"
        >
          <Button onClick={() => nextStep({ verifiedResult: true })}>
            I need a verified result
          </Button>
        </ResultTypeCard>
      </section>
    </ModalBody>
  )
}
