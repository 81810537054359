export { checkCoupon } from "./checkCoupon"
export { generatePlanButtonText } from "./generatePlanButtonText"
export { getPlanNumber } from "./getPlanNumber"
export { getPlanName } from "./getPlanName"
export { getFreePlanMissedFeatures } from "./getFreePlanMissedFeatures"
export { checkFeaturePermission } from "./checkFeaturePermission"
export { calculatePercentageOff } from "./calculatePercentageOff"

export const freePlanId = "1pRR517FCRRQrEwJ4RtPSV"
export const premiumPlanId = "4C5KYC2gZfCpCNpVYPIpZY"
export const familyPlanId = "22XgSIw5qIzhg7pHROGzoE"
