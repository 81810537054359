import React from "react"
import { Link } from "@reach/router"

interface Props {
  children: React.ReactNode
  disabled?: boolean
  formCard?: boolean
  loading?: boolean
  href?: string
  onClick?: () => void
  to?: string
  type?: "button" | "submit"
  small?: boolean
  variant?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "delete"
    | "disabled"
    | "secondary button-secondary-form-legend"
    | "secondary button-fit-for-work-status"
    | "primary button-nav"
}

export const Button = ({
  children,
  disabled = false,
  formCard = false,
  loading = false,
  onClick,
  to = "",
  href = "",
  type = "button",
  variant = "primary",
  small = false,
}: Props) => {
  if (to) {
    return (
      <Link
        to={to}
        className={`button button-${variant}${
          formCard ? " button-form-card" : ""
        }${small ? "" : " button-large"}`}
      >
        {children}
      </Link>
    )
  } else if (onClick) {
    return (
      <button
        className={`button button-${variant}${
          loading ? " button-loading" : ""
        }${formCard ? " button-form-card" : ""}${small ? "" : " button-large"}`}
        onClick={onClick}
        type={type}
        disabled={disabled}
      >
        <span className="button-text">{children}</span>
      </button>
    )
  } else if (href) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className={`button button-${variant}${
          loading ? " button-loading" : ""
        }${formCard ? " button-form-card" : ""}${small ? "" : " button-large"}`}
      >
        {children}
      </a>
    )
  } else {
    return (
      <button
        className={`button button-${variant}${
          loading ? " button-loading" : ""
        }${formCard ? " button-form-card" : ""}${small ? "" : " button-large"}`}
        type={type}
        disabled={disabled}
      >
        <span className="button-text">{children}</span>
      </button>
    )
  }
}
