import { immunizations } from "../../../constants"

import {
  reactSelectOptionsType,
  immunizationType,
  vaccineType,
} from "../../../types"

interface Args {
  diseaseTargeted: string
}

export const calculateImmunizationVaccines = ({ diseaseTargeted }: Args) => {
  let formattedVaccines: reactSelectOptionsType = []

  immunizations.forEach((immunization: immunizationType) => {
    if (immunization.diseaseTargeted === diseaseTargeted) {
      immunization.vaccines.forEach((vaccine: vaccineType) => {
        formattedVaccines.push({
          value: vaccine.name,
          label: vaccine.name,
        })
      })
    }
  })

  return formattedVaccines
}
