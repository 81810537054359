import React from "react"
import axios from "axios"
import { useToasts } from "react-toast-notifications"

import { useAuth, usePlan } from "../../../../hooks"
import {
  getFreePlanMissedFeatures,
  premiumPlanId,
  familyPlanId,
  handleAPIError,
} from "../../../../utilities"
import { Button } from "../../../shared"

interface Props {
  setSubmitted: (arg0: boolean) => void
}

export const DowngradeToFree = ({ setSubmitted }: Props) => {
  const { allPlans } = usePlan()

  const authHook = useAuth()
  const planTier = authHook?.planTier
  const { addToast } = useToasts()

  const [loading, setLoading] = React.useState(false)
  const [freePlanMissedFeatures, setFreePlanMissedFeatures] = React.useState([])

  const downgradeToFreePlan = async () => {
    setLoading(true)
    try {
      await axios({
        method: "post",
        url: "/stripe/remove-subscription",
      })
      authHook?.setPlanTier(0)
      setSubmitted(true)
    } catch (error: any) {
      console.log(error, error.response)
      addToast(handleAPIError(error, "downgrade to free plan"), {
        appearance: "error",
      })
    }
    setLoading(false)
  }

  React.useEffect(() => {
    if (!loading) {
      setFreePlanMissedFeatures(
        getFreePlanMissedFeatures({
          plan: allPlans.find((item: any) => {
            if (planTier === 1) {
              // Premium
              return item?.sys?.id === premiumPlanId
            } else if (planTier === 2) {
              // Family
              return item?.sys?.id === familyPlanId
            } else {
              // Unknown?
              return "Can't find plan details"
            }
          }),
          allPlans: allPlans,
        })
      )
    }
    return () => {
      setFreePlanMissedFeatures([])
    }
  }, [allPlans, planTier, loading])

  return (
    <div className="change-plan-guidance">
      <span className="icon-alert-circle-outline change-plan-guidance-icon" />
      <h1 className="heading-large">Downgrade to free?</h1>
      <p className="change-plan-guidance-text">
        If you downgrade to free you'll loose the following features:
      </p>
      <ul className="list change-plan-guidance-list">
        {freePlanMissedFeatures.map((missedFeature) => (
          <li key={missedFeature} className="warning-modal-body-list-item">
            {missedFeature}
          </li>
        ))}
      </ul>
      <Button
        loading={loading}
        disabled={loading}
        onClick={() => downgradeToFreePlan()}
      >
        Downgrade to free
      </Button>
    </div>
  )
}
