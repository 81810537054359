import React from "react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

import { VerifiedStep1 } from "./VerifiedStep1"
import { VerifiedStep2 } from "./VerifiedStep2"
import { useSelfTest } from ".."

const stripe = loadStripe(process.env.REACT_APP_STRIPE)

export const VerifiedResult = ({ addToast }) => {
  const { testData } = useSelfTest()

  return testData.step === 2 ? (
    <VerifiedStep1 addToast={addToast} />
  ) : testData.step === 3 ? (
    <Elements stripe={stripe}>
      <VerifiedStep2 addToast={addToast} />
    </Elements>
  ) : null
}
