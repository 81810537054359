import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { Link } from "@reach/router"

import {
  Button,
  Form,
  FormLegend,
  FormSection,
  Input,
  InputSelect,
  InputSelectOther,
  ModalBody,
  ModalFooter,
} from "../../../../../../shared"
import { usePatients, useAuth } from "../../../../../../../hooks"
import {
  pcrTestManufacturers,
  testSampleTypeOptions,
} from "../../../../../../../constants"
import {
  handleAPIError,
  checkFeaturePermission,
} from "../../../../../../../utilities"
import { usePCRTest } from "../.."

export const Step1 = () => {
  const { formattedPatients, rawPatients, error, loading } = usePatients()
  const { setTestData, addToast } = usePCRTest()
  const { planTier } = useAuth()

  const onSubmit = async (values, { setSubmitting }) => {
    values.selfTest = true
    values.type = "PCR COVID-19 Test"
    if (
      !checkFeaturePermission({
        planTier: planTier,
        feature: "multiple-patients",
      })
    ) {
      values.patient = rawPatients.find((patient) => patient.accountHolder)?.id
    }

    try {
      const response = await axios({
        method: "post",
        url: "/tests/create",
        data: values,
      })
      const newTest = response.data
      setTestData((testData) => ({
        ...testData,
        step: testData.step + 1,
        data: { ...newTest },
      }))
      // setSubmitting not used to prevent 'memory leak'
    } catch (error) {
      addToast(handleAPIError(error, "create test"), {
        appearance: "error",
      })
      setSubmitting(false)
    }
  }

  React.useEffect(() => {
    if (error) {
      addToast(handleAPIError(error, "load patient options"), {
        appearance: "error",
      })
    }
  }, [error]) // eslint-disable-line

  return (
    <Formik
      initialValues={{
        patient: "",
        manufacturer: "DNA Genotek",
        batch: "",
        sampleType: "Saliva Collection Tube",
      }}
      validationSchema={Yup.object().shape({
        patient: checkFeaturePermission({
          planTier: planTier,
          feature: "multiple-patients",
        })
          ? Yup.string().required("Please choose a patient")
          : Yup.string(),
        manufacturer: Yup.string().required(
          "Please enter the manufacturer of your test"
        ),
        batch: Yup.string().required(
          "Please enter the batch number printed on your PCR test sticker"
        ),
        sampleType: Yup.string().required(
          "Please enter the sample type your test requires"
        ),
      })}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <ModalBody>
            <FormLegend>
              <p>
                You’ve chosen to complete a new PCR test and follow instructions
                to send your test to a CLIA Lab.
              </p>
              <p>
                Which PCR COVID-19 test is being used
                {checkFeaturePermission({
                  planTier: planTier,
                  feature: " multiple-patients",
                })
                  ? "and who is taking the test?"
                  : "?"}
              </p>
            </FormLegend>
            <FormSection>
              {checkFeaturePermission({
                planTier: planTier,
                feature: "multiple-patients",
              }) && (
                <>
                  <InputSelect
                    loading={loading}
                    label="Patient"
                    name="patient"
                    options={formattedPatients}
                  >
                    <Link className="label-link" to="/account/patients">
                      New patient
                    </Link>
                  </InputSelect>{" "}
                  <div />
                </>
              )}
              <InputSelectOther
                label="Test manufacturer"
                name="manufacturer"
                options={pcrTestManufacturers}
              />
              <Input label="Batch/Lot" name="batch" />
              <InputSelect
                label="Sample type"
                name="sampleType"
                options={testSampleTypeOptions}
              />
            </FormSection>
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Prepare your test
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}
