import React from "react"
import { Helmet } from "react-helmet"

import { SEOTitleTemplate } from "../../../constants"
import { ChoosePlan } from "../../shared"

export const ChooseNewPlan = () => (
  <>
    <Helmet title={SEOTitleTemplate({ title: "Choose your plan" })} />
    <ChoosePlan variant="change-plan" />
  </>
)
