import React from "react"
import PropTypes from "prop-types"
import { Link } from "@reach/router"

export const Tab = ({ name, url }) => {
  const isCurrentTab = ({ isCurrent }) => {
    return isCurrent ? { className: "tab active" } : { className: "tab" }
  }

  return (
    <Link getProps={isCurrentTab} to={url}>
      {name}
    </Link>
  )
}

Tab.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}
