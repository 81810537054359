import React from "react"
import axios from "axios"

import { Button, FormLegend, WarningModal } from "../../../shared"
import { Report } from "./Report"
import { useAuth } from "../../../../hooks"
import {
  handleAPIError,
  updateLatestStatusReportingGroup,
} from "../../../../utilities"
import {
  patientType,
  statusReportingGroup,
  toastType,
  statusReportingGroupDefinition,
  statusReportingGroupReport,
} from "../../../../types"

interface Props {
  addToast: toastType
  id: string
  name: string
  definition: statusReportingGroupDefinition
  patient: patientType
  setReportingGroups: Function
  liveReport: statusReportingGroupReport
  code: string
}

export const ReportingGroup = ({
  addToast,
  id,
  name,
  definition,
  patient,
  setReportingGroups,
  liveReport,
  code,
}: Props) => {
  const authHook = useAuth()
  const [
    leaveReportingGroupModalVisible,
    setLeaveReportingGroupModalVisibility,
  ] = React.useState(false)
  const [leaveReportingGroupLoading, setLeaveReportingGroupLoading] =
    React.useState(false)

  const leaveReportingGroup = async () => {
    setLeaveReportingGroupLoading(true)
    try {
      await axios({
        method: "post",
        url: "/status-reporting-groups/leave-group",
        data: { statusReportingGroupId: id, patientId: patient.id },
      })
      setReportingGroups((reportingGroups: Array<statusReportingGroup>) =>
        reportingGroups.filter(
          (reportingGroup) => reportingGroup.statusReportingGroup.id !== id
        )
      )
      updateLatestStatusReportingGroup({
        userId: authHook?.user.uid,
        addToast: addToast,
        setFitForWorkReport: authHook?.setFitForWorkReport,
      })
      // hit /user/:id to update nav bar
      addToast(`You have left ${name} reporting group`, {
        appearance: "success",
      })
      setLeaveReportingGroupModalVisibility(false)
    } catch (error) {
      addToast(handleAPIError(error, "leave reporting group"), {
        appearance: "error",
      })
    }
    setLeaveReportingGroupLoading(false)
  }

  return (
    <section className="reporting-group">
      <div>
        <h2 className="heading-small reporting-group-details-heading">
          {name} ({code})
        </h2>
        <FormLegend>
          <p>Access rights:</p>
          {definition?.accessRights.length > 0 && (
            <ul className="list">
              {definition?.accessRights.map((accessRight) => (
                <li key={`${accessRight.model}.${accessRight.key}`}>
                  {patient.accountHolder
                    ? `Your ${accessRight.description}`
                    : `${patient.fullName}'s ${accessRight.description}`}
                </li>
              ))}
            </ul>
          )}
        </FormLegend>
        <Report
          key={liveReport.id}
          id={liveReport.id}
          taskResults={liveReport.taskResults}
          status={liveReport.status}
          variant="live-report"
        />
        {/* 
        // For the static reports we used to create before moving to live reporting with the employer portal
        {reports && reports.length > 0 && (
          <div className="reporting-group-past-reporting">
            <ButtonWithIcon
              iconPosition="left"
              icon={pastReportsVisible ? "chevron-up" : "chevron-down"}
              onClick={() => setPastReportsVisibility((cur) => !cur)}
              variant="secondary"
            >
              Older reports ({reports.length})
            </ButtonWithIcon>
            <section
              className={`reporting-group-past-reports${
                pastReportsVisible ? " reporting-group-past-reports-active" : ""
              }`}
            >
              {reports.map((report) => (
                <Report
                  key={report.id}
                  id={report.id}
                  taskResults={report.taskResults}
                  end={report.validUntil}
                  start={report.created}
                  status={report.status}
                  variant="past-report"
                />
              ))}
            </section>
          </div>
        )} */}
      </div>
      <div className="reporting-group-actions">
        <div>
          <Button
            variant="secondary"
            onClick={() => setLeaveReportingGroupModalVisibility(true)}
          >
            Leave reporting group
          </Button>
        </div>
      </div>
      <WarningModal
        isVisible={leaveReportingGroupModalVisible}
        dismissFunc={() => setLeaveReportingGroupModalVisibility(false)}
        title="Leave reporting group?"
        deleteButtonText="Leave reporting group"
        deleteFunc={() => leaveReportingGroup()}
        deleteButtonLoading={leaveReportingGroupLoading}
      >
        {patient.accountHolder ? "You" : patient.fullName} will not longer
        report {definition.name} status to {name} {/* Should be company name */}
      </WarningModal>
    </section>
  )
}
