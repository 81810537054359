import React from "react"
import { Helmet } from "react-helmet"
import { Calendar, momentLocalizer } from "react-big-calendar"
import moment from "moment-timezone"

import { ButtonWithIcon, Tag } from "../../shared"
import { SEOTitleTemplate } from "../../../constants"
import { useQuery } from "../../../hooks"
import { CustomToolbar } from "./CustomToolbar"
import { NewAppointmentModal } from "./NewAppointmentModal"
import { ViewAppointment } from "./ViewAppointment"
import { CancelAppointment } from "./CancelAppointment"
import { useToasts } from "react-toast-notifications"
import { handleAPIError, convertDateToTimezone } from "../../../utilities"
import { BetaModal } from "./BetaModal"

const localizer = momentLocalizer(moment)

export const AppointmentList = () => {
  const { addToast } = useToasts()
  const { data, error, loading } = useQuery({
    url: "/user/appointments",
    requestData: { _expand: ["patient"] },
  })

  const [newAppointmentModalVisible, setNewAppointmentModalVisibility] =
    React.useState(false)
  const [viewAppointmentModalVisible, setViewAppointmentModalVisibility] =
    React.useState(false)
  const [cancelAppointmentModalVisible, setCancelAppointmentModalVisibility] =
    React.useState(false)
  const [events, setEvents] = React.useState([])
  const [viewAppointmentData, setViewAppointmentData] = React.useState({
    start: new Date(),
    end: new Date(),
    title: "",
    originalTitle: "",
    resource: {
      patient: {
        id: "",
        fullName: "",
      },
      mendAppointment: {
        shortUrl: "",
        visitUrl: "",
        visitUri: "",
      },
      id: "",
    },
  })
  const [cancelAppointmentData, setCancelAppointmentData] = React.useState({
    start: new Date(),
    end: new Date(),
    title: "",
    originalTitle: "",
    resource: {
      patient: {
        id: "",
        fullName: "",
      },
      mendAppointment: {
        shortUrl: "",
        visitUrl: "",
        visitUri: "",
      },
      id: "",
    },
  })

  React.useEffect(() => {
    if (error) {
      addToast(handleAPIError(error, "get appointments"), {
        appearance: "error",
      })
    }
  }, [error]) // eslint-disable-line

  React.useEffect(() => {
    const parseISODates = () => {
      let calendarEvents = []

      data.forEach((event) => {
        // mutating dates for ISO format to instanceOf(Date)
        event.start = new Date(event.start)
        event.end = new Date(event.end)
        event.originalTitle = event.title
        event.title = event?.resource?.patient?.fullName

        calendarEvents.push(event)
      })
      // remove
      setEvents(calendarEvents)
    }

    if (!loading && data) {
      parseISODates()
    }
  }, [data, loading])

  const timeInNY = convertDateToTimezone(
    new Date(),
    "America/New_York"
  ).getHours()
  const localTime = new Date().getHours()
  const timeDifference = timeInNY - localTime
  const openingHour = 7 - timeDifference // Ascend opens from 7am America/New_York time
  const closingHour = 19 - timeDifference // Ascend closes from 7pm America/New_York time
  const today = new Date()

  return (
    <>
      <Helmet title={SEOTitleTemplate({ title: "Your appointments" })} />
      <header className="list-page-header">
        <div className="list-page-header-tag">
          <h1 className="heading-extra-large list-page-header-tag-heading">
            Appointments
          </h1>
          <Tag type="white">BETA</Tag>
        </div>
        <section className="list-page-header-dropdowns">
          <ButtonWithIcon
            icon="plus"
            iconPosition="left"
            onClick={() => setNewAppointmentModalVisibility(true)}
          >
            New appointment
          </ButtonWithIcon>
        </section>
      </header>
      <section className={`calendar${loading ? " calendar-loading" : ""}`}>
        <Calendar
          components={{ toolbar: CustomToolbar }}
          localizer={localizer}
          events={events}
          view="week"
          views={["week"]}
          startAccessor="start"
          endAccessor="end"
          // 7am NY respectively in local timezone
          min={
            new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate(),
              openingHour
            )
          }
          // 7pm NY respectively in local timezone
          max={
            closingHour < 24
              ? new Date(
                  today.getFullYear(),
                  today.getMonth(),
                  today.getDate(),
                  closingHour
                )
              : new Date(
                  today.getFullYear(),
                  today.getMonth(),
                  today.getDate(),
                  23,
                  59
                )
          }
          onView={() => null}
          style={{ height: 768 }}
          onSelectEvent={(slot) => {
            setViewAppointmentData(slot)
            setViewAppointmentModalVisibility(true)
          }}
        />
        <span className="calendar-loader" />
      </section>
      <NewAppointmentModal
        modalVisible={newAppointmentModalVisible}
        closeModal={() => setNewAppointmentModalVisibility(false)}
        openViewAppointment={() => setViewAppointmentModalVisibility(true)}
        setViewAppointmentData={setViewAppointmentData}
        setEvents={setEvents}
      />
      <ViewAppointment
        modalVisible={viewAppointmentModalVisible}
        closeModal={() => setViewAppointmentModalVisibility(false)}
        openCancelModal={() => {
          setViewAppointmentModalVisibility(false)
          setCancelAppointmentModalVisibility(true)
          setCancelAppointmentData(viewAppointmentData)
        }}
        data={viewAppointmentData}
      />
      <CancelAppointment
        modalVisible={cancelAppointmentModalVisible}
        closeModal={() => setCancelAppointmentModalVisibility(false)}
        data={cancelAppointmentData}
        addToast={addToast}
        setEvents={setEvents}
      />
      <BetaModal
        isVisible={true}
        closeModal={() => {
          /* setBetaModalVisible(false)
          localStorage.setItem("hasSeenBetaMessage", "true") */
        }}
      />
    </>
  )
}
