import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import { Helmet } from "react-helmet"
import moment from "moment-timezone"

import { Modal, Button, Dialog } from "../../../shared"
import { handleAPIError } from "../../../../utilities"
import { SEOTitleTemplate } from "../../../../constants"
import { AppointmentReview } from "../AppointmentReview"

export const CancelAppointment = ({
  modalVisible,
  closeModal,
  data,
  addToast,
  setEvents,
}) => {
  const [loading, setLoading] = React.useState(false)

  const cancelAppointment = async () => {
    setLoading(true)
    try {
      await axios({
        method: "post",
        url: "/appointment/delete",
        data: { appointmentId: data.resource.id },
      })
      addToast(
        "Appointment cancelled, you will receive a refund with 5 working days",
        { appearance: "success" }
      )
      setEvents((events) =>
        events.filter((event) => event.resource.id !== data.resource.id)
      )
      closeModal()
    } catch (error) {
      addToast(handleAPIError(error, "cancel appointment"), {
        appearance: "error",
      })
      setLoading(false)
    }
  }

  return (
    <Modal
      dismissFunc={closeModal}
      isVisible={modalVisible}
      size="medium"
      header={false}
    >
      <Helmet
        title={SEOTitleTemplate({
          title: `Cancel appointment ${moment(data.start).format(
            "MM/DD/YYYY hh:mm A"
          )}?`,
        })}
      />
      <Dialog
        title="Cancel appointment?"
        icon="alert-circle-outline"
        description="The card used will be refunded in full within 7 working days. Cancelations within 24 hours of an appointment will not be refunded."
        footer={
          <>
            <Button
              variant="delete"
              loading={loading}
              disabled={loading}
              onClick={() => cancelAppointment()}
            >
              Cancel appointment
            </Button>
            <Button onClick={closeModal} variant="secondary">
              Don't cancel
            </Button>
          </>
        }
      >
        <AppointmentReview
          title={data.originalTitle}
          dates={{
            startTime: data.start,
            endTime: data.end,
          }}
          patient={data.resource.patient.fullName}
        />
      </Dialog>
    </Modal>
  )
}

CancelAppointment.propTypes = {
  addToast: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  setEvents: PropTypes.func.isRequired,
}
