import axios from "axios"

import { handleAPIError } from "../.."
import { toastType } from "../../../types"

interface Props {
  userId: string
  addToast: toastType
  setFitForWorkReport: any // Update to real type
}

export const updateLatestStatusReportingGroup = async ({
  userId,
  addToast,
  setFitForWorkReport,
}: Props) => {
  if (userId && addToast && setFitForWorkReport) {
    try {
      const response = await axios({
        method: "post",
        url: `/user/${userId}`,
      })

      if (response?.data?.liveStatusReportingGroupReports.length > 0) {
        setFitForWorkReport(response?.data?.liveStatusReportingGroupReports[0])
      }
    } catch (error) {
      addToast(
        handleAPIError(error, "update status report navigation bar button"),
        {
          appearance: "error",
        }
      )
    }
  } else {
    console.log("Did not recieve userId, addToast or setFitForWorkReport")
  }
}
