import React from "react"
import PropTypes from "prop-types"
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"
import axios from "axios"

import {
  Label,
  ModalBody,
  ModalFooter,
  ButtonWithIcon,
  FormSection,
  ButtonLinkWithIcon,
  PassCodeDecision,
} from "../../../../shared"
import { handleAPIError } from "../../../../../utilities"
import { useAuth } from "../../../../../hooks"
import { AppointmentReview } from "../../AppointmentReview"

export const Step3 = ({ data, setData, addToast, setEvents }) => {
  const stripe = useStripe()
  const elements = useElements()
  const auth = useAuth()
  const appointment = data.data.appointment

  //
  const [passcodeFormSubmitted, setPasscodeFormSubmitted] =
    React.useState(false)
  const [hasPassCode, setHasPassCode] = React.useState(false)
  const [passcode, setPasscode] = React.useState({
    value: "",
    error: "",
    loading: false,
  })
  // Stripe state variables
  const [gettingPaymentIntent, setGettingPaymentIntent] = React.useState(false)
  const [paymentIntentError, setPaymentIntentError] = React.useState(false)
  const [clientSecret, setClientSecret] = React.useState("")
  const [disabled, setDisabled] = React.useState(true)
  const [error, setError] = React.useState(null)
  const [processing, setProcessing] = React.useState(false)
  const [succeeded, setSucceeded] = React.useState(false)

  const handleChange = async (event) => {
    setDisabled(event.empty)
    setError(event.error ? event.error.message : "")
  }

  const handleSubmit = async (ev) => {
    ev.preventDefault()

    setProcessing(true)
    try {
      const payload = await stripe.confirmCardPayment(clientSecret, {
        receipt_email: auth.user.email,
        payment_method: {
          card: elements.getElement(CardElement),
        },
      })
      if (payload.error) {
        console.log(payload.error)
        setError("Payment card failed, please try again or use another card")
        setProcessing(false)
      } else {
        setEvents((events) => [
          ...events,
          {
            start: new Date(appointment.startTime),
            end: new Date(appointment.endTime),
            title: data.data.patient.fullName,
            originalTitle: appointment.title,
            resource: {
              patient: {
                ...data.data.patient,
              },
              mendAppointment: {
                ...appointment.mendAppointment,
              },
              id: appointment.id,
            },
          },
        ])
        setError(null)
        setSucceeded(true)
        setData((data) => ({
          ...data,
          step: data.step + 1,
        }))
      }
    } catch (error) {
      setProcessing(false)
      addToast(handleAPIError(error, "book appointment"), {
        appearance: "error",
      })
    }
  }

  const handlePaymentIntentResponse = (paymentRequired) => {
    // by default passcode is null so if a passcode exists the users has changed the value
    if (passcode.value !== "") {
      if (paymentRequired) {
        // If the user has changee passcode value but the server responded with paymentRequired we don't recognise the code
        setPasscode((passcode) => ({
          ...passcode,
          loading: false,
          error: "Sorry, this passcode doesn't match our records",
        }))
      } else {
        // Code was recognised, appointment ready to be joined
        setPasscode((passcode) => ({ ...passcode, loading: false, error: "" }))
        setEvents((events) => [
          ...events,
          {
            start: new Date(appointment.startTime),
            end: new Date(appointment.endTime),
            title: data.data.patient.fullName,
            originalTitle: appointment.title,
            resource: {
              patient: {
                ...data.data.patient,
              },
              mendAppointment: {
                ...appointment.mendAppointment,
              },
              id: appointment.id,
            },
          },
        ])
        setError(null)
        setSucceeded(true)
        setData((data) => ({
          ...data,
          step: data.step + 1,
        }))
      }
    }
  }

  const createPaymentIntent = async () => {
    setGettingPaymentIntent(true)

    try {
      let requestData = {}

      if (hasPassCode) {
        requestData = {
          appointmentId: data.data.appointment.id,
          couponCode: passcode.value,
        }
      } else {
        requestData = {
          appointmentId: data.data.appointment.id,
        }
      }

      const getPaymentIntent = await axios({
        method: "post",
        url: "/stripe/create-appointment-payment",
        data: requestData,
      })
      setClientSecret(getPaymentIntent.data.clientSecret)
      setPaymentIntentError(false)
      handlePaymentIntentResponse(getPaymentIntent.data.paymentRequired)
    } catch (error) {
      console.log(error)
      addToast(handleAPIError(error, "set up payment form"), {
        appearance: "error",
      })
      setPaymentIntentError(true)
    }
    setGettingPaymentIntent(false)
  }

  const submitPasscode = () => {
    if (passcode === "") {
      setPasscode((passcode) => ({
        ...passcode,
        loading: true,
        error: "Please enter a passcode",
      }))
    } else {
      setPasscode((passcode) => ({ ...passcode, loading: true }))
      createPaymentIntent()
    }
  }

  const handlePasscodeDecision = ({ usePasscode }) => {
    if (usePasscode) {
      setPasscodeFormSubmitted(true)
      setHasPassCode(true)
    } else {
      setPasscodeFormSubmitted(true)
      setHasPassCode(false)
    }
  }

  React.useEffect(() => {
    if (!hasPassCode) {
      createPaymentIntent()
    }
  }, [hasPassCode]) // eslint-disable-line

  return (
    <form onSubmit={handleSubmit}>
      <ModalBody>
        <AppointmentReview
          title="Rapid Antigen COVID-19 Test Result Video Verification"
          dates={{
            startTime: new Date(appointment.startTime),
            endTime: new Date(appointment.endTime),
          }}
          patient={data.data.patient.fullName}
        />
        {!passcodeFormSubmitted ? (
          <PassCodeDecision
            hasPassCodeFunc={() =>
              handlePasscodeDecision({ usePasscode: true })
            }
            noPassCodeFunc={() =>
              handlePasscodeDecision({ usePasscode: false })
            }
          />
        ) : (
          <>
            <ButtonLinkWithIcon
              icon="arrow-left"
              iconPosition="left"
              onClick={() => setHasPassCode((current) => !current)}
            >
              {hasPassCode
                ? "Don't have an employer code?"
                : "Have an employer code?"}
            </ButtonLinkWithIcon>
            <FormSection
              additionalClasses={`payment-form${
                !hasPassCode && gettingPaymentIntent
                  ? " payment-form-loading"
                  : ""
              }${paymentIntentError ? " payment-form-has-error" : ""}`}
            >
              {hasPassCode ? (
                <div>
                  <Label field="passcode">Your employer code</Label>
                  <input
                    onChange={(e) => {
                      if (e.target.value !== "") {
                        setPasscode((passcode) => ({
                          ...passcode,
                          error: "",
                          value: e.target.value,
                        }))
                      } else {
                        setPasscode((passcode) => ({
                          ...passcode,
                          value: e.target.value,
                        }))
                      }
                    }}
                    className={`input${passcode.error ? " input-error" : ""}`}
                    type="text"
                    name="passcode"
                    id="passcode"
                    value={passcode.value}
                  />
                  <span className="input-error-text">
                    {passcode.error ? passcode.error : ""}
                  </span>
                </div>
              ) : (
                <div>
                  <Label field="card-element">Your card details</Label>
                  <CardElement
                    id="card-element"
                    options={{
                      hidePostalCode: true,
                      style: {
                        base: {
                          color: "#222",
                          fontFamily: "Arial, sans-serif",
                          fontSmoothing: "antialiased",
                          fontSize: "16px",
                          "::placeholder": {
                            color: "#999",
                          },
                        },
                        invalid: {
                          color: "#d62b62",
                          iconColor: "#fa755a",
                        },
                      },
                    }}
                    onChange={handleChange}
                  />
                  <span className="input-error-text">{error}</span>
                </div>
              )}
              <span className="payment-form-loader" />
              <div className="payment-form-error">
                <div className="payment-form-error-content">
                  <span className="icon-alert-circle-outline payment-form-error-icon" />
                  <p className="payment-form-error-text">
                    Could not set up payment form, please go back and try again
                  </p>
                </div>
              </div>
            </FormSection>
          </>
        )}
      </ModalBody>
      {passcodeFormSubmitted && (
        <ModalFooter>
          {hasPassCode ? (
            <ButtonWithIcon
              icon="lock"
              iconPosition="left"
              onClick={() => submitPasscode()}
              type="button"
              loading={passcode.loading}
              disabled={passcode.loading}
            >
              Book appointment
            </ButtonWithIcon>
          ) : (
            <ButtonWithIcon
              icon="lock"
              iconPosition="left"
              type="submit"
              id="submit"
              loading={processing}
              disabled={processing || disabled || succeeded}
            >
              Book appointment ($20.00)
            </ButtonWithIcon>
          )}
        </ModalFooter>
      )}
    </form>
  )
}

Step3.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  addToast: PropTypes.func.isRequired,
  setEvents: PropTypes.func.isRequired,
}
