import React from "react"
import { Router } from "@reach/router"

import { AppointmentList } from "./AppointmentList"

export const Appointments = () => (
  <Router>
    <AppointmentList path="/" />
  </Router>
)
