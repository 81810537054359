import React from "react"

interface Props {
  heading: string
  onClick: () => void
}

export const AddNewButton = ({ heading, onClick }: Props) => (
  <button className="add-new-button" onClick={onClick}>
    <span className="add-new-button-content">
      <span className="icon-plus" />
      <span className="add-new-button-content-text">{heading}</span>
    </span>
  </button>
)
