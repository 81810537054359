import React from "react"

import { Button, Tag } from "../../../shared"

interface Props {
  status: boolean
}

export const FitForWorkStatusButton = ({ status }: Props) => (
  <Button
    variant="secondary button-fit-for-work-status"
    to="/account/status-reporting"
  >
    <Tag icon type={status ? "success" : "error"} variant="no-background">
      {status ? "You are Fit for Work" : "You are not Fit for Work"}
    </Tag>
  </Button>
)
