import React from "react"

interface Props {
  children: React.ReactNode
  description?: string
  dismissFunc: () => void
  isVisible: boolean
  title: string
}

export const ModalSmall = ({
  children,
  description,
  dismissFunc,
  isVisible,
  title,
}: Props) => {
  React.useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "visible"
    }

    return () => {
      document.body.style.overflow = "visible"
    }
  }, [isVisible])

  if (isVisible) {
    return (
      <>
        <div className="modal modal-small">
          <header className="modal-small-header">
            <h2 className="heading-small modal-small-header-title">{title}</h2>
            {description ? (
              <p className="modal-small-header-description">{description}</p>
            ) : (
              ""
            )}
          </header>
          <div className="modal-small-body">{children}</div>
        </div>
        <div
          className={`modal-overlay`}
          role="button"
          aria-label="Close pop-up"
          onClick={dismissFunc}
        />
      </>
    )
  } else {
    return null
  }
}
