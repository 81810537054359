import React from "react"

import { IconButton } from "../.."

interface Props {
  children: React.ReactNode
  dismissFunc: () => void
  header?: boolean
  isVisible: boolean
  title?: string
  size?: "large" | "medium"
}

export const Modal = ({
  children,
  dismissFunc,
  header = true,
  isVisible,
  title = "",
  size = "large",
}: Props) => {
  React.useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "visible"
    }

    return () => {
      document.body.style.overflow = "visible"
    }
  }, [isVisible])

  if (isVisible) {
    return (
      <>
        <div className={`modal${size === "medium" ? " modal-medium" : ""}`}>
          {header && (
            <header className="modal-header">
              <h2 className="heading-small modal-header-title">{title}</h2>
              <IconButton onClick={dismissFunc} icon="cross" />
            </header>
          )}
          {children}
        </div>
        <div
          className={`modal-overlay`}
          role="button"
          aria-label="Close pop-up"
          onClick={dismissFunc}
        />
      </>
    )
  } else {
    return null
  }
}
