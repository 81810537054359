import React from "react"
import { Page, Text, View, Image, Document } from "@react-pdf/renderer"
import moment from "moment-timezone"

import { PDFStyles, TableCell, TableHeaderCell } from "../../../shared"
import GotTheTestLogoDark from "../../../../assets/GotTheTest-logo-dark.jpg"
import { immunizationRecordType } from "../../../../types"

export const ImmunizationPDF = ({ data }: { data: immunizationRecordType }) => {
  return (
    <Document
      title={`${data?.vaccineManufacturer} ${data?.patient?.fullName}`}
      author="GotTheTest"
      subject={`Immunization saved ${moment(data.created).format(
        "MM-DD-yyyy"
      )} ${data?.patient?.fullName}`}
    >
      <Page size="A4" style={PDFStyles.page}>
        <View style={PDFStyles.header}>
          <Image style={PDFStyles.headerLogo} src={GotTheTestLogoDark} />
          <View style={PDFStyles.headerCompanyInfo}>
            <Text>
              GotTheTest Inc. 2139 Placentia Ave, Costa Mesa, CA, 92627, United
              States gotthetest.portmanteau.app
            </Text>
          </View>
          <View>
            <Text style={PDFStyles.headerTestIDBold}>Immunization ID:</Text>
            <Text style={PDFStyles.headerTestID}>{data?.id}</Text>
          </View>
        </View>
        <View style={PDFStyles.table}>
          <View style={PDFStyles.tableRow}>
            <TableCell
              width="25%"
              label="Patient Name"
              value={data?.patient?.fullName}
            />
            <TableCell
              width="25%"
              label="Patient ID"
              value={data?.patient?.id}
            />
            <TableCell
              width="17%"
              label="Patient Phone"
              value={data?.patient?.phone}
            />
            <TableCell
              width="17%"
              label="DOB"
              value={data?.patient?.dateOfBirth}
            />
            <TableCell
              width="16%"
              label="Gender"
              value={data?.patient?.gender}
            />
          </View>
          <View style={PDFStyles.tableRow}>
            <TableCell label="Address" value={data?.patient?.address} />
          </View>
        </View>
        <View style={PDFStyles.table}>
          <View style={PDFStyles.tableRow}>
            <View style={PDFStyles.tableColumn50}>
              <View style={PDFStyles.tableRow}>
                <TableHeaderCell header="Immunization" />
              </View>
              <View style={PDFStyles.tableRow}>
                <TableCell label="Vaccine" value={data?.name} />
              </View>
              <View style={PDFStyles.tableRow}>
                <TableCell
                  label="Disease targeted"
                  value={data?.diseaseTargeted}
                />
              </View>
              <View style={PDFStyles.tableRow}>
                <TableCell
                  label="Saved"
                  value={moment(data?.created).format("MM/DD/YYYY hh:mm A")}
                />
              </View>
            </View>
            <View style={PDFStyles.tableColumn50}>
              <View style={PDFStyles.tableRow}>
                <TableHeaderCell header="Dose" />
              </View>
              <View style={PDFStyles.tableRow}>
                <TableCell
                  label="Dose Date"
                  value={data?.vaccinationDate.toString()}
                />
              </View>
              <View style={PDFStyles.tableRow}>
                <View style={PDFStyles.tableResult2Cell}>
                  <Text style={PDFStyles.tableResultText}>
                    {data?.vaccineManufacturer}
                  </Text>
                  <Text style={PDFStyles.fontCentered}>
                    Dose {data?.dose} of{" "}
                    {data?.vaccineManufacturer === "Moderna" ||
                    data?.vaccineManufacturer === "Pfizer/BioNTech"
                      ? "2"
                      : "1"}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}
