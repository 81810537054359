import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import { Helmet } from "react-helmet"
import axios from "axios"
import { useToasts } from "react-toast-notifications"
import {
  getAuth,
  reauthenticateWithCredential,
  updateEmail,
  EmailAuthProvider,
} from "firebase/auth"

import {
  Form,
  Input,
  Button,
  ModalSmall,
  InputPassword,
  FormLegend,
} from "../../shared"
import { SEOTitleTemplate, firebaseApp } from "../../../constants"
import { useAuth } from "../../../hooks"

export const ChangeEmail = () => {
  const authHook = useAuth()
  const { addToast } = useToasts()
  const [modalVisible, setModalVisibility] = React.useState(false)
  const [newEmail, setNewEmail] = React.useState("")
  const [oldEmail, setOldEmail] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const auth = getAuth(firebaseApp)
  const user = auth.currentUser

  const revealPassword = (
    values: { email: string },
    { setSubmitting }: { setSubmitting: any }
  ) => {
    setModalVisibility(true)
    setNewEmail(values.email)
    setSubmitting(false)
    setOldEmail(authHook?.user.email)
  }

  const onSubmit = async (
    values: { password: string },
    { setSubmitting }: { setSubmitting: any }
  ) => {
    try {
      if (user) {
        await reauthenticateWithCredential(
          user,
          EmailAuthProvider.credential(authHook?.user.email, values.password)
        )
      }
      await updateEmailFirebase(newEmail, { updateAPI: true })
    } catch (error) {
      addToast("Could not update email address, password was incorrect", {
        appearance: "error",
      })
    }
    setSubmitting(false)
  }

  const updateEmailFirebase = async (
    newEmail: string,
    { updateAPI }: { updateAPI: boolean }
  ) => {
    setLoading(true)
    try {
      if (user) {
        await updateEmail(user, newEmail)
      }

      if (updateAPI) {
        await updateEmailAPI()
      }
    } catch (error: any) {
      addToast(`Could not update email address, ${error.message}`, {
        appearance: "error",
      })
      setLoading(false)
    }
  }

  const updateEmailAPI = async () => {
    try {
      await axios({
        method: "post",
        url: "/user/update-email",
      })
      addToast(
        `The email address for this account is now ${authHook?.user.email}`,
        {
          appearance: "success",
        }
      )
      setModalVisibility(false)
    } catch (error) {
      await updateEmailFirebase(oldEmail, { updateAPI: false })
      addToast("Could not update email address, please try again", {
        appearance: "error",
      })
    }
    setLoading(false)
  }

  return (
    <>
      <Helmet title={SEOTitleTemplate({ title: "Change your email" })} />
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Please enter a valid email address")
            .required("Please enter an email address"),
        })}
        onSubmit={revealPassword}
      >
        {({ isSubmitting }) => (
          <Form variant="card-small">
            <FormLegend>Current email: {authHook?.user.email}</FormLegend>
            <Input label="New email" name="email" />
            <Button
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Change email
            </Button>
          </Form>
        )}
      </Formik>
      <ModalSmall
        isVisible={modalVisible}
        dismissFunc={() => setModalVisibility(false)}
        title="Please enter your password before continuing"
        description={`Are you sure you want to change the email for this account to
        ${newEmail}?`}
      >
        <Formik
          initialValues={{
            password: "",
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string().required("Please enter your password"),
          })}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <InputPassword name="password" />
              <Button
                type="submit"
                disabled={isSubmitting || loading}
                loading={isSubmitting || loading}
                formCard={true}
              >
                Confirm email change
              </Button>
              <Button
                type="button"
                variant="secondary"
                formCard={true}
                onClick={() => setModalVisibility(false)}
              >
                Cancel
              </Button>
            </Form>
          )}
        </Formik>
      </ModalSmall>
    </>
  )
}
