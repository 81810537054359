import React from "react"
import PropTypes from "prop-types"
import { Formik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { useToasts } from "react-toast-notifications"
import { Link } from "@reach/router"

import {
  Button,
  InputSelect,
  Form,
  FormLegend,
  InputButtonList,
  Modal,
  ModalBody,
  ModalFooter,
  Dialog,
  FormSection,
} from "../../../shared"
import {
  handleAPIError,
  updateLatestStatusReportingGroup,
  checkFeaturePermission,
} from "../../../../utilities"
import { usePatients, useAuth } from "../../../../hooks"

export const Survey = ({
  isVisible,
  dismissFunc,
  setSurveys,
  surveyDefinition,
}) => {
  const { setFitForWorkReport, user, planTier } = useAuth()
  const { formattedPatients, rawPatients, error, loading } = usePatients()
  const { addToast } = useToasts()
  const [submitted, setSubmitted] = React.useState(false)
  const [responseId, setResponseId] = React.useState("")
  const [survey, setSurvey] = React.useState({
    initialValues: {},
    validationSchema: {},
    questions: [],
    loaded: false,
  })

  const closeModal = () => {
    dismissFunc()
    setSubmitted(false)
  }

  const formatAnswers = (answers) => {
    const formattedAnswers = []
    delete answers.patient

    Object.keys(answers).forEach((answer) => {
      formattedAnswers.push({
        dataType: "boolean", // needs updating
        type: "checkbox", // needs updating
        id: answer,
        value: answers[answer],
      })
    })

    return formattedAnswers
  }

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      if (
        !checkFeaturePermission({
          planTier: planTier,
          feature: "multiple-patients",
        })
      ) {
        values.patient = rawPatients.find(
          (patient) => patient.accountHolder
        )?.id
      }

      const response = await axios({
        method: "post",
        url: "/surveys/record-response",
        data: {
          surveyId: surveyDefinition.id,
          patientId: values.patient, // do I need to remove?
          answers: formatAnswers(values),
        },
      })

      const newResponse = response.data
      updateLatestStatusReportingGroup({
        userId: user.uid,
        addToast: addToast,
        setFitForWorkReport: setFitForWorkReport,
      })
      setSurveys((oldSurveys) => [newResponse, ...oldSurveys])
      setResponseId(newResponse.id)
      setSubmitted(true)
    } catch (error) {
      addToast(handleAPIError(error, "save survey response"), {
        appearance: "error",
      })
      setSubmitting(false)
    }
  }

  React.useEffect(() => {
    const buildSurvey = () => {
      surveyDefinition.questions.forEach((question) => {
        if (question.dataType === "boolean") {
          setSurvey((survey) => ({
            initialValues: {
              ...survey.initialValues,
              [question.id]: false,
            },
            validationSchema: {
              ...survey.validationSchema,
              [question.id]: Yup.boolean().oneOf([false, true]),
            },
            questions: [
              ...survey.questions,
              {
                name: question.id,
                label: question.label,
                dataType: "boolean",
                options: [
                  {
                    value: true,
                    label: "Yes",
                  },
                  {
                    value: false,
                    label: "No",
                  },
                ],
              },
            ],
            loaded: true,
          }))
        }
      })
    }
    if (!survey.loaded) {
      buildSurvey()
    }
  }, [surveyDefinition]) // eslint-disable-line

  React.useEffect(() => {
    if (error) {
      addToast(handleAPIError(error, "load patient options"), {
        appearance: "error",
      })
    }
  }, [error]) // eslint-disable-line

  return (
    <Modal
      isVisible={isVisible}
      dismissFunc={closeModal}
      title={surveyDefinition.name}
      header={!submitted}
      size={!submitted ? "large" : "medium"}
    >
      {!submitted && survey.loaded ? (
        <Formik
          initialValues={{
            patient: "",
            ...survey.initialValues,
          }}
          validationSchema={Yup.object().shape({
            patient: checkFeaturePermission({
              planTier: planTier,
              feature: "multiple-patients",
            })
              ? Yup.string().required(
                  "Please choose the patient you are recording a response for"
                )
              : Yup.string(),

            ...survey.validationSchema,
          })}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <ModalBody>
                <FormLegend>
                  <p>
                    Please answer questions honestly, failing to do so could
                    contribute to the spread of COVID-19
                  </p>
                </FormLegend>
                {checkFeaturePermission({
                  planTier: planTier,
                  feature: "multiple-patients",
                }) && (
                  <FormSection>
                    <InputSelect
                      loading={loading}
                      label="Patient"
                      name="patient"
                      options={formattedPatients}
                    >
                      <Link className="label-link" to="/account/patients">
                        New patient
                      </Link>
                    </InputSelect>{" "}
                  </FormSection>
                )}

                {survey.questions.map((question) => {
                  if (question.dataType === "boolean") {
                    return (
                      <InputButtonList
                        key={question.name}
                        name={question.name}
                        label={question.label}
                        options={question.options}
                      />
                    )
                  } else {
                    return ``
                  }
                })}
              </ModalBody>
              <ModalFooter>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  Save response
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      ) : submitted ? (
        <Dialog
          title="Survey response saved"
          footer={
            <>
              <Button to={`/surveys/${responseId}`}>View response</Button>
              <Button variant="secondary" onClick={() => closeModal()}>
                Back to surveys
              </Button>
            </>
          }
        >
          <FormLegend>
            <p>You can now view your response</p>
          </FormLegend>
        </Dialog>
      ) : (
        "Loader"
      )}
    </Modal>
  )
}

Survey.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  dismissFunc: PropTypes.func.isRequired,
  setSurveys: PropTypes.func.isRequired,
  surveyDefinition: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        dataType: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }).isRequired
    ),
  }),
}
