import React from "react"

import { Dialog, Button, FormLegend } from "../../../../shared"

export const NewVaccinationModalConfirmation = ({
  vaccinationId,
  closeModal,
}) => {
  return (
    <Dialog
      title="Result saved"
      footer={
        <>
          <Button to={`/immunizations/${vaccinationId}`}>
            View immunization
          </Button>
          <Button variant="secondary" onClick={() => closeModal()}>
            Back to immunizations
          </Button>
        </>
      }
    >
      <FormLegend>
        You can now view your vaccination and show your phone to prove your
        current immunization state
      </FormLegend>
    </Dialog>
  )
}
