import React from "react"
import PropTypes from "prop-types"
import axios from "axios"

import {
  Button,
  ModalBody,
  FormLegend,
  ModalFooter,
  ModalImageContainer,
} from "../../../../../../shared"
import { handleAPIError } from "../../../../../../../utilities"
import { useSelfTest } from "../.."

export const Step4 = ({ addToast, images, inteliSwabImages }) => {
  const { testData, setTestData } = useSelfTest()
  const [loading, setLoading] = React.useState(false)

  const logStartTime = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "post",
        url: "/tests/update",
        data: {
          testId: testData.data.id,
          updates: {
            startedTime: new Date().toISOString(),
          },
        },
      })
      const updatedTest = response.data

      setTestData((testData) => ({
        step: testData.step + 1,
        verifiedResult: false,
        data: { ...updatedTest },
      }))
      // setLoading not used to preent 'memory leak'
    } catch (error) {
      addToast(handleAPIError(error, "start timer"), {
        appearance: "error",
      })
      setLoading(false)
    }
  }

  return (
    <>
      <ModalBody>
        {testData?.data?.manufacturer === "Orasure Inteliswab" ? (
          <>
            <FormLegend>
              <p>
                Insert your InteliSwab into the extraction tube. Swirl 10 times,
                leave the swab in the tube when you're done.
              </p>
            </FormLegend>
            <ModalImageContainer
              images={inteliSwabImages}
              svg={true}
              wide={true}
            />
          </>
        ) : (
          <>
            <FormLegend>
              <p>
                Prepare your test result according to manufacturer instructions.
              </p>
              <p>When you're ready to wait for your result, start the timer</p>
            </FormLegend>
            <ModalImageContainer images={images} />
          </>
        )}
      </ModalBody>
      <ModalFooter
        backFunc={() =>
          setTestData((testData) => ({
            step: testData.step - 1,
            verifiedResult: false,
            data: { ...testData.data },
          }))
        }
      >
        <Button
          loading={loading}
          disabled={loading}
          onClick={() => logStartTime()}
        >
          Start timer
        </Button>
      </ModalFooter>
    </>
  )
}

Step4.propTypes = {
  addToast: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  inteliSwabImages: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}
