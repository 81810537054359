import React from "react"
import PropTypes from "prop-types"

import { ButtonWithIcon, Dropdown } from "../../../shared"
import { useOnClickOutside } from "../../../../hooks"

export const SurveysDropDown = ({ surveys, populateNewReponseModal }) => {
  const [surveysDropdownVisible, setSurveysDropdownVisibility] =
    React.useState(false)
  const [surveyOptions, setSurveyOptions] = React.useState([])

  const dropdownRef = React.useRef()
  useOnClickOutside(dropdownRef, () => setSurveysDropdownVisibility(false))

  React.useEffect(() => {
    let formattedSurveyOptions = []

    if (surveys && surveys.length > 0) {
      surveys.forEach((survey) => {
        formattedSurveyOptions.push({
          name: survey.name,
          guidance: survey.description,
          icon: "record",
          onClick: () => populateNewReponseModal(survey),
        })
      })
    }

    setSurveyOptions(formattedSurveyOptions)

    return () => {
      setSurveyOptions([])
    }
  }, [surveys]) // eslint-disable-line

  return (
    <section className="list-page-header-dropdowns">
      <div className="list-page-header-dropdown" ref={dropdownRef}>
        <ButtonWithIcon
          icon="plus"
          iconPosition="left"
          onClick={() => setSurveysDropdownVisibility((current) => !current)}
        >
          New response
        </ButtonWithIcon>
        <Dropdown
          isOpen={surveysDropdownVisible}
          dismissFunc={() => setSurveysDropdownVisibility(false)}
          options={surveyOptions}
        />
      </div>
    </section>
  )
}

SurveysDropDown.propTypes = {
  surveys: PropTypes.array.isRequired,
  populateNewReponseModal: PropTypes.func.isRequired,
}
