import React from "react"
import PropTypes from "prop-types"
import { Link, useLocation } from "@reach/router"

import { IconButton, NavAvatar, Button, NavLink } from "../../shared"
import { useOnClickOutside, useAuth } from "../../../hooks"
import { ReactComponent as GTTLogoDark } from "../../../assets/GotTheTest-logo-dark.svg"

import { FitForWorkStatusButton } from "./FitForWorkStatusButton"

interface Props {
  openSidebar: () => void
}

export const Nav = ({ openSidebar }: Props) => {
  const location = useLocation()
  const authHook = useAuth()

  const [fitForWorkStatus, setFitForWorkStatus] = React.useState({
    available: false,
    status: false,
  })

  const [mobileExtraLinksVisible, setMobileExtraLinksVisibility] =
    React.useState(false)

  const mobileExtraLinksRef =
    React.useRef() as React.MutableRefObject<HTMLInputElement>
  useOnClickOutside(mobileExtraLinksRef, () =>
    setMobileExtraLinksVisibility(false)
  )

  React.useEffect(() => {
    setMobileExtraLinksVisibility(false)

    return () => {
      setMobileExtraLinksVisibility(false)
    }
  }, [location.pathname]) // eslint-disable-line

  React.useEffect(() => {
    if (
      authHook?.fitForWorkReport?.status === true ||
      authHook?.fitForWorkReport?.status === false
    ) {
      setFitForWorkStatus({
        available: true,
        status: authHook?.fitForWorkReport?.status,
      })
    } else {
      setFitForWorkStatus({ available: false, status: false })
    }

    return () => {
      setFitForWorkStatus({ available: false, status: false })
    }
  }, [authHook?.fitForWorkReport])

  return (
    <div className="nav">
      <div className="nav-mobile-menu">
        <IconButton icon="menu" onClick={openSidebar} />
      </div>
      <Link className="nav-logo" to="/">
        <GTTLogoDark />
      </Link>
      <div className="nav-item">
        <div className="nav-mobile" ref={mobileExtraLinksRef}>
          <IconButton
            icon="more"
            onClick={() => setMobileExtraLinksVisibility((current) => !current)}
          />
          <ul
            className={`nav-link-group${
              mobileExtraLinksVisible ? " active" : ""
            }`}
          >
            <li className="nav-link-group-section">
              <ul>
                {authHook?.fitForWorkReport && (
                  <li>
                    <FitForWorkStatusButton
                      status={authHook?.fitForWorkReport?.status}
                    />
                  </li>
                )}
                {(authHook?.planTier === 0 || authHook?.planTier === 1) && (
                  <NavLink to="/change-plan">Upgrade</NavLink>
                )}
              </ul>
            </li>
          </ul>
        </div>
        <div className="nav-tablet">
          {fitForWorkStatus.available && (
            <FitForWorkStatusButton status={fitForWorkStatus.status} />
          )}
          {/* Plan Tier may be undefined for users with old accounts (before subscriptions introduced) */}
          {(authHook?.planTier === 0 ||
            authHook?.planTier === 1 ||
            authHook?.planTier === undefined) && (
            <Button to="/change-plan" variant="primary button-nav">
              Upgrade
            </Button>
          )}
        </div>
      </div>
      <NavAvatar />
    </div>
  )
}

Nav.propTypes = {
  openSidebar: PropTypes.func.isRequired,
}
