import React from "react"
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"
import { useToasts } from "react-toast-notifications"
import { Formik } from "formik"
import * as Yup from "yup"
import axios from "axios"

import {
  AddressFinder,
  Button,
  ButtonWithIcon,
  Form,
  FormSection,
  Input,
  Label,
  Tag,
  ButtonList,
} from "../.."
import {
  handleAPIError,
  generatePlanButtonText,
  getPlanNumber,
  checkCoupon,
  premiumPlanId,
} from "../../../../utilities"
import { useAuth, usePlan } from "../../../../hooks"
import { couponStateType } from "../../../../types"

interface BillingFormValues {
  name: string
  phone: string
  address: string
}

interface Props {
  setSubmitted: any
  planCost: {
    original: number
    discounted: number
  }
  coupon: couponStateType
  setCoupon: (arg0: any) => void
  resetCoupon: () => void
  variant: string
}

export const PlanPaymentForm = ({
  setSubmitted,
  planCost,
  coupon,
  setCoupon,
  resetCoupon,
  variant,
}: Props) => {
  const authHook = useAuth()
  const stripe = useStripe()
  const elements = useElements()
  const { addToast } = useToasts()
  const { plan } = usePlan()
  const planTier = authHook?.planTier

  const [disabled, setDisabled] = React.useState(true)
  const [error, setError] = React.useState<string | null>(null)
  const [retrySubscription, setRetrySubscription] = React.useState({
    isRetry: false,
    invoiceId: "",
    customerId: "",
    subscriptionId: "",
  })
  const [processing, setProcessing] = React.useState(false)
  const [searchedAddress, setSearchedAddress] = React.useState()
  const [manualAddressVisible, setManualAddressVisibility] =
    React.useState(false)

  const planNumber = getPlanNumber(plan?.sys?.id)

  const onSubmit = async (
    values: BillingFormValues,
    { setSubmitting }: { setSubmitting: any }
  ) => {
    setProcessing(true)
    try {
      const response = await stripe?.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardElement)!,
      })
      if (response?.paymentMethod) {
        if (retrySubscription.isRetry) {
          retryInvoice({ paymentMethodId: response.paymentMethod.id })
        } else {
          updateSubscription({
            paymentMethodId: response.paymentMethod.id,
            billingDetails: values,
          })
        }
      }
    } catch (error: any) {
      setError(`Could not change plan, ${error.error.message}`)
      setProcessing(false)
      setSubmitting(false)
    }
  }

  const updateSubscription = async ({
    paymentMethodId,
    billingDetails,
  }: {
    paymentMethodId: string
    billingDetails: BillingFormValues
  }) => {
    let data
    if (coupon.id) {
      data = {
        couponId: coupon.id,
        paymentMethodId,
        plan: planNumber,
        billingDetails,
      }
    } else {
      data = {
        paymentMethodId,
        plan: planNumber,
        billingDetails,
      }
    }

    try {
      const response = await axios({
        method: "post",
        url: "/stripe/update-subscription",
        data: data,
      })
      // response.data = Stripe Subscription object https://stripe.com/docs/api/subscriptions/object
      if (response?.data?.status === "active") {
        updateSubscriptionSucceeded()
      } else if (response?.data?.status === "incomplete") {
        handleSubscriptionError({
          paymentMethodId,
          paymentIntent: response?.data?.latest_invoice?.payment_intent,
          invoiceId: response?.data?.latest_invoice?.id,
          customerId: response?.data?.customer,
          subscriptionId: response?.data?.id,
        })
      }
    } catch (error: any) {
      console.log(error, error.response)
      setProcessing(false)
      addToast(handleAPIError(error, "update plan"), {
        appearance: "error",
      })
    }
  }

  const retryInvoice = async ({
    paymentMethodId,
    invoiceId,
    customerId,
    subscriptionId,
  }: {
    invoiceId?: string
    customerId?: string
    paymentMethodId: string
    subscriptionId?: string
  }) => {
    // Using retrySubscription state variables if retryInvoice called directly from onSubmit()
    // Using args if called from handleSubscriptionError() as we have that data already
    try {
      const response = await axios({
        method: "post",
        url: "/stripe/retry-invoice",
        data: {
          paymentMethodId: paymentMethodId,
          customerId: customerId ? customerId : retrySubscription.customerId,
          invoiceId: invoiceId ? invoiceId : retrySubscription.invoiceId,
          subscriptionId: subscriptionId
            ? subscriptionId
            : retrySubscription.subscriptionId,
        },
      })
      if (response?.data?.payment_intent?.status === "succeeded") {
        updateSubscriptionSucceeded()
      } else {
        // response.data = Stripe Invoice object https://stripe.com/docs/api/invoices/object
        handleSubscriptionError({
          paymentIntent: response?.data?.payment_intent,
          invoiceId: response?.data?.id,
          customerId: response?.data?.customer,
          subscriptionId: subscriptionId
            ? subscriptionId
            : retrySubscription.subscriptionId,
          paymentMethodId: paymentMethodId,
        })
      }
    } catch (error) {
      setProcessing(false)
      console.log(error)
      addToast(handleAPIError(error, "update plan"), {
        appearance: "error",
      })
    }
  }

  const handleSubscriptionError = async ({
    paymentIntent,
    invoiceId,
    customerId,
    paymentMethodId,
    subscriptionId,
  }: {
    paymentIntent: {
      id: string
      client_secret: string
      status: string
    }
    invoiceId: string
    customerId: string
    paymentMethodId: string
    subscriptionId: string
  }) => {
    if (
      paymentIntent.status === "requires_action" ||
      paymentIntent.status === "requires_payment_method"
    ) {
      try {
        const result = await stripe?.confirmCardPayment(
          paymentIntent.client_secret,
          {
            payment_method: paymentMethodId,
          }
        )

        if (result?.error) {
          updateSubscriptionFailed({
            invoiceId,
            customerId,
            subscriptionId,
            variant: "authentication",
          })
        } else {
          if (result?.paymentIntent.status === "succeeded") {
            retryInvoice({
              paymentMethodId,
              invoiceId,
              customerId,
              subscriptionId,
            })
          } else {
            updateSubscriptionFailed({
              invoiceId,
              customerId,
              subscriptionId,
            })
          }
        }
      } catch (error) {
        updateSubscriptionFailed({ invoiceId, customerId, subscriptionId })
      }
    } else {
      updateSubscriptionFailed({ invoiceId, customerId, subscriptionId })
    }
  }

  const updateSubscriptionFailed = ({
    invoiceId,
    customerId,
    variant,
    subscriptionId,
  }: {
    invoiceId: string
    customerId: string
    variant?: string
    subscriptionId: string
  }) => {
    setRetrySubscription({
      isRetry: true,
      invoiceId: invoiceId,
      customerId: customerId,
      subscriptionId: subscriptionId,
    })
    if (variant === "authentication") {
      setError("Authentication failed, please retry or use another card")
    } else {
      setError("Payment card failed, please use another card")
    }
    setProcessing(false)
  }

  const updateSubscriptionSucceeded = () => {
    authHook?.setPlanTier(planNumber)
    setProcessing(true)
    window.scrollTo(0, 0)
    setSubmitted(true)
  }

  const handleChange = async (event: any) => {
    setDisabled(event.empty)
    setError(event.error ? event.error.message : "")
  }

  return (
    <Formik
      initialValues={{
        name: "",
        phone: "+1 ",
        address: "",
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(
          "Please enter the full name of the account holder"
        ),
        phone: Yup.string()
          .phone(
            "US",
            true,
            "Please enter a US phone number including the country code '+1'"
          )
          .required("Please the phone number of the account holder"),
        address: Yup.string().required(
          "Please enter the address of the account holder"
        ),
      })}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, setFieldValue, errors, touched }) => (
        <Form variant="billing-details">
          <h1
            className={`heading-large plan-payment-form-title${
              variant === "sign-up" ? " plan-payment-form-title-sign-up" : ""
            }`}
          >
            Billing information
          </h1>
          <FormSection columns={variant === "sign-up" ? 1 : 2}>
            <Input name="name" label="Name on card" />
            <Input name="phone" label="Mobile" placeholder="+11234567890" />
            <AddressFinder
              searchedAddress={searchedAddress}
              onChange={(address) => {
                setSearchedAddress(address)
                setFieldValue(
                  "address",
                  address && address.label ? address.label : ""
                )
              }}
              error={touched?.address ? errors?.address : ""}
            >
              {!manualAddressVisible && (
                <button
                  onClick={() => setManualAddressVisibility(true)}
                  className="label-link"
                >
                  Enter manually
                </button>
              )}
            </AddressFinder>
            <div className={manualAddressVisible ? "" : "not-visible"}>
              <Input
                name="address"
                label="Enter address"
                placeholder="Your full address"
              />
            </div>
          </FormSection>
          <FormSection
            columns={variant === "sign-up" || coupon.succeeded ? 1 : 2}
          >
            <div className="plan-payment-form-coupon">
              {coupon.visible ? (
                coupon.succeeded ? (
                  <div className="plan-payment-form-coupon-succeeded">
                    <Button variant="secondary" onClick={resetCoupon}>
                      Reset coupon code
                    </Button>
                    <Tag icon type="success" variant="background">
                      Congratulations you’ve qualified for{" "}
                      {coupon.amount_off
                        ? coupon.amount_off
                        : coupon.percent_off
                        ? `${coupon.percent_off}%`
                        : "?"}{" "}
                      off ($
                      {planCost.discounted}
                      /mo)
                    </Tag>
                  </div>
                ) : (
                  <div>
                    <div className={coupon.error ? " form-error" : ""}>
                      <Label field="coupon">Coupon code</Label>
                      <input
                        className={`input${coupon.error ? " input-error" : ""}`}
                        type="text"
                        name="coupon"
                        id="coupon"
                        value={coupon.value}
                        onChange={(e) =>
                          setCoupon((coupon: couponStateType) => ({
                            ...coupon,
                            error: "",
                            value: e.target.value,
                          }))
                        }
                      />
                      <span className="input-error-text">{coupon.error}</span>
                    </div>
                    <div />
                    <ButtonList>
                      <Button
                        loading={coupon.loading}
                        type="button"
                        onClick={() =>
                          checkCoupon({ coupon, addToast, setCoupon })
                        }
                      >
                        Apply
                      </Button>
                      <Button
                        type="button"
                        variant="secondary"
                        onClick={() =>
                          setCoupon((coupon: couponStateType) => ({
                            ...coupon,
                            visible: false,
                          }))
                        }
                      >
                        Cancel
                      </Button>
                    </ButtonList>
                  </div>
                )
              ) : (
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() =>
                    setCoupon((coupon: couponStateType) => ({
                      ...coupon,
                      visible: true,
                    }))
                  }
                >
                  Enter coupon code
                </Button>
              )}
            </div>
          </FormSection>
          <FormSection columns={variant === "sign-up" ? 1 : 2}>
            <div
              className={
                variant === "sign-up" ? "plan-payment-form-card-sign-up" : ""
              }
            >
              <Label field="card-element">Your card details</Label>
              <CardElement
                id="card-element"
                options={{
                  hidePostalCode: true,
                  style: {
                    base: {
                      color: "#222",
                      fontFamily: "Arial, sans-serif",
                      fontSmoothing: "antialiased",
                      fontSize: "16px",
                      "::placeholder": {
                        color: "#999",
                      },
                    },
                    invalid: {
                      color: "#d62b62",
                      iconColor: "#fa755a",
                    },
                  },
                }}
                onChange={handleChange}
              />
              <span className="input-error-text">{error}</span>
              {variant === "sign-up" ? (
                <Button
                  formCard
                  type="submit"
                  disabled={isSubmitting || disabled}
                  loading={isSubmitting || processing}
                >
                  Sign up with{" "}
                  {plan?.sys?.id === premiumPlanId ? "Premium" : "Family"}
                </Button>
              ) : (
                <ButtonWithIcon
                  iconPosition="left"
                  icon="lock"
                  type="submit"
                  disabled={isSubmitting || disabled}
                  loading={isSubmitting || processing}
                >
                  {generatePlanButtonText(
                    planTier ? planTier : 0,
                    plan?.sys?.id,
                    plan?.fields?.name
                  )}
                </ButtonWithIcon>
              )}
            </div>
            <div />
            {variant === "change-plan" && (
              <div className="form-section-full-width">
                <p className="text-small plan-payment-form-disclaimer">
                  Today you’ll be charged $
                  {planCost.original !== 0
                    ? planCost.discounted !== 0
                      ? planCost.discounted.toFixed(2)
                      : planCost.original.toFixed(2)
                    : ""}
                  . You may cancel or change your subscription at any time by
                  visiting &#39;Manage Subscription&#39; in your account
                  Settings.
                </p>
              </div>
            )}
          </FormSection>
        </Form>
      )}
    </Formik>
  )
}
