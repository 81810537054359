import React from "react"
import PropTypes from "prop-types"

export const Dialog = ({ title, description, children, footer, icon }) => (
  <div className="dialog">
    {icon && <span className={`icon-${icon} dialog-icon`} />}
    {title && (
      <h2
        className={`heading-medium dialog-heading${
          description ? " dialog-heading-with-description" : ""
        }`}
      >
        {title}
      </h2>
    )}
    {description && <p className="dialog-description">{description}</p>}
    {children && <div>{children}</div>}
    {footer && <footer className="dialog-footer">{footer}</footer>}
  </div>
)

Dialog.defaultProps = {
  description: "",
  footer: "",
  icon: "tick-circle-fill",
  title: "",
  children: null,
}

Dialog.propTypes = {
  children: PropTypes.node,
  description: PropTypes.string,
  footer: PropTypes.node,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  title: PropTypes.string,
}
