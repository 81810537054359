import React from "react"
import axios from "axios"
import { Formik } from "formik"
import * as Yup from "yup"

import {
  Button,
  Form,
  FormLegend,
  FormSection,
  Input,
  ModalBody,
  ModalFooter,
} from "../../../../../../shared"
import { handleAPIError } from "../../../../../../../utilities"
import { usePCRTest } from "../.."

export const Step2 = () => {
  const { testData, setTestData, addToast } = usePCRTest()

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await axios({
        method: "post",
        url: "/tests/update",
        data: {
          testId: testData.data.id,
          updates: values,
        },
      })
      const updatedTest = response.data

      setTestData((testData) => ({
        ...testData,
        step: testData.step + 1,
        data: { ...updatedTest },
      }))
    } catch (error) {
      addToast(handleAPIError(error, "save result"), {
        appearance: "error",
      })
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={{
        cliaLabName: "",
        cliaLabNumber: "",
        orderingPhysician: "",
        npi: "",
      }}
      validationSchema={Yup.object().shape({
        cliaLabName: Yup.string().required(
          "Please enter the name of the CLIA Lab that processed your result"
        ),
        cliaLabNumber: Yup.string().required(
          "Please enter the number of the CLIA Lab that processed your result"
        ),
        orderingPhysician: Yup.string().required(
          "Please enter the name of the order physician that processed your result"
        ),
        npi: Yup.string().required(
          "Please enter the NPI for the order physician that processed your result"
        ),
      })}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <ModalBody>
            <FormLegend>
              <p>
                Which Lab processed your PCR test and who was the ordering
                physician responsible for the result?
              </p>
            </FormLegend>
            <FormSection>
              <Input label="Lab Name" name="cliaLabName" />
              <Input label="Lab Registration Number" name="cliaLabNumber" />
              <Input label="Ordering Physician Name" name="orderingPhysician" />
              <Input label="Ordering Physician NPI" name="npi" />
            </FormSection>
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Save processor details
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}
