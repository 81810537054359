import React from "react"
import PropTypes from "prop-types"

import { Tag } from "../../../../shared"
import { ReportCondition } from "./ReportCondition"
import { statusReportingGroupReportTask } from "../../../../../types"

interface Props {
  taskResults: Array<statusReportingGroupReportTask>
  status: boolean
  variant: "live-report" | "past-report"
}

export const Report = ({ taskResults, status, variant }: Props) => (
  <div className={`report report-${variant}`}>
    <header className="report-header">
      {/*   
      // For static reports we used to create before moving to live reporting with the employer portal
      <div className="report-header-period">
        <h3 className="heading-extra-small report-header-period-title">
          {`${moment(start).format("MM/DD/YYYY")} - 
          ${moment(end).format("MM/DD/YYYY")}`}
        </h3>
        {variant === "live-report" && (
          <div className="report-header-period-status">
            <Tag type="info">In progress</Tag>
          </div>
        )}
      </div> */}
      <Tag type={status ? "success" : "error"} icon={true}>
        {status ? "Fit for work" : "Not fit for work"}
      </Tag>
    </header>
    {taskResults.length > 0 && (
      <section className="report-conditions">
        {taskResults.map((taskResult: statusReportingGroupReportTask) => (
          <ReportCondition
            key={taskResult.task.name}
            name={taskResult.task.description}
            status={taskResult.pass}
          />
        ))}
      </section>
    )}
  </div>
)

Report.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
}
