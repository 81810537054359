import React from "react"
import PropTypes from "prop-types"

import { useField } from "formik"

export const Checkbox = ({ name, label, children }) => {
  const [field, meta] = useField(name)
  const { value } = field
  return (
    <div
      className={`checkbox-wrapper ${
        meta.touched && meta.error ? " form-error" : ""
      }`}
    >
      <div className="checkbox">
        <input
          type="checkbox"
          name={name}
          id={name}
          checked={value}
          {...field}
        />
        <span className="checkbox-checkmark"></span>
        <label className="checkbox-label" htmlFor={name}>
          {label}
        </label>
      </div>
      {children ? <div className="checkbox-extras">{children}</div> : ""}
      <span className="input-error-text">{meta.touched ? meta.error : ""}</span>
    </div>
  )
}

Checkbox.defaultProps = {
  children: null,
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
}
