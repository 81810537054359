import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import { getStorage, ref, uploadBytes } from "firebase/storage"

import {
  handleAPIError,
  updateLatestStatusReportingGroup,
} from "../../../../../utilities"
import {
  ImageUploader,
  ModalBody,
  FormLegend,
  FormSection,
  ModalFooter,
  Button,
  Label,
} from "../../../../shared"
import { useAuth } from "../../../../../hooks"

import Image from "../../../../../assets/vaccine-card-example.jpg"

export const Step2 = ({
  vaccinationData,
  setVaccinationData,
  setVaccinations,
  addToast,
}) => {
  const storage = getStorage()
  const auth = useAuth()
  const [error, setError] = React.useState("")
  const [photos, setPhotos] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [pdfs, setPdfFile] = React.useState([])

  const updatePdfs = (event) => {
    setPdfFile([event.target.files[0]])
  }

  const removePdf = () => {
    setPdfFile([])
  }

  const onSubmit = async () => {
    if (photos.length === 0 && pdfs.length === 0) {
      setError(
        "Please upload a photo of your vaccination record card or PDF proving your vaccination"
      )
    } else {
      setLoading(true)

      try {
        const date = new Date().toISOString()
        const filePath = `users/${auth.user.uid}/images/${date}-${
          photos.length > 0 ? photos[0].file.name : pdfs[0].name
        }`
        const storageRef = ref(storage, filePath)
        await uploadBytes(
          storageRef,
          photos.length > 0 ? photos[0].file : pdfs[0]
        )

        const response = await axios({
          method: "post",
          url: "/immunization-records/update",
          data: {
            immunizationId: vaccinationData.data.id,
            updates: { images: [filePath] },
            _expand: ["patient"],
          },
        })
        const completedVaccination = response.data
        updateLatestStatusReportingGroup({
          userId: auth.user.uid,
          addToast: addToast,
          setFitForWorkReport: auth.setFitForWorkReport,
        })
        setVaccinations((vaccinations) => [
          completedVaccination,
          ...vaccinations,
        ])
        setVaccinationData((data) => ({
          step: data.step + 1,
          data: { ...completedVaccination },
        }))
      } catch (error) {
        addToast(handleAPIError(error, "save vaccination card photo"), {
          appearance: "error",
        })
        setLoading(false)
      }
    }
  }

  return (
    <>
      <ModalBody>
        <FormLegend>
          <p>
            Please upload a photo of your vaccination record card with your
            latest dose shown
          </p>
        </FormLegend>
        <FormSection>
          <div>
            <ImageUploader
              photos={photos}
              updatePhotos={setPhotos}
              variant={"vaccinations"}
              updatePdfs={updatePdfs}
              pdfs={pdfs}
              removePdf={removePdf}
              pdf={true}
            />
            <span className="input-error-text image-uploader-error">
              {error}
            </span>
          </div>
          <div>
            <Label>Example</Label>
            <img
              className="image-uploader-example"
              src={Image}
              alt="Vaccination Record Card"
            />
          </div>
        </FormSection>
      </ModalBody>
      <ModalFooter>
        <Button disabled={loading} loading={loading} onClick={() => onSubmit()}>
          Save immunization
        </Button>
      </ModalFooter>
    </>
  )
}

Step2.propTypes = {
  setVaccinations: PropTypes.func.isRequired,
}
