import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import { useToasts } from "react-toast-notifications"
import { Helmet } from "react-helmet"
import axios from "axios"
import { navigate, useLocation } from "@reach/router"
import { useQueryParam, StringParam } from "use-query-params"

import { useAuth } from "../../hooks"
import { Form, Input, InputPassword, Button } from "../shared"
import { SEOTitleTemplate } from "../../constants"

import { ReactComponent as GTTLogoDark } from "../../assets/GotTheTest-logo-dark.svg"

interface LoginFormValues {
  email: string
  password: string
}

export const Login = () => {
  const [loading, setLoading] = React.useState(false)
  const authHook = useAuth()
  const location = useLocation()
  const [code] = useQueryParam("code", StringParam)

  const { addToast } = useToasts()

  const onSubmit = async (
    values: LoginFormValues,
    { setSubmitting }: { setSubmitting: (arg0: boolean) => void }
  ) => {
    setLoading(true)
    try {
      // setSubmitting(false) To prevent memory leak
      await authHook?.signIn({
        email: values.email,
        password: values.password,
        errorFunc: () => {
          setLoading(false)
          setSubmitting(false)
        },
      })
    } catch {
      addToast("Something went wrong, please try again", {
        appearance: "error",
      })
      setLoading(false)
      setSubmitting(false)
    }
  }

  React.useEffect(() => {
    const checkIfUserSignedUp = async () => {
      try {
        const response = await axios({
          method: "post",
          url: `/user/${authHook?.user.uid}`,
        })
        const user = response.data
        await authHook?.setPlanTier(user?.plan)
        if (!user.emailVerified) {
          authHook?.setCreatingAccount(true)
          if (code) {
            navigate(`/create-account/confirm-email?code=${code}`)
          } else {
            navigate("/create-account/confirm-email")
          }
        } else if (user.emailVerified && user.patients.length === 0) {
          authHook?.setCreatingAccount(true)
          navigate("/create-account/create-patient")
        } else {
          // emailVerified = true && patients.length !== 0
          if (!authHook?.updatingDummyPatient) {
            if (
              location.pathname === "/create-account/create-patient" ||
              location.pathname === "/create-account/confirm-email" ||
              location.pathname === "/create-account/choose-plan"
            ) {
              navigate("/")
            }
            authHook?.setCreatingAccount(false)
            authHook?.setSignedUp(true)
          } else {
            authHook?.setCreatingAccount(true)
            authHook?.setSignedUp(false)
          }
        }
        if (
          user.liveStatusReportingGroupReports &&
          user.liveStatusReportingGroupReports.length > 0
        ) {
          authHook?.setFitForWorkReport(user.liveStatusReportingGroupReports[0])
        }
      } catch (error: any) {
        console.log(error, error.response)
        setLoading(false)
      }
    }
    if (authHook?.user) {
      checkIfUserSignedUp()
    }
    // auth.user doesn't change upon login, this may be an issue for directing based on url
  }, [authHook?.user]) // eslint-disable-line

  return (
    <>
      <Helmet title={SEOTitleTemplate({ title: "Login" })} />
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Please enter a valid email address")
            .required("Please enter your email"),
          password: Yup.string().required("Please enter your password"),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form variant="card">
            <GTTLogoDark className="login-form-logo" />
            <Input label="Email" name="email" />
            <InputPassword label="Password" name="password" />
            <Button
              type="submit"
              disabled={isSubmitting || loading}
              loading={isSubmitting || loading}
              formCard={true}
            >
              Log in
            </Button>
            <Button variant="secondary" to="/sign-up" formCard={true}>
              Sign up
            </Button>
            <Button variant="tertiary" to="/forgotten-password" formCard={true}>
              Forgotten your password?
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}
