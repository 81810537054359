import React from "react"
import { Link } from "@reach/router"

import { Button, NavAvatar } from "../../shared"
import { ReactComponent as GTTLogoDark } from "../../../assets/GotTheTest-logo-dark.svg"

export const SubscriptionNav = () => (
  <div className="nav nav-subscription">
    <Link className="nav-logo" to="/">
      <GTTLogoDark />
    </Link>
    <div className="nav-subscription-back-button">
      <Button to="/" variant="secondary" small={true}>
        Back to diagnostics
      </Button>
    </div>
    <NavAvatar />
  </div>
)
