import React from "react"
import PropTypes from "prop-types"

import {
  ModalBody,
  ResultTypeCard,
  Button,
  FormLegend,
} from "../../../../shared"

import { ReactComponent as Attestation } from "../../../../../assets/immunizations/attestation.svg"
import { ReactComponent as VaccineCard } from "../../../../../assets/immunizations/vaccine-card.svg"

export const Introduction = ({ setVaccinationData }) => (
  <ModalBody>
    <FormLegend>
      <p>What type of immunization would you like to upload?</p>
    </FormLegend>
    <section className="result-type-cards">
      <ResultTypeCard
        illustration={<Attestation />}
        title="Attestation"
        paragraph="You don’t have a vaccine card or can’t upload a photo/PDF."
      >
        <Button
          onClick={() =>
            setVaccinationData((current) => ({
              ...current,
              step: 2,
              attestation: true,
            }))
          }
        >
          Get started
        </Button>
      </ResultTypeCard>
      <ResultTypeCard
        illustration={<VaccineCard />}
        title="Vaccination card"
        paragraph="You can take a photo or upload a PDF of your vaccine card."
      >
        <Button
          onClick={() =>
            setVaccinationData((current) => ({
              ...current,
              step: 2,
              attestation: false,
            }))
          }
        >
          Get started
        </Button>
      </ResultTypeCard>
    </section>
  </ModalBody>
)

Introduction.propTypes = {
  setVaccinationData: PropTypes.func.isRequired,
}
