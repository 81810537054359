import React from "react"
import { Helmet } from "react-helmet"
import axios from "axios"

import {
  Button,
  ButtonWithIcon,
  Dropdown,
  Query,
  ResultFiltersWithType,
} from "../../shared"
import { DiagnosticsTable } from "./DiagnosticsTable"
import { DiagnosticsCards } from "./DiagnosticsCards"
import { NewAntigenTestModal, NewPCRTestModal } from "./NewSelfTest"
import { UploadAntigenResultModal, UploadPCRResultModal } from "./UploadResult"
import { testTypes, SEOTitleTemplate } from "../../../constants"
import { useOnClickOutside } from "../../../hooks"
import { filterStateTypeWithType } from "../../../types"

export const DiagnosticsList = () => {
  const [error, setError] = React.useState(null)
  const [data, setData] = React.useState<any | null>(null)
  const [loading, setLoading] = React.useState(true)
  // Filters
  const [touched, setTouched] = React.useState(false)
  const [mobileFiltersVisible, setMobileFiltersVisibility] =
    React.useState(false)
  const [filters, setFilters] = React.useState<filterStateTypeWithType>({
    patient: {
      selected: {
        value: undefined,
        label: undefined,
      },
      all: [],
    },
    type: {
      selected: {
        value: undefined,
        label: undefined,
      },
      all: testTypes,
    },
    rangeStart: null,
    rangeEnd: null,
  })
  const [dateRangeTouched, setDateRangeTouched] = React.useState({
    before: false,
    after: false,
  })
  // Dropdowns
  const [selfTestOptionsVisibility, setSelfTestOptionsVisibility] =
    React.useState(false)
  const [uploadResultOptionsVisibility, setUploadResultOptionsVisibility] =
    React.useState(false)
  // Modals
  const [antigenTestModalVisibility, setAntigenTestModalVisibility] =
    React.useState(false)
  const [antigenResultModalVisibility, setAntigenResultModalVisibility] =
    React.useState(false)
  const [pcrTestModalVisibility, setPcrTestModalVisibility] =
    React.useState(false)
  const [pcrResultModalVisibility, setPcrResultModalVisibility] =
    React.useState(false)
  // Dropdown refs
  const dropdownRef = React.useRef<HTMLDivElement | null>(null)
  useOnClickOutside(dropdownRef, () => setSelfTestOptionsVisibility(false))

  const uploadResultDropdownRef = React.useRef<HTMLDivElement | null>(null)
  useOnClickOutside(uploadResultDropdownRef, () =>
    setUploadResultOptionsVisibility(false)
  )

  React.useEffect(() => {
    const query = async () => {
      setLoading(true)
      setMobileFiltersVisibility(false)
      try {
        const response = await axios({
          method: "post",
          url: "/tests/all",
          data: {
            patientId: filters.patient.selected.value,
            type: filters.type.selected.value,
            afterSampleTime: filters.rangeStart
              ? filters.rangeStart.toISOString()
              : "",
            beforeSampleTime: filters.rangeEnd
              ? filters.rangeEnd.toISOString()
              : "",
            onlyTestsWithResults: true,
            _expand: ["testResult", "patient"],
          },
        })
        setData(response.data)
      } catch (error: any) {
        setError(error)
      }
      setLoading(false)
    }
    query()

    return () => {
      setError(null)
      setData(null)
      setLoading(false)
    }
  }, [
    filters.patient.selected,
    filters.type.selected,
    filters.rangeStart,
    filters.rangeEnd,
  ])

  return (
    <>
      <Helmet title={SEOTitleTemplate({ title: "Your diagnostics" })} />
      <header className="list-page-header list-page-header-diagnostics">
        <h1 className="heading-extra-large">Diagnostics</h1>
        <section className="list-page-header-dropdowns">
          <div
            className="list-page-header-dropdown"
            ref={uploadResultDropdownRef}
          >
            <ButtonWithIcon
              variant="secondary"
              icon="upload"
              iconPosition="left"
              onClick={() =>
                setUploadResultOptionsVisibility((current) => !current)
              }
            >
              Upload result
            </ButtonWithIcon>
            <Dropdown
              isOpen={uploadResultOptionsVisibility}
              options={[
                {
                  name: "Rapid Antigen Test",
                  guidance: "Great for fast results",
                  icon: "fast",
                  onClick: () => {
                    setAntigenResultModalVisibility(true)
                    setUploadResultOptionsVisibility(false)
                  },
                },
                {
                  name: "PCR Test",
                  guidance: "Better for result accuracy",
                  icon: "lock-outline",
                  onClick: () => {
                    setPcrResultModalVisibility(true)
                    setUploadResultOptionsVisibility(false)
                  },
                },
              ]}
            />
          </div>
          <div className="list-page-header-dropdown" ref={dropdownRef}>
            <ButtonWithIcon
              icon="plus"
              iconPosition="left"
              onClick={() =>
                setSelfTestOptionsVisibility((current) => !current)
              }
            >
              New self-test
            </ButtonWithIcon>
            <Dropdown
              isOpen={selfTestOptionsVisibility}
              options={[
                {
                  name: "Rapid Antigen Test",
                  guidance: "Great for fast results",
                  icon: "fast",
                  onClick: () => {
                    setAntigenTestModalVisibility(true)
                    setSelfTestOptionsVisibility(false)
                  },
                },
                {
                  name: "PCR Test",
                  guidance: "Better for result accuracy",
                  icon: "lock-outline",
                  onClick: () => {
                    setPcrTestModalVisibility(true)
                    setSelfTestOptionsVisibility(false)
                  },
                },
              ]}
            />
          </div>
        </section>
      </header>
      <ResultFiltersWithType
        mobileFiltersVisible={mobileFiltersVisible}
        setMobileFiltersVisibility={setMobileFiltersVisibility}
        filters={filters}
        setTouched={setTouched}
        setFilters={setFilters}
        setDateRangeTouched={setDateRangeTouched}
        dateRangeTouched={dateRangeTouched}
        variant="diagnostics"
      />
      {loading || error ? (
        <Query
          additionalClasses="list-page-loader"
          loading={loading}
          error={error}
          action="load diagnostics"
        />
      ) : data && data.length > 0 ? (
        <>
          <DiagnosticsCards data={data} />
          <DiagnosticsTable data={data} />
        </>
      ) : touched ? (
        <div className="list-page-empty">
          <p className="list-page-empty-description">
            Sorry, there are no results matching your criteria
          </p>
          <Button
            onClick={() => {
              setFilters((filters: filterStateTypeWithType) => ({
                type: {
                  ...filters.type,
                  selected: { value: undefined, label: undefined },
                },
                patient: {
                  ...filters.patient,
                  selected: { value: undefined, label: undefined },
                },
                rangeStart: null,
                rangeEnd: null,
              }))
              setDateRangeTouched({
                before: false,
                after: false,
              })
            }}
            variant="secondary"
          >
            Clear all filters
          </Button>
        </div>
      ) : (
        <div className="list-page-empty">
          <p className="list-page-empty-description">
            You haven't completed any tests yet
          </p>
          <Button onClick={() => setAntigenTestModalVisibility(true)}>
            Complete your first test
          </Button>
        </div>
      )}
      <NewAntigenTestModal
        modalVisible={antigenTestModalVisibility}
        dismissFunc={() => setAntigenTestModalVisibility(false)}
        setTests={setData}
      />
      <UploadAntigenResultModal
        modalVisible={antigenResultModalVisibility}
        dismissFunc={() => setAntigenResultModalVisibility(false)}
        setTests={setData}
      />
      <NewPCRTestModal
        modalVisible={pcrTestModalVisibility}
        dismissFunc={() => setPcrTestModalVisibility(false)}
        setTests={setData}
      />
      <UploadPCRResultModal
        modalVisible={pcrResultModalVisibility}
        dismissFunc={() => setPcrResultModalVisibility(false)}
        setTests={setData}
      />
    </>
  )
}
