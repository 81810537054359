import React from "react"
import PropTypes from "prop-types"

export const FormLegend = ({ children, variant }) => (
  <div
    className={`form-legend${
      variant === "formElements"
        ? " form-legend-has-elements"
        : variant === "button"
        ? " form-legend-button"
        : ""
    }`}
  >
    {children}
  </div>
)

FormLegend.defaultProps = {
  hasFormElements: false,
  button: false,
}

FormLegend.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(["button", "formElements", "normal"]),
}
