import React from "react"
import PropTypes from "prop-types"
import axios from "axios"

import {
  Button,
  ModalBody,
  FormLegend,
  ModalFooter,
  ModalImageContainer,
} from "../../../../../../shared"
import { handleAPIError } from "../../../../../../../utilities"
import { usePCRTest } from "../.."

export const Step3 = ({ images }) => {
  const { testData, setTestData, addToast } = usePCRTest()
  const [loading, setLoading] = React.useState(false)

  const logSampleTime = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "post",
        url: "/tests/update",
        data: {
          testId: testData.data.id,
          updates: {
            sampledTime: new Date().toISOString(),
          },
        },
      })
      const updatedTest = response.data

      setTestData((testData) => ({
        step: testData.step + 1,
        data: { ...updatedTest },
      }))
      // setLoading not used to prevent 'memory leak'
    } catch (error) {
      addToast(handleAPIError(error, "log sample time"), {
        appearance: "error",
      })
      setLoading(false)
    }
  }
  return (
    <>
      <ModalBody>
        <FormLegend>
          <p>
            Please collect a sample according to your test manufacturer
            instructions
          </p>
        </FormLegend>
        <ModalImageContainer images={images} svg={true} />
      </ModalBody>
      <ModalFooter
        backFunc={() =>
          setTestData((testData) => ({
            step: testData.step - 1,
            data: { ...testData.data },
          }))
        }
      >
        <Button
          loading={loading}
          disabled={loading}
          onClick={() => logSampleTime()}
        >
          Log sample collected time
        </Button>
      </ModalFooter>
    </>
  )
}

Step3.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.object.isRequired,
      alt: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}
