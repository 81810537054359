import { Router } from "@reach/router"

import { DiagnosticsList } from "./DiagnosticsList"
import { ViewDiagnostic } from "./ViewDiagnostic"

export const Diagnostics = () => (
  <Router>
    <DiagnosticsList path="/" />
    <ViewDiagnostic path="diagnostics/:id" />
  </Router>
)
