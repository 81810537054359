import { immunizations } from "../../../constants"

import {
  buttonGroupOptionsType,
  immunizationType,
  vaccineType,
  vaccineManufacturerType,
} from "../../../types"

interface Args {
  diseaseTargeted: string
  vaccine: string
  manufacturer: string
}

export const calculateImmunizationDoses = ({
  diseaseTargeted,
  vaccine,
  manufacturer,
}: Args) => {
  let formattedImmunizationDoseOptions: buttonGroupOptionsType = []

  immunizations.forEach((immunization: immunizationType) => {
    if (immunization.diseaseTargeted === diseaseTargeted) {
      immunization.vaccines.forEach((vaccineOption: vaccineType) => {
        if (vaccineOption.name === vaccine) {
          vaccineOption.manufacturers.forEach(
            (vaccineManufacturer: vaccineManufacturerType) => {
              if (vaccineManufacturer.name === manufacturer) {
                for (let i = 0; i < vaccineManufacturer.doses; i++) {
                  const userCount = i + 1
                  formattedImmunizationDoseOptions.push({
                    label: userCount.toString(),
                    value: userCount.toString(),
                  })
                }

                if (vaccineManufacturer.boosters) {
                  formattedImmunizationDoseOptions.push({
                    label: "Booster",
                    value: "Booster",
                  })
                }
              }
            }
          )
        }
      })
    }
  })

  return formattedImmunizationDoseOptions
}
