import { immunizations } from "../../../constants"

import {
  reactSelectOptionsType,
  immunizationType,
  vaccineType,
  vaccineManufacturerType,
} from "../../../types"

interface Args {
  diseaseTargeted?: "COVID-19" | "Influenza (Flu)"
  vaccine?: "SARS Cov-2 mRNA" | "Influenza (Flu)"
}

export const calculateImmunizationVaccineManufacturers = ({
  diseaseTargeted,
  vaccine,
}: Args) => {
  let formattedVaccineManufacturers: reactSelectOptionsType = []

  if (diseaseTargeted && vaccine) {
    immunizations.forEach((immunization: immunizationType) => {
      if (immunization.diseaseTargeted === diseaseTargeted) {
        immunization.vaccines.forEach((vaccineOption: vaccineType) => {
          if (vaccineOption.name === vaccine) {
            vaccineOption.manufacturers.forEach(
              (manufacturer: vaccineManufacturerType) => {
                formattedVaccineManufacturers.push({
                  value: manufacturer.name,
                  label: manufacturer.name,
                })
              }
            )
          }
        })
      }
    })
  } else {
    immunizations.forEach((immunization: immunizationType) => {
      immunization.vaccines.forEach((vaccineOption: vaccineType) => {
        vaccineOption.manufacturers.forEach(
          (manufacturer: vaccineManufacturerType) => {
            if (manufacturer.name !== "I'm not sure") {
              formattedVaccineManufacturers.push({
                value: manufacturer.name,
                label: manufacturer.name,
              })
            }
          }
        )
      })
    })
  }

  return formattedVaccineManufacturers
}
