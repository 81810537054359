import React from "react"
import { useField } from "formik"

import { Label } from ".."

interface Props {
  name: string
  label: string
  optional?: boolean
  options: Array<{
    label: string
    value: string
  }>
  placeholder?: string
}

export const InputButtonList = ({
  name,
  label,
  optional = false,
  options,
  placeholder,
}: Props) => {
  const [field, meta, helpers] = useField(name)
  const { value } = field

  return (
    <div className={meta.touched && meta.error ? " form-error" : ""}>
      <Label field={name} optional={optional}>
        {label}
      </Label>
      <div
        className={`input-button-list-options${
          meta.touched && meta.error ? " input-button-list-options-error" : ""
        }`}
      >
        {options && options.length > 0 ? (
          options.map((option) => (
            <button
              type="button"
              key={option.value}
              className={`input-button-list-option${
                value === option.value ? " input-button-list-option-active" : ""
              }`}
              onClick={() => helpers.setValue(option.value)}
            >
              {option.label}
            </button>
          ))
        ) : (
          <span className="input-button-list-options-placeholder">
            {placeholder}
          </span>
        )}
      </div>
      <span className="input-error-text">{meta.touched ? meta.error : ""}</span>
    </div>
  )
}
