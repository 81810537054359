import React from "react"
import PropTypes from "prop-types"
import { useTable, useSortBy } from "react-table"
import moment from "moment-timezone"
import { useNavigate } from "@reach/router"

export const SurveyResponsesTable = ({ data }) => {
  const navigate = useNavigate()
  const columns = React.useMemo(
    () => [
      {
        Header: "Response ID",
        accessor: "id", // accessor is the "key" in the data
        Cell: ({ value }) => (
          <span className="list-page-table-body-cell-text">{value}</span>
        ),
      },
      {
        Header: "Survey",
        accessor: "surveyDefinition.name",
        Cell: ({ value }) => (
          <span className="list-page-table-body-cell-text list-page-table-body-cell-bold">
            {value}
          </span>
        ),
      },
      {
        Header: "Patient",
        accessor: "patient.fullName",
        Cell: ({ value }) => (
          <span className="list-page-table-body-cell-text ">{value}</span>
        ),
      },
      {
        Header: "Outcome",
        accessor: "outcome",
        Cell: ({ value }) => (
          <span className="list-page-table-body-cell-text">{value}</span>
        ),
      },
      {
        Header: "Response",
        accessor: "patientSurveyResponses",
        Cell: ({ value }) => (
          <span className="list-page-table-body-cell-text">{value}</span>
        ),
      },
      {
        Header: "Completed",
        accessor: "completed",
        Cell: ({ value }) => (
          <span className="list-page-table-body-cell-final-column">
            <span className="list-page-table-body-cell-text">{`${moment(
              value
            ).format("MM/DD/YYYY hh:mm A")}`}</span>
            <span className="icon-chevron-right list-page-table-body-cell-final-column-icon" />
          </span>
        ),
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy)

  return (
    <table className="list-page-table" {...getTableProps()}>
      <thead className="list-page-table-header">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) => (
              <th
                className={`list-page-table-header-cell${
                  column.isSorted ? " list-page-table-header-cell-sorted" : ""
                }${
                  index === 0 || index === 3
                    ? " list-page-table-header-cell-desktop"
                    : ""
                }`}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                <span className="list-page-table-header-cell-layout">
                  <span className="list-page-table-header-cell-text">
                    {column.render("Header")}
                  </span>
                  <span className="icon-sort list-page-table-header-cell-icon" />
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="list-page-table-body" {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)
          return (
            <tr
              className="list-page-table-body-row"
              {...row.getRowProps()}
              onClick={() => navigate(`/surveys/${row.cells[0].value}`)}
            >
              {row.cells.map((cell, index) => {
                return (
                  <td
                    className={`list-page-table-body-cell${
                      index === 0 || index === 3
                        ? " list-page-table-body-cell-desktop"
                        : ""
                    }`}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

SurveyResponsesTable.propTypes = {
  data: PropTypes.array.isRequired,
}
