import { View, Text } from "@react-pdf/renderer"

import { PDFStyles } from ".."

interface Props {
  width?: "100%" | "50%" | "25%" | "17%" | "16%"
  label: string
  value: string
}

export const TableCell = ({ width = "100%", label, value }: Props) => (
  <View
    style={
      width === "50%"
        ? PDFStyles.tableCell50
        : width === "25%"
        ? PDFStyles.tableCell25
        : width === "17%"
        ? PDFStyles.tableCell17
        : width === "16%"
        ? PDFStyles.tableCell16
        : PDFStyles.tableCell100
    }
  >
    <Text style={PDFStyles.tableCellLabel}>{label}</Text>
    <Text style={PDFStyles.font}>{value !== "" ? value : "-"}</Text>
  </View>
)
