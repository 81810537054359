import React from "react"

import { usePlan } from "../../../hooks"
import { SubscriptionNav } from "../SubscriptionNav"
import { ChooseNewPlan } from "../ChooseNewPlan"
import { BillingInfo } from "../BillingInfo"
import { Loader } from "../../shared"

export const ChangePlanMaster = () => {
  const { loading, planSelected } = usePlan()

  return (
    <div className="change-plan">
      <SubscriptionNav />
      <div className="wrapper-width wrapper-height">
        {loading ? (
          <Loader />
        ) : planSelected ? (
          <BillingInfo />
        ) : (
          <ChooseNewPlan />
        )}
      </div>
    </div>
  )
}
