import React from "react"
import { Page, Text, View, Image, Document } from "@react-pdf/renderer"
import moment from "moment-timezone"

import { ResultType } from "./ResultType"
import { PDFStyles, TableCell, TableHeaderCell } from "../../../shared"

import GotTheTestLogoDark from "../../../../assets/GotTheTest-logo-dark.jpg"

export const ResultPDF = ({ data }: { data: any }) => {
  return (
    <Document
      title={`${data?.type} ${data?.patient?.fullName}`}
      author="GotTheTest"
      subject={`Diagnostic created ${moment(data.expiry).format(
        "MM-DD-yyyy"
      )} ${data?.patient?.fullName}`}
    >
      <Page size="A4" style={PDFStyles.page}>
        <View style={PDFStyles.header}>
          <Image style={PDFStyles.headerLogo} src={GotTheTestLogoDark} />
          <View style={PDFStyles.headerCompanyInfo}>
            <Text>
              GotTheTest Inc. 2139 Placentia Ave, Costa Mesa, CA, 92627, United
              States gotthetest.portmanteau.app
            </Text>
          </View>
          <View>
            <Text style={PDFStyles.headerTestIDBold}>Test ID:</Text>
            <Text style={PDFStyles.headerTestID}>{data?.id}</Text>
          </View>
        </View>
        <View style={PDFStyles.table}>
          <View style={PDFStyles.tableRow}>
            <TableCell
              width="25%"
              label="Patient Name"
              value={data?.patient?.fullName}
            />
            <TableCell
              width="25%"
              label="Patient ID"
              value={data?.patient?.id}
            />
            <TableCell
              width="17%"
              label="Patient Phone"
              value={data?.patient?.phone}
            />
            <TableCell
              width="17%"
              label="DOB"
              value={data?.patient?.dateOfBirth}
            />
            <TableCell
              width="16%"
              label="Gender"
              value={data?.patient?.gender}
            />
          </View>
          <View style={PDFStyles.tableRow}>
            <TableCell label="Address" value={data?.patient?.address} />
          </View>
        </View>
        <View style={PDFStyles.table}>
          <View style={PDFStyles.tableRow}>
            <View style={PDFStyles.tableColumn50}>
              <View style={PDFStyles.tableRow}>
                <TableHeaderCell header="Test" />
              </View>
              <View style={PDFStyles.tableRow}>
                <TableCell label="Test Type" value={data?.type} />
              </View>
              <View style={PDFStyles.tableRow}>
                <TableCell
                  width="50%"
                  label="Test Manufacturer"
                  value={data?.manufacturer}
                />
                <TableCell width="50%" label="Batch" value={data?.batch} />
              </View>
              <View style={PDFStyles.tableRow}>
                <TableCell label="Sample type" value={data?.sampleType} />
              </View>
              {data?.orderingPhysician && data.npi && (
                <View style={PDFStyles.tableRow}>
                  <TableCell
                    label="Ordering Physician"
                    value={`${data?.orderingPhysician} (${data.npi})`}
                  />
                </View>
              )}
              {data?.observingAssistantName && (
                <View style={PDFStyles.tableRow}>
                  <TableCell
                    label="Observing Assistant"
                    value={data?.observingAssistantName}
                  />
                </View>
              )}
              {data?.cliaLabName && data?.cliaLabNumber && (
                <View style={PDFStyles.tableRow}>
                  <TableCell
                    label="CLIA Lab"
                    value={`${data?.cliaLabName} (${data?.cliaLabNumber})`}
                  />
                </View>
              )}
            </View>
            <View style={PDFStyles.tableColumn50}>
              <View style={PDFStyles.tableRow}>
                <TableHeaderCell header="Result" />
              </View>
              <View style={PDFStyles.tableRow}>
                <TableCell
                  label="Collected"
                  value={`${moment(data?.sampledTime).format(
                    "MM/DD/YYYY hh:mm A"
                  )} (${moment.tz(moment.tz.guess()).zoneAbbr()})`}
                />
              </View>
              <View style={PDFStyles.tableRow}>
                <View style={PDFStyles.tableResult2Cell}>
                  <Text style={PDFStyles.tableResultText}>
                    {data?.testResult?.reading?.data}
                  </Text>
                  <Text style={PDFStyles.fontCentered}>
                    {data?.type === "Rapid Antigen COVID-19 Test" &&
                    data?.selfTest
                      ? "(Not medically verified)"
                      : "(Verified by physician)"}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        {data?.type === "Rapid Antigen COVID-19 Test" ? (
          <View style={PDFStyles.resultTypes}>
            <ResultType
              type="Negative"
              description="A Negative test result is interpreted as nucleocapsid protein antigen was not detected in the specimen. The individual is presumed negative for COVID-19."
            />
            <ResultType
              type="Positive"
              description="A Positive test result is interpreted as nucleocapsid protein antigen was detected in the specimen. The individual is positive for COVID-19. Additional confirmatory testing with a molecular test for positive results may also be necessary, if there is a low likelihood of COVID-19, such as in individuals without known exposures to COVID-19 or residing in communities with low prevalence of infection."
            />
            <ResultType
              type="Invalid"
              description="An Invalid test result means that there was a problem running the test. An Invalid result cannot be interpreted. An invalid test result needs to be repeated with a fresh sample and a new test device."
            />
          </View>
        ) : data?.type === "PCR COVID-19 Test" ? (
          <View style={PDFStyles.resultTypes}>
            <ResultType
              type="Not detected"
              description="A “Not Detected” result indicates that RNA from SARS-CoV-2 in the patient sample was either absent or present below the limit of detection of the assay. The healthcare provider should consider the clinical manifestations to determine if the patient is truly negative."
            />
            <ResultType
              type="Detected"
              description="A “Detected” result indicated the presence of RNA from SARS-CoV-2 in patient sample. Detection of the viral RNA may not indicate the presence of infectious virus or that SARS-CoV-2 is the causative agent for clinical symptoms. Patient is recommended follow instructions from their health care provider."
            />
            <ResultType
              type="Invalid"
              description="An “invalid” test result is a consequence of improper performance of test (compromised specimen and/or improper assay conditions) and accurate interpretation of the results cannot be made. Sample recollection and retesting is recommended."
            />
            <ResultType
              type="Inconclusive"
              description="An “Inconclusive” result indicates the inability to reliable determine a clinical result attributed by inconsistent amplification of all the required SARS-CoV-2 targets from the clinical sample. A recollection of sample and retesting is recommended."
            />
          </View>
        ) : (
          ""
        )}
      </Page>
    </Document>
  )
}
