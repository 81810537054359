import React from "react"

interface Props {
  icon: "tick-circle-fill" | "cross-circle-fill"
  children: React.ReactNode
  large?: boolean
}

export const BulletsIconBullet = ({ icon, children, large = false }: Props) => (
  <div
    className={`bullets-icon-bullet${
      large ? " bullets-icon-bullet-large" : ""
    }`}
  >
    <span className={`icon-${icon} bullets-icon-bullet-icon`} />
    <span className="bullets-icon-bullet-text">{children}</span>
  </div>
)
