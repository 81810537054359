import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import { Helmet } from "react-helmet"
import { useToasts } from "react-toast-notifications"
import {
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
  EmailAuthProvider,
} from "firebase/auth"

import { Form, InputPassword, Button } from "../../shared"
import { SEOTitleTemplate, firebaseApp } from "../../../constants"
import { useAuth } from "../../../hooks"

export const ChangePassword = () => {
  const authHook = useAuth()
  const currentUser = authHook?.user
  const { addToast } = useToasts()
  const auth = getAuth(firebaseApp)
  const user = auth?.currentUser

  const onSubmit = async (
    values: {
      currentPassword: string
      newPassword: string
    },
    { setSubmitting, resetForm }: { setSubmitting: any; resetForm: any }
  ) => {
    try {
      if (user)
        await reauthenticateWithCredential(
          user,
          EmailAuthProvider.credential(
            currentUser.email,
            values.currentPassword
          )
        )
      await changePassword(values.newPassword)
      resetForm({
        currentPassword: "",
        newPassword: "",
      })
    } catch (error: any) {
      if (error.code === "auth/wrong-password") {
        addToast("Could not update password, current password was incorrect", {
          appearance: "error",
        })
      } else {
        addToast("Could not update password, please try again", {
          appearance: "error",
        })
      }
    }
    setSubmitting(false)
  }

  const changePassword = async (newPassword: string) => {
    try {
      if (user) await updatePassword(user, newPassword)

      addToast("Password updated successfully", {
        appearance: "success",
      })
    } catch (error: any) {
      console.log(error)
      addToast("Could not update password, " + error.message, {
        appearance: "error",
      })
    }
  }
  return (
    <>
      <Helmet title={SEOTitleTemplate({ title: "Change your password" })} />
      <Formik
        initialValues={{
          currentPassword: "",
          newPassword: "",
        }}
        validationSchema={Yup.object().shape({
          currentPassword: Yup.string().required(
            "Please enter the password you'd like to update"
          ),
          newPassword: Yup.string().required("Please enter your new password"),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form variant="card-small">
            <InputPassword label="Current password" name="currentPassword" />
            <InputPassword label="New password" name="newPassword" />
            <Button
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Change password
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}
