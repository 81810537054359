export const testTypes = [
  {
    value: "Rapid Antigen COVID-19 Test",
    label: "Rapid Antigen COVID-19 Test",
  },
  {
    value: "PCR COVID-19 Test",
    label: "PCR COVID-19 Test",
  },
  /* 
  {
    value: "Antibody COVID-19 Test",
    label: "Antibody COVID-19 Test",
  }, */
]
