import React from "react"
import { Page, Text, View, Image, Document } from "@react-pdf/renderer"
import moment from "moment-timezone"

import { PDFStyles, TableCell, TableHeaderCell } from "../../../shared"
import GotTheTestLogoDark from "../../../../assets/GotTheTest-logo-dark.jpg"
import {
  surveyQuestionType,
  surveyResponseAnswerType,
  surveyResponseType,
} from "../../../../types"

export const SurveyResponsePDF = ({ data }: { data: surveyResponseType }) => {
  const findQuestionLabel = (answerId: string) => {
    const question = data.surveyDefinition.questions.find(
      (question: surveyQuestionType) => question.id === answerId
    )
    return question ? question.label : ""
  }

  return (
    <Document
      title={`Survey Response ${data?.patient?.fullName}`}
      author="GotTheTest"
      subject={`Survey Response created ${moment(data.completed).format(
        "MM-DD-yyyy"
      )} ${data?.patient?.fullName}`}
    >
      <Page size="A4" style={PDFStyles.page}>
        <View style={PDFStyles.header}>
          <Image style={PDFStyles.headerLogo} src={GotTheTestLogoDark} />
          <View style={PDFStyles.headerCompanyInfo}>
            <Text>
              GotTheTest Inc. 2139 Placentia Ave, Costa Mesa, CA, 92627, United
              States gotthetest.portmanteau.app
            </Text>
          </View>
          <View>
            <Text style={PDFStyles.headerTestIDBold}>Survey Response ID:</Text>
            <Text style={PDFStyles.headerTestID}>{data?.id}</Text>
          </View>
        </View>
        <View style={PDFStyles.table}>
          <View style={PDFStyles.tableRow}>
            <TableCell
              width="25%"
              label="Patient Name"
              value={data?.patient?.fullName}
            />
            <TableCell
              width="25%"
              label="Patient ID"
              value={data?.patient?.id}
            />
            <TableCell
              width="17%"
              label="Patient Phone"
              value={data?.patient?.phone}
            />
            <TableCell
              width="17%"
              label="DOB"
              value={data?.patient?.dateOfBirth}
            />
            <TableCell
              width="16%"
              label="Gender"
              value={data?.patient?.gender}
            />
          </View>
          <View style={PDFStyles.tableRow}>
            <TableCell label="Address" value={data?.patient?.address} />
          </View>
        </View>
        <View style={PDFStyles.table}>
          <View style={PDFStyles.tableRow}>
            <View style={PDFStyles.tableColumn50}>
              <View style={PDFStyles.tableRow}>
                <TableHeaderCell header="Survey Questions" />
              </View>
              {data?.surveyDefinition?.questions &&
                data?.answers.map((answer: surveyResponseAnswerType) => (
                  <View style={PDFStyles.tableRow} key={answer.id}>
                    <TableCell
                      label={findQuestionLabel(answer.id)}
                      value={answer.value ? "Yes" : "No"}
                    />
                  </View>
                ))}
            </View>
            <View style={PDFStyles.tableColumn50}>
              <View style={PDFStyles.tableRow}>
                <TableHeaderCell header="Outcome" />
              </View>
              <View style={PDFStyles.tableRow}>
                <TableCell
                  label="Completed"
                  value={`${moment(data?.completed).format(
                    "MM/DD/YYYY hh:mm A"
                  )} (${moment.tz(moment.tz.guess()).zoneAbbr()})`}
                />
              </View>
              <View style={PDFStyles.tableRow}>
                <View style={PDFStyles.tableResult2Cell}>
                  <Text style={PDFStyles.tableResultText}>{data?.outcome}</Text>
                  <Text style={PDFStyles.fontCentered}>
                    ({data?.surveyDefinition?.name})
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}
