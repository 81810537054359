interface Props {
  planTier: number | undefined //  0 | 1 | 2 | 3
  feature: string
}

/* 
  Plan Tiers:
  0 = Free
  1 = Premium
  2 = Family
  3 = Enterprise
*/

export const checkFeaturePermission = ({ planTier, feature }: Props) => {
  if (planTier) {
    if (feature === "multiple-patients") {
      return planTier === 2
    } else if (feature === "diagnostics-results") {
      return planTier === 1 || planTier === 2 || planTier === 3
    } else {
      return false
    }
  } else {
    return false
  }
}
