import React from "react"

import { useAuth, usePlan } from "../../../../hooks"
import { generatePlanButtonText, getPlanNumber } from "../../../../utilities"
import { Button, BulletsIcon } from "../.."
import { planType } from "../../../../types"

interface Props {
  plan: planType
  variant: "sign-up" | "change-plan"
}

export const Plan = ({ plan, variant }: Props) => {
  const { selectPlan, formatPlanBenefits } = usePlan()
  const authHook = useAuth()
  const planTier = authHook?.planTier

  return (
    <div
      className={`plan${plan?.fields?.mostPopular ? " plan-most-popular" : ""}`}
    >
      {plan?.fields?.mostPopular && (
        <div className="plan-most-popular-details">
          <span>Most popular</span>
        </div>
      )}
      <section
        className={`plan-details ${
          plan?.fields?.mostPopular ? " plan-details-most-popular" : ""
        }`}
      >
        <div className="plan-details-header">
          <h2 className="plan-details-header-title">{plan?.fields?.name}</h2>
          <p className="plan-details-header-description">
            {plan?.fields?.description}
          </p>
          <div className="plan-details-header-price">
            <span className="plan-details-header-price-value">
              ${plan?.fields?.cost}
            </span>
            <span className="plan-details-header-price-period">/mo</span>
          </div>
          <Button
            onClick={() => selectPlan(plan)}
            disabled={
              planTier === getPlanNumber(plan?.sys?.id) &&
              variant === "change-plan"
            }
            formCard
            variant={
              planTier === getPlanNumber(plan?.sys?.id) &&
              variant === "change-plan"
                ? "disabled"
                : "primary"
            }
          >
            {variant === "sign-up"
              ? "Choose this plan"
              : generatePlanButtonText(
                  planTier ? planTier : 0,
                  plan?.sys?.id,
                  plan?.fields?.name
                )}
          </Button>
        </div>
        <div className="plan-details-body">
          <BulletsIcon bullets={formatPlanBenefits(plan)} large={true} />
        </div>
      </section>
    </div>
  )
}
