import ReactSelect from "react-select"

import {
  reactSelectOptionsType,
  reactSelectOptionType,
} from "../../../../types"

interface Props {
  loading?: boolean
  onChange: (arg0: reactSelectOptionType) => void
  options: reactSelectOptionsType
  name: string
  placeholder?: string
  value: number | { value: string | undefined; label: string | undefined }
}

export const Filter = ({
  loading = false,
  onChange,
  options,
  name,
  placeholder,
  value,
}: Props) => (
  <div className="filter">
    <ReactSelect
      isLoading={loading}
      placeholder={placeholder ? placeholder : "Please select..."}
      styles={{
        option: (provided: any, state: any) => ({
          ...provided,
          backgroundColor: state.isSelected
            ? "#ffae33 !important"
            : state.isFocused
            ? "#f2f2f5"
            : "white",
        }),
      }}
      name={name}
      type="select"
      classNamePrefix="react-select"
      className="input-select"
      options={options}
      onChange={onChange}
      value={value}
    />
  </div>
)
