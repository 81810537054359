import { useQuery } from ".."
import { reactSelectOptionsType, patientType } from "../../types"

export const usePatients = () => {
  const { data, error, loading } = useQuery({
    url: "/patient/all",
  })

  let formattedPatients: reactSelectOptionsType = []

  if (!loading && data) {
    data.forEach((patient: patientType & { id: string }) =>
      formattedPatients.push({
        value: patient.id,
        label: `${patient.firstName} ${patient.lastName}`,
      })
    )
  }
  const rawPatients = data
  return { formattedPatients, rawPatients, error, loading }
}
