import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import moment from "moment-timezone"

import {
  Modal,
  Button,
  ButtonWithIcon,
  Dialog,
  Tag,
  InputSelectResult,
} from "../../../shared"
import { AppointmentReview } from "../AppointmentReview"
import { SEOTitleTemplate } from "../../../../constants"

export const ViewAppointment = ({
  modalVisible,
  closeModal,
  openCancelModal,
  data,
}) => (
  <Modal
    isVisible={modalVisible}
    dismissFunc={closeModal}
    size="medium"
    header={false}
  >
    <Helmet
      title={SEOTitleTemplate({
        title: `Appointment ${moment(data.start).format("MM/DD/YYYY hh:mm A")}`,
      })}
    />
    <Dialog
      icon={false}
      footer={
        <>
          {moment(data.start).subtract(30, "minutes") < new Date() ? (
            <>
              <InputSelectResult label="Patient ID">
                {data?.resource?.patient?.id}
              </InputSelectResult>
              <InputSelectResult label="Appointment ID">
                {data?.resource?.id}
              </InputSelectResult>
              <ButtonWithIcon
                icon="external-link"
                iconPosition="left"
                href={data?.resource?.mendAppointment?.shortUrl}
              >
                Join appointment call
              </ButtonWithIcon>
            </>
          ) : (
            <Tag type="success">
              Link to video call becomes available 30 minutes before appointment
              time
            </Tag>
          )}
          {data.start < new Date() ? (
            ""
          ) : (
            <Button onClick={openCancelModal} variant="secondary">
              Cancel appointment
            </Button>
          )}
          <Button onClick={closeModal} variant="secondary">
            Back to appointments
          </Button>
        </>
      }
    >
      {data.end < new Date() ? (
        <Tag type="error">This appointment is in the past</Tag>
      ) : (
        ""
      )}
      <AppointmentReview
        title={data.originalTitle}
        dates={{
          startTime: data.start,
          endTime: data.end,
        }}
        patient={data.resource.patient.fullName}
      />
    </Dialog>
  </Modal>
)

ViewAppointment.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
}
