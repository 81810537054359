import React from "react"
import { Helmet } from "react-helmet"
import moment from "moment-timezone"
import { PDFDownloadLink } from "@react-pdf/renderer"

import {
  ButtonLinkWithIcon,
  Query,
  Card,
  CardData,
  ButtonWithIcon,
} from "../../shared"
import { SEOTitleTemplate } from "../../../constants"
import { useQuery } from "../../../hooks"
import { SurveyResponsePDF } from "./SurveyResponsePDF"
import { surveyResponseAnswerType, surveyQuestionType } from "../../../types"

interface Props {
  id?: string
}

export const ViewSurveyResponse = ({ id }: Props) => {
  const { data, error, loading } = useQuery({
    url: `/surveys/responses/${id}`,
  })
  return (
    <>
      <Helmet title={SEOTitleTemplate({ title: `Survey Response ${id}` })} />
      <div className="view-result">
        <ButtonLinkWithIcon to="/surveys">Survey responses</ButtonLinkWithIcon>
        {loading || error ? (
          <Query
            additionalClasses="view-result-loader"
            loading={loading}
            error={error}
            action="load survey response"
          />
        ) : (
          data && (
            <>
              <header className="view-result-header">
                <div className="view-result-header-info">
                  <h1 className="heading-extra-large">
                    {data?.surveyDefinition?.name}
                  </h1>
                  <PDFDownloadLink
                    document={<SurveyResponsePDF data={data} />}
                    fileName={`${data?.patient?.fullName
                      .replace(/\s+/g, "-")
                      .toLowerCase()}-${moment(data?.completed).format(
                      "MM-DD-yyyy"
                    )}-${data?.surveyDefinition?.name
                      .replace(/\s+/g, "-")
                      .toLowerCase()}`}
                  >
                    <ButtonWithIcon
                      iconPosition="left"
                      icon="download"
                      onClick={() => {}}
                    >
                      Download as PDF
                    </ButtonWithIcon>
                  </PDFDownloadLink>
                </div>
              </header>
              <section className="view-result-body">
                <div className="view-result-body-test">
                  {data?.patient && (
                    <Card heading="Patient details" columns={3}>
                      {data?.patient?.firstName && data?.patient?.lastName && (
                        <CardData label="Name" colSpan={3}>
                          {data?.patient?.firstName} {data?.patient?.lastName}
                        </CardData>
                      )}
                      {data?.patient?.gender && (
                        <CardData label="Gender">
                          {data?.patient?.gender}
                        </CardData>
                      )}
                      {data?.patient?.dateOfBirth && (
                        <CardData label="Data of birth">
                          {data?.patient?.dateOfBirth}
                        </CardData>
                      )}
                      {data?.patient?.race && (
                        <CardData label="Race">{data?.patient?.race}</CardData>
                      )}
                    </Card>
                  )}
                  <Card heading="Response details" columns={3}>
                    {id && <CardData label="Response ID">{id}</CardData>}
                    {data?.completed && (
                      <CardData label="Completed">
                        {moment(data?.completed).format("MM/DD/YYYY hh:mm A")}
                      </CardData>
                    )}
                    {data?.outcome && (
                      <CardData label="Response outcome">
                        {data?.outcome}
                      </CardData>
                    )}
                    {data?.surveyDefinition?.questions &&
                      data?.answers.map((answer: surveyResponseAnswerType) => (
                        <CardData
                          key={answer.id}
                          label={
                            data.surveyDefinition.questions.find(
                              (question: surveyQuestionType) =>
                                question.id === answer.id
                            ).label
                          }
                          colSpan={3}
                        >
                          {answer.value ? "Yes" : "No"}
                        </CardData>
                      ))}
                  </Card>
                </div>
                <div className="view-result-body-result">
                  <Card heading="Survey details">
                    <CardData label="Survey name">
                      {data?.surveyDefinition?.name}
                    </CardData>
                    {data?.patientSurveyResponses && (
                      <CardData label="Response count">
                        {data?.patientSurveyResponses}
                      </CardData>
                    )}
                  </Card>
                </div>
              </section>
            </>
          )
        )}
      </div>
    </>
  )
}
