import { freePlanId, premiumPlanId, familyPlanId } from ".."

export const getPlanNumber = (planId: string) => {
  if (planId === freePlanId) {
    return 0
  } else if (planId === premiumPlanId) {
    return 1
  } else if (planId === familyPlanId) {
    return 2
  } else {
    return 0
  }
}
