import React from "react"
import PropTypes from "prop-types"
import DatePicker from "react-date-picker"

import { Label } from ".."

export const InputDatePicker = ({
  label,
  name,
  value,
  setValue,
  tileDisabled,
  error,
}) => {
  const [month, setMonth] = React.useState(new Date())

  return (
    <div className="input-date-picker">
      <Label field={name}>{label}</Label>
      <DatePicker
        activeStartDate={month}
        onActiveStartDateChange={(newMonth) =>
          setMonth(newMonth.activeStartDate)
        }
        clearIcon={null}
        value={value}
        onChange={setValue}
        calendarIcon={<span className="icon-calendar" />}
        prevLabel={<span className="icon-chevron-left" />}
        format="MM/dd/y"
        nextLabel={<span className="icon-chevron-right" />}
        name={name}
        showFixedNumberOfWeeks={true}
        tileDisabled={({ date }) => tileDisabled(date)}
      />
      <span className="input-error-text">{error}</span>
    </div>
  )
}

InputDatePicker.defaultProps = {
  error: "",
}

InputDatePicker.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  tileDisabled: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date).isRequired,
}
