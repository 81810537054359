import React from "react"
import { Helmet } from "react-helmet"
import moment from "moment-timezone"
import { useToasts } from "react-toast-notifications"
import { getStorage, ref, getDownloadURL } from "firebase/storage"
import { PDFDownloadLink } from "@react-pdf/renderer"

import {
  ButtonLinkWithIcon,
  Tag,
  Card,
  CardData,
  CardImage,
  Query,
  ButtonWithIcon,
  CardUploadedFile,
} from "../../shared"
import { useQuery } from "../../../hooks"
import { SEOTitleTemplate } from "../../../constants"
import { AppointmentCard } from "./AppointmentCard"
import { ResultPDF } from "./ResultPDF"

export const ViewDiagnostic = ({ id }: { id?: string }) => {
  const storage = getStorage()
  const { addToast } = useToasts()

  const [imageUrl, setImageUrl] = React.useState("")
  const { data, error, loading } = useQuery({
    url: `/tests/${id}`,
    requestData: { _expand: ["patient", "testResult", "appointment"] },
  })

  React.useEffect(() => {
    const getImageUrl = async () => {
      try {
        const url = await getDownloadURL(
          ref(storage, data.testResult.images[0])
        )
        setImageUrl(url)
      } catch (error) {
        addToast("Could not get result image", {
          appearance: "error",
        })
      }
    }

    if (data?.testResult?.images) {
      getImageUrl()
    }
  }, [data]) // eslint-disable-line

  const reading = data?.testResult?.reading?.data

  return (
    <>
      <Helmet title={SEOTitleTemplate({ title: `Diagnostic ${id}` })} />
      <div className="view-result">
        <ButtonLinkWithIcon to="/">Diagnostics</ButtonLinkWithIcon>
        {loading || error ? (
          <Query
            additionalClasses="view-result-loader"
            loading={loading}
            error={error}
            action="load diagnostic"
          />
        ) : (
          data && (
            <>
              <header className="view-result-header">
                <div className="view-result-header-info">
                  <h1 className="heading-extra-large">
                    {moment(data?.sampledTime).format("MM/DD/YYYY hh:mm A")}
                  </h1>
                  <PDFDownloadLink
                    document={<ResultPDF data={data} />}
                    fileName={`${data?.patient?.fullName
                      .replace(/\s+/g, "-")
                      .toLowerCase()}-${data?.type
                      .replace(/\s+/g, "-")
                      .toLowerCase()}-${moment(data?.sampledTime).format(
                      "MM-DD-yyyy"
                    )}`}
                  >
                    {reading !== "Pending" && (
                      <ButtonWithIcon
                        iconPosition="left"
                        icon="download"
                        onClick={() => {}}
                      >
                        Download as PDF
                      </ButtonWithIcon>
                    )}
                  </PDFDownloadLink>
                </div>
                <div className="view-result-header-data">
                  {!data.selfTest && (
                    <Tag icon={true} type="success">
                      Result verified by medical professional
                    </Tag>
                  )}
                </div>
              </header>
              <section className="view-result-body">
                <div className="view-result-body-test">
                  {data.patient && (
                    <Card heading="Patient details" columns={3}>
                      {data.patient.firstName && data.patient.lastName && (
                        <CardData label="Name" colSpan={3}>
                          {data.patient.firstName} {data.patient.lastName}
                        </CardData>
                      )}
                      {data.patient.gender && (
                        <CardData label="Gender">
                          {data.patient.gender}
                        </CardData>
                      )}
                      {data.patient.dateOfBirth && (
                        <CardData label="Data of birth">
                          {data.patient.dateOfBirth}
                        </CardData>
                      )}
                      {data.patient.race && (
                        <CardData label="Race">{data.patient.race}</CardData>
                      )}
                    </Card>
                  )}
                  <Card heading="Test details" columns={3}>
                    {data.type && (
                      <CardData label="Test type" colSpan={3}>
                        {data.type}
                      </CardData>
                    )}
                    {data.sampleType && (
                      <CardData label="Sample type">{data.sampleType}</CardData>
                    )}
                    {data.manufacturer && (
                      <CardData label="Test manufacturer">
                        {data.manufacturer}
                      </CardData>
                    )}
                    {data.batch && (
                      <CardData label="Batch">{data.batch}</CardData>
                    )}
                  </Card>
                </div>
                <div className="view-result-body-result">
                  <Card heading="Result details">
                    {imageUrl && (
                      <>
                        {imageUrl.includes(".pdf") ? (
                          <CardUploadedFile
                            name="Uploaded PDF"
                            link={imageUrl}
                            icon="external-link"
                          />
                        ) : (
                          <CardImage
                            src={imageUrl}
                            alt={`${data.type} result from ${moment(
                              data.sampledTime
                            ).format("MM/DD/YYYY")}`}
                          />
                        )}
                      </>
                    )}
                    {data.testResult && (
                      <CardData label="Result">
                        <div className="result-reading">
                          {reading === "Negative" ||
                          reading === "Not detected" ? (
                            <span className="icon-tick-circle-fill result-reading-icon result-reading-icon-positive" />
                          ) : reading === "Positive" ||
                            reading === "Invalid" ||
                            reading === "Detected" ? (
                            <span className="icon-alert-circle-fill result-reading-icon result-reading-icon-negative" />
                          ) : reading === "Pending" ? (
                            <span className="icon-clock result-reading-icon result-reading-icon-pending" />
                          ) : (
                            <span className="result-reading-icon" />
                          )}
                          <span className="result-reading-text">{reading}</span>
                        </div>
                      </CardData>
                    )}
                    {!data.selfTest && data.appointment ? (
                      <AppointmentCard
                        sampledTime={data?.sampledTime}
                        resultsReadTime={data?.resultsReadTime}
                        expiry={data?.expiry}
                        appointmentEnd={data?.appointment.endTime}
                        appointmentStart={data?.appointment.startTime}
                        orderingPhysician={data?.orderingPhysician}
                        npi={data?.npi}
                        observingAssistantName={data?.observingAssistantName}
                      />
                    ) : (
                      <>
                        {data.sampledTime && (
                          <CardData label="Result Id">{id ? id : ""}</CardData>
                        )}
                        {data?.resultsReadTime && (
                          <CardData label="Result read time">
                            {moment(data.resultsReadTime).format(
                              "MM/DD/YYYY hh:mm A"
                            )}
                          </CardData>
                        )}
                        {data?.orderingPhysician && data?.npi && (
                          <CardData label="Ordering Physician">
                            {`${data?.orderingPhysician} (${data?.npi})`}
                          </CardData>
                        )}
                        {data?.observingAssistantName && (
                          <CardData label="Observing Assistant">
                            {data?.observingAssistantName}
                          </CardData>
                        )}
                        {data?.cliaLabName && data?.cliaLabNumber && (
                          <CardData label="CLIA Lab">
                            {`${data?.cliaLabName} (${data?.cliaLabNumber})`}
                          </CardData>
                        )}
                      </>
                    )}
                  </Card>
                </div>
              </section>
            </>
          )
        )}
      </div>
    </>
  )
}
