import React from "react"
import { View, Text } from "@react-pdf/renderer"

import { PDFStyles } from "../../../../shared"

interface Props {
  type: string
  description: string
}

export const ResultType = ({ type, description }: Props) => (
  <View style={PDFStyles.resultType}>
    <Text style={PDFStyles.resultTypeHeading}>{type}</Text>
    <Text style={PDFStyles.font}>{description}</Text>
  </View>
)
