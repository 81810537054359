import React from "react"
import PropTypes from "prop-types"

import { Step1 } from "./Step1"
import { Step2 } from "./Step2"
import { Step3 } from "./Step3"
import { Confirmation } from "./Confirmation"

export const HasResult = ({ step, closeModal, setTests }) => {
  return step === 1 ? (
    <Step1 />
  ) : step === 2 ? (
    <Step2 />
  ) : step === 3 ? (
    <Step3 setTests={setTests} />
  ) : step === 4 ? (
    <Confirmation closeModal={closeModal} />
  ) : null
}

HasResult.propTypes = {
  step: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  setTests: PropTypes.func.isRequired,
}
