import React from "react"
import { Helmet } from "react-helmet"
import moment from "moment-timezone"
import { getStorage, ref, getDownloadURL } from "firebase/storage"
import { PDFDownloadLink } from "@react-pdf/renderer"
import { useToasts } from "react-toast-notifications"

import { SEOTitleTemplate } from "../../../constants"
import {
  ButtonLinkWithIcon,
  Query,
  Card,
  CardData,
  CardImage,
  ButtonWithIcon,
  CardUploadedFile,
} from "../../shared"
import { useQuery } from "../../../hooks"
import { ImmunizationPDF } from "./ImmunizationPDF"
import { formatImmunizationDoseAsText } from "../../../utilities"

interface Props {
  id?: string
}

export const ViewImmunization = ({ id }: Props) => {
  const storage = getStorage()
  const { addToast } = useToasts()

  const { data, error, loading } = useQuery({
    url: `/immunization-records/${id}`,
    requestData: { _expand: ["patient", "testResult"] },
  })

  const [imageUrl, setImageUrl] = React.useState<string | null>(null)

  React.useEffect(() => {
    const getImageUrl = async () => {
      try {
        const url = await getDownloadURL(ref(storage, data?.images[0]))
        setImageUrl(url)
      } catch (error: any) {
        console.log(error, error.response)
        addToast("Could not get immunization image", {
          appearance: "error",
        })
      }
    }

    if (data && data?.images) {
      getImageUrl()
    }
  }, [data]) // eslint-disable-line

  return (
    <>
      <Helmet title={SEOTitleTemplate({ title: `Immunization ${id}` })} />
      <div className="view-result">
        <ButtonLinkWithIcon to="/immunizations">
          Immunizations
        </ButtonLinkWithIcon>
        {loading || error ? (
          <Query
            additionalClasses="view-result-loader"
            loading={loading}
            error={error}
            action="load immunization"
          />
        ) : (
          data && (
            <>
              <header className="view-result-header">
                <div className="view-result-header-info">
                  <h1 className="heading-extra-large">
                    {data?.vaccineManufacturer} (
                    {formatImmunizationDoseAsText({ immunizationRecord: data })}
                    )
                  </h1>
                  <PDFDownloadLink
                    document={<ImmunizationPDF data={data} />}
                    fileName={`${data?.patient?.fullName
                      .replace(/\s+/g, "-")
                      .toLowerCase()}-${data?.vaccinationDate.replace(
                      /\//g,
                      "-"
                    )}`}
                  >
                    <ButtonWithIcon
                      iconPosition="left"
                      icon="download"
                      onClick={() => {}}
                    >
                      Download as PDF
                    </ButtonWithIcon>
                  </PDFDownloadLink>
                </div>
              </header>
              <section className="view-result-body">
                <div className="view-result-body-test">
                  {data?.patient && (
                    <Card heading="Patient details" columns={3}>
                      {data?.patient?.firstName && data?.patient?.lastName && (
                        <CardData label="Name" colSpan={3}>
                          {data?.patient?.firstName} {data?.patient?.lastName}
                        </CardData>
                      )}
                      {data?.patient?.gender && (
                        <CardData label="Gender">
                          {data?.patient?.gender}
                        </CardData>
                      )}
                      {data?.patient?.dateOfBirth && (
                        <CardData label="Data of birth">
                          {data?.patient?.dateOfBirth}
                        </CardData>
                      )}
                      {data?.patient?.race && (
                        <CardData label="Race">{data?.patient?.race}</CardData>
                      )}
                    </Card>
                  )}
                  <Card heading="Vaccine details" columns={3}>
                    {data?.name && (
                      <CardData label="Vaccine">{data?.name}</CardData>
                    )}
                    {data?.diseaseTargeted && (
                      <CardData label="Disease targeted">
                        {data?.diseaseTargeted}
                      </CardData>
                    )}
                    {data?.vaccineManufacturer && (
                      <CardData label="Manufacturer">
                        {data?.vaccineManufacturer}
                      </CardData>
                    )}
                    {data?.vaccineManufacturerBatch && (
                      <CardData label="Batch">
                        {data?.vaccineManufacturerBatch}
                      </CardData>
                    )}
                  </Card>
                </div>
                <div className="view-result-body-result">
                  <Card heading="Dose details">
                    {imageUrl && (
                      <>
                        {imageUrl.includes(".pdf") ? (
                          <CardUploadedFile
                            name="Uploaded PDF"
                            link={imageUrl}
                            icon="external-link"
                          />
                        ) : (
                          <CardImage
                            src={imageUrl}
                            alt={`Vaccination card ${moment(
                              data?.created
                            ).format("MM/DD/YYYY")}`}
                          />
                        )}
                      </>
                    )}
                    {data?.vaccinationDate && (
                      <CardData label="Dose">
                        {formatImmunizationDoseAsText({
                          immunizationRecord: data,
                        })}
                      </CardData>
                    )}
                    {data?.dose && (
                      <CardData label="Date">{data?.vaccinationDate}</CardData>
                    )}
                    {data?.created && (
                      <CardData label="Saved">
                        {moment(data?.created).format("MM/DD/YYYY hh:mm A")}
                      </CardData>
                    )}
                    {id && <CardData label="Immunization Id">{id}</CardData>}
                    {data?.medicalProvider && (
                      <CardData label="Administering centre">
                        {data?.medicalProvider}
                      </CardData>
                    )}
                  </Card>
                </div>
              </section>
            </>
          )
        )}
      </div>
    </>
  )
}
