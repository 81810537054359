import React from "react"
import axios from "axios"

import { Button, WarningModal } from "../../../shared"
import { UpdatePatientModal } from "../UpdatePatientModal"
import { handleAPIError } from "../../../../utilities"
import { toastType, patientType } from "../../../../types"

interface Props {
  accountHolder?: boolean
  addToast: toastType
  address: string
  dateOfBirth: string
  firstName: string
  gender: string
  id: string
  lastName: string
  phone: string
  race: string
  relationshipToAccountHolder?: string
  setPatients: (arg0: any) => void
}

export const Patient = ({
  id,
  accountHolder,
  relationshipToAccountHolder,
  firstName,
  lastName,
  dateOfBirth,
  gender,
  race,
  address,
  phone,
  addToast,
  setPatients,
}: Props) => {
  const [editPatientModalVisible, setEditPatientModalVisibility] =
    React.useState(false)
  const [deletePatientModalVisible, setDeletePatientModalVisibility] =
    React.useState(false)
  const [deletePatientLoading, setDeletePatientLoading] = React.useState(false)

  const deletePatient = async () => {
    setDeletePatientLoading(true)
    try {
      await axios({
        method: "post",
        url: "/patient/delete",
        data: { patientId: id },
      })
      setPatients((patients: Array<patientType>) =>
        patients.filter((patient) => patient.id !== id)
      )
      addToast("Patient deleted", { appearance: "success" })
      setDeletePatientModalVisibility(false)
    } catch (error) {
      addToast(handleAPIError(error, "delete patient"), {
        appearance: "error",
      })
    }
    setDeletePatientLoading(false)
  }

  return (
    <div className="account-card">
      <header className="account-card-header">
        <h2 className="heading-small">
          <span>
            {firstName && firstName} {lastName && lastName}
          </span>
          <span className="account-card-header-heading-light">
            {accountHolder
              ? " (you)"
              : relationshipToAccountHolder
              ? ` (${relationshipToAccountHolder})`
              : ""}
          </span>
        </h2>
        {race || gender ? (
          <span className="account-card-header-text">
            {race && race + " "}
            {gender && gender}
          </span>
        ) : (
          ""
        )}
        {dateOfBirth && (
          <span className="account-card-header-text">{dateOfBirth}</span>
        )}
        {phone && <span className="account-card-header-text">{phone}</span>}
        {address && <span className="account-card-header-text">{address}</span>}
      </header>
      <footer className="account-card-footer">
        <Button
          variant="secondary"
          onClick={() => setEditPatientModalVisibility(true)}
        >
          Edit
        </Button>
        {accountHolder ? (
          ""
        ) : (
          <Button
            variant="secondary"
            onClick={() => setDeletePatientModalVisibility(true)}
          >
            Delete
          </Button>
        )}
      </footer>
      <UpdatePatientModal
        accountHolder={accountHolder}
        addToast={addToast}
        address={address}
        dateOfBirth={dateOfBirth}
        dismissFunc={() => setEditPatientModalVisibility(false)}
        firstName={firstName}
        gender={gender}
        id={id}
        isVisible={editPatientModalVisible}
        lastName={lastName}
        phone={phone}
        race={race}
        relationshipToAccountHolder={relationshipToAccountHolder}
        setPatients={setPatients}
      />
      <WarningModal
        isVisible={deletePatientModalVisible}
        dismissFunc={() => setDeletePatientModalVisibility(false)}
        title="Delete patient?"
        deleteButtonText="Delete patient"
        deleteFunc={() => deletePatient()}
        deleteButtonLoading={deletePatientLoading}
      >
        You will not be able to use this patient in future diagnostic test
        results
      </WarningModal>
    </div>
  )
}
