import React from "react"
import PropTypes from "prop-types"

import { formatBytes } from "../../../../../utilities"
import { IconButton } from "../../.."

export const UploadedFile = ({ name, size, removeFunc }) => (
  <div className="uploaded-file">
    <span className="icon-file uploaded-file-icon" />
    <span className="uploaded-file-data">
      <span className="uploaded-file-data-name">{name}</span>
      <span className="uploaded-file-data-size">({formatBytes(size, 2)})</span>
    </span>
    {removeFunc && <IconButton icon="cross" onClick={removeFunc} />}
  </div>
)

UploadedFile.defaultProps = {
  removeFunc: null,
}

UploadedFile.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  removeFunc: PropTypes.func,
}
