import React from "react"
import { Helmet } from "react-helmet"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

import {
  Button,
  ButtonWithIcon,
  BulletsIcon,
  PlanHeader,
  PlanPaymentForm,
} from "../../shared"
import { SEOTitleTemplate } from "../../../constants"
import {
  getPlanName,
  freePlanId,
  calculatePercentageOff,
} from "../../../utilities"
import { useAuth, usePlan } from "../../../hooks"
import { DowngradeToFree } from "./DowngradeToFree"

const stripe = loadStripe(process.env.REACT_APP_STRIPE)

export const BillingInfo = () => {
  const { resetPlan, plan, allPlans, formatPlanBenefits } = usePlan()
  const authHook = useAuth()
  const planTier = authHook?.planTier ? authHook?.planTier : 0

  const [submitted, setSubmitted] = React.useState(false)
  const [planCost, setPlanCost] = React.useState({
    original: plan?.fields?.cost,
    discounted: 0,
  })
  const [coupon, setCoupon] = React.useState({
    visible: false,
    value: "",
    error: "",
    loading: false,
    succeeded: false,
    id: "",
    amount_off: 0,
    percent_off: 0,
  })

  React.useEffect(() => {
    if (coupon.amount_off) {
      setPlanCost((current) => ({
        ...current,
        discounted: current.discounted - coupon.amount_off,
      }))
    } else if (coupon.percent_off) {
      setPlanCost((current) => ({
        ...current,
        discounted: calculatePercentageOff(
          current.original,
          coupon.percent_off
        ),
      }))
    } else {
      setPlanCost((current) => ({ ...current, discounted: 0 }))
    }

    return () => {
      setPlanCost((current) => ({ ...current, discounted: 0 }))
    }
  }, [coupon.amount_off, coupon.percent_off])

  return (
    <>
      <Helmet
        title={SEOTitleTemplate({
          title: submitted
            ? "Congratulations! You've upgraded"
            : "Enter Billing Information",
        })}
      />
      <div className="billing-info-back-button">
        {!submitted && (
          <ButtonWithIcon
            iconPosition="left"
            icon="arrow-left"
            variant="secondary"
            onClick={() => resetPlan()}
          >
            Change plan
          </ButtonWithIcon>
        )}
      </div>
      <div className="billing-info">
        <main className="billing-info-details">
          {submitted ? (
            <div className="change-plan-guidance">
              <span className="icon-tick-circle-fill change-plan-guidance-icon" />
              <h1 className="heading-large">
                You’ve changed to the GotTheTest{" "}
                {getPlanName({ planTier, allPlans })} plan
              </h1>
              <p className="change-plan-guidance-text">
                You can start enjoying your account straight away.
              </p>
              <Button to="/">Start enjoying your account</Button>
            </div>
          ) : plan?.sys?.id === freePlanId ? (
            <DowngradeToFree setSubmitted={setSubmitted} />
          ) : (
            <Elements stripe={stripe}>
              <PlanPaymentForm
                variant="change-plan"
                setSubmitted={setSubmitted}
                planCost={planCost}
                setCoupon={setCoupon}
                coupon={coupon}
                resetCoupon={() =>
                  setCoupon({
                    visible: false,
                    value: "",
                    error: "",
                    id: "",
                    loading: false,
                    succeeded: false,
                    amount_off: 0,
                    percent_off: 0,
                  })
                }
              />
            </Elements>
          )}
        </main>
        <aside className="billing-info-plan">
          <div className="billing-info-plan-details">
            <PlanHeader
              name={plan?.fields?.name}
              description={plan?.fields?.description}
              originalCost={planCost.original}
              discountedCost={planCost.discounted}
            />
            <BulletsIcon bullets={formatPlanBenefits(plan)} large />
          </div>
        </aside>
      </div>
    </>
  )
}
