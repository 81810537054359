import React from "react"
import PropTypes from "prop-types"

import { Confirmation } from "./Confirmation"
import { Step1 } from "./Step1"
import { Step2 } from "./Step2"
import { Step3 } from "./Step3"
import { Step4 } from "./Step4"

import { ReactComponent as TestKit } from "../../../../../../assets/self-test/pcr/1-pcr-test-kit.svg"
import { ReactComponent as RemoveSwab } from "../../../../../../assets/self-test/pcr/2-remove-swab.svg"
import { ReactComponent as SwabLeft } from "../../../../../../assets/self-test/pcr/3-swab-left.svg"
import { ReactComponent as SwabRight } from "../../../../../../assets/self-test/pcr/4-swab-right.svg"
import { ReactComponent as SnapSwab } from "../../../../../../assets/self-test/pcr/5-snap-swab.svg"
import { ReactComponent as PackageSwab } from "../../../../../../assets/self-test/pcr/6-package.svg"

export const NeedsResult = ({ step, closeModal, setTests }) => {
  return step === 1 ? (
    <Step1 />
  ) : step === 2 ? (
    <Step2
      images={[
        {
          src: <TestKit />,
          alt: "Remove your test kit from packaging",
        },
        {
          src: <RemoveSwab />,
          alt: "Remove your swab from packaging",
        },
      ]}
    />
  ) : step === 3 ? (
    <Step3
      images={[
        {
          src: <SwabLeft />,
          alt: "Swab your left nostril",
        },
        {
          src: <SwabRight />,
          alt: "Swab your right nostril",
        },
      ]}
    />
  ) : step === 4 ? (
    <Step4
      images={[
        {
          src: <SnapSwab />,
          alt: "Snap off the top of your swab",
        },
        {
          src: <PackageSwab />,
          alt: "Package your swab",
        },
      ]}
      setTests={setTests}
    />
  ) : step === 5 ? (
    <Confirmation closeModal={closeModal} />
  ) : null
}

NeedsResult.propTypes = {
  step: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  setTests: PropTypes.func.isRequired,
}
