import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import { Formik } from "formik"
import * as Yup from "yup"
import { getStorage, ref, uploadBytes } from "firebase/storage"

import {
  Button,
  Form,
  FormLegend,
  FormSection,
  ImageUploader,
  InputSelect,
  ModalBody,
  ModalFooter,
} from "../../../../../../shared"
import { handleAPIError } from "../../../../../../../utilities"
import { useAuth } from "../../../../../../../hooks"
import { PCRResultOptions } from "../../../../../../../constants"
import { usePCRTest } from "../.."

export const Step3 = ({ setTests }) => {
  const storage = getStorage()
  const auth = useAuth()
  const { testData, setTestData, addToast } = usePCRTest()
  const [error, setError] = React.useState("")
  const [photos, setPhotos] = React.useState([])
  const [pdfs, setPdfFile] = React.useState([])

  const updatePdfs = (event) => {
    setPdfFile([event.target.files[0]])
  }

  const removePdf = () => {
    setPdfFile([])
  }

  const onSubmit = async (values, { setSubmitting }) => {
    if (photos.length === 0 && pdfs.length === 0) {
      setError("Please upload a photo or PDF of your Lab result")
    } else {
      try {
        const date = new Date().toISOString()

        const filePath = `users/${auth.user.uid}/images/${date}-${
          photos.length > 0 ? photos[0].file.name : pdfs[0].name
        }`
        const storageRef = ref(storage, filePath)
        await uploadBytes(
          storageRef,
          photos.length > 0 ? photos[0].file : pdfs[0]
        )
        const response = await axios({
          method: "post",
          url: "/tests/record-result",
          data: {
            testId: testData.data.id,
            result: {
              reading: {
                type: "PCR",
                data: values.reading,
              },
              images: [filePath],
            },
          },
        })
        const testWithTestResult = response.data
        setTests((tests) => [testWithTestResult, ...tests])
        setTestData((testData) => ({
          ...testData,
          step: testData.step + 1,
          data: { ...testWithTestResult },
        }))
        // setTests
      } catch (error) {
        addToast(handleAPIError(error, "save result"), {
          appearance: "error",
        })
        setSubmitting(false)
      }
    }
  }

  return (
    <Formik
      initialValues={{
        reading: "",
      }}
      validationSchema={Yup.object().shape({
        reading: Yup.string().required("Please enter the result of your test"),
      })}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <ModalBody>
            <FormLegend>
              <p>Please enter the details of your PCR test result</p>
            </FormLegend>
            <FormSection>
              <div>
                <ImageUploader
                  photos={photos}
                  updatePhotos={setPhotos}
                  updatePdfs={updatePdfs}
                  pdfs={pdfs}
                  variant="self-test"
                  removePdf={removePdf}
                  pdf={true}
                />
                <span className="input-error-text image-uploader-error">
                  {error}
                </span>
              </div>
              <div>
                <InputSelect
                  label="Reading shown"
                  name="reading"
                  options={PCRResultOptions}
                />
              </div>
            </FormSection>
          </ModalBody>
          <ModalFooter
            backFunc={() =>
              setTestData((testData) => ({
                ...testData,
                step: testData.step - 1,
              }))
            }
          >
            <Button
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Record result
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}

Step3.propTypes = {
  setTests: PropTypes.func.isRequired,
}
