import React from "react"
import PropTypes from "prop-types"

import { Button, BulletsIconBullet } from "../../../../../shared"

export const ReportCondition = ({ name, status }) => (
  <div className="report-condition">
    <BulletsIconBullet icon={status ? "tick" : "alert-circle-outline"}>
      {name}
    </BulletsIconBullet>
    {!status && (
      <section className="report-condition-body">
        {name === "Fill out a COVID-19 Symptoms Survey" ? (
          <Button variant="secondary" to="/surveys" small={true}>
            Complete survey response
          </Button>
        ) : (
          <>
            <Button variant="secondary" to="/immunizations" small={true}>
              Add immunization records
            </Button>
            <br />
            <Button variant="secondary" to="/" small={true}>
              New self-test
            </Button>
          </>
        )}
      </section>
    )}
  </div>
)

ReportCondition.propTypes = {
  name: PropTypes.string.isRequired,
  completed: PropTypes.bool,
  status: PropTypes.bool.isRequired,
}
