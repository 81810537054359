import React from "react"
import { Router } from "@reach/router"

import { ImmunizationsList } from "./ImmunizationsList"
import { ViewImmunization } from "./ViewImmunization"

export const Immunizations = () => (
  <Router>
    <ImmunizationsList path="/" />
    <ViewImmunization path="/:id" />
  </Router>
)
