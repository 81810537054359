import React from "react"

import { ProvidePlan } from "../../hooks"
import { ChangePlanMaster } from "./ChangePlanMaster"

/* 
  <ChangePlanMaster/> child component use for context control.
  Context state variables are needed at the top level of <ChangePlanMaster/>
  If they we're used in <ChangePlan/> they would be outside of <ProvidePlan/>
  context wrapper
*/

export const ChangePlan = () => (
  <ProvidePlan>
    <ChangePlanMaster />
  </ProvidePlan>
)
