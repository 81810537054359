import React from "react"
import PropTypes from "prop-types"

export const Slot = ({ children, selected, onClick }) => (
  <button
    type="button"
    className={`slot${selected ? " slot-selected" : ""}`}
    onClick={onClick}
  >
    {children}
  </button>
)

Slot.propTypes = {
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}
