import React from "react"
import { useQueryParam, StringParam } from "use-query-params"
import { useToasts } from "react-toast-notifications"
import axios from "axios"
import { navigate } from "@reach/router"
import { Helmet } from "react-helmet"

import { usePatients } from "../../../../hooks"
import {
  Button,
  FormLegend,
  Modal,
  ModalBody,
  ModalFooter,
  BulletsIcon,
} from "../../../shared"
import { handleAPIError } from "../../../../utilities"
import { SEOTitleTemplate } from "../../../../constants"
import { patientType } from "../../../../types"

export const ExistingAccountInvitation = () => {
  const [accountHolderPatient, setAccountHolderPatient] =
    React.useState<patientType>()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const {
    rawPatients,
    error: patientsError,
    loading: patientsLoading,
  } = usePatients()
  const [code] = useQueryParam("code", StringParam)
  const [alreadyUpgraded] = useQueryParam("alreadyUpgraded", StringParam)

  const { addToast } = useToasts()

  const onSubmit = async () => {
    if (code && accountHolderPatient?.id) {
      try {
        setLoading(true)
        await axios({
          method: "post",
          url: "invitations/confirm-enterprise-tier",
          data: {
            statusReportingGroupCode: code,
            patient: accountHolderPatient.id,
          },
        })
        addToast(
          alreadyUpgraded
            ? `You have joined ${code} Status Reporting Group code`
            : `Your account has been upgraded to the GotTheTest Enterprise plan. You have also joined ${code} Status Reporting Group code`,
          {
            appearance: "success",
          }
        )
        navigate("/account/status-reporting")
      } catch (error) {
        addToast(handleAPIError(error, "upgrade to Enterprise plan"), {
          appearance: "error",
        })
        setLoading(false)
      }
    } else {
      addToast(
        "Could not upgrade to Enterprise plan, could not find Status Reporting Group code to join, please click the link in your email again",
        {
          appearance: "error",
        }
      )
    }
  }

  React.useEffect(() => {
    if (rawPatients && rawPatients.length > 0) {
      setAccountHolderPatient(
        rawPatients.find((patient: patientType) => patient.accountHolder)
      )
    } else if (patientsError) {
      addToast(
        handleAPIError(patientsError, "find account patients for invitation"),
        {
          appearance: "error",
        }
      )
    }
  }, [rawPatients, patientsError]) // eslint-disable-line

  return (
    <>
      <Helmet
        title={SEOTitleTemplate({ title: "Accept Enterprise Invitation" })}
      />
      <div className="form-card">
        <h1 className="heading-large form-card-heading-central">
          Accept invitation to{" "}
          {code ? code : "{no Status Reporting Group code found}"}?
        </h1>
        <p className="form-card-paragraph text-center">
          Your employer has invited you to join{" "}
          {code ? code : "{no Status Reporting Group code found}"} Status
          Reporting Group to report your Fit for Work status.
        </p>
        {!alreadyUpgraded && (
          <>
            <FormLegend>
              To accept this invitation your account will be upgraded to the
              GotTheTest Enterprise plan.
            </FormLegend>
            <Button
              type="button"
              variant="secondary"
              formCard
              onClick={() => setModalVisible(true)}
            >
              More about Enterprise plan
            </Button>
          </>
        )}
        <Button
          type="button"
          formCard
          onClick={onSubmit}
          disabled={patientsLoading}
          loading={loading}
        >
          Accept invitation
        </Button>
      </div>
      <Modal
        dismissFunc={() => setModalVisible(false)}
        isVisible={modalVisible}
        title="GotTheTest Enterprise Plan"
      >
        <ModalBody>
          <p className="mb-4">
            On the GotTheTest Enterprise plan you'll be able to:
          </p>
          {/* Same as Preium plan, should update to be dynmaic and pull from Contentful Subscriptions Master */}
          <BulletsIcon
            bullets={[
              {
                text: "Upload immunization records",
                icon: "tick-circle-fill",
              },
              {
                text: "Complete surveys",
                icon: "tick-circle-fill",
              },
              {
                text: "Book telehealth appointments and access appointment results",
                icon: "tick-circle-fill",
              },
              {
                text: "Upload test results and complete self-tests",
                icon: "tick-circle-fill",
              },
              {
                text: "Download test results as PDFs",
                icon: "tick-circle-fill",
              },
              {
                text: "Add multiple patients",
                icon: "cross-circle-fill",
              },
              {
                text: "Upload test results and complete self-tests for multiple patients",
                icon: "cross-circle-fill",
              },
              {
                text: "Upload immunization records for multiple patients",
                icon: "cross-circle-fill",
              },
              {
                text: "Complete surveys for multiple patients",
                icon: "cross-circle-fill",
              },
              {
                text: "Book telehealth appointments and access appointment results for multiple patients",
                icon: "cross-circle-fill",
              },
            ]}
            large={true}
          />
          <div className="mb-6" />
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={() => setModalVisible(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
