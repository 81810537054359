import React from "react"

import { Button, FormLegend, Dialog } from "../../../../../../shared"

export const Confirmation = ({ closeModal }) => (
  <Dialog
    title="Result pending"
    footer={
      <>
        <Button variant="secondary" onClick={() => closeModal()}>
          Back to diagnostics
        </Button>
      </>
    }
  >
    <FormLegend>
      <p>
        Please take your PCR test package to your nearest the shipping center
        within 24 hrs.
      </p>
    </FormLegend>
  </Dialog>
)
