import React from "react"
import PropTypes from "prop-types"

import { usePCRTest } from "../.."
import { Button, FormLegend, Dialog } from "../../../../../../shared"

export const Confirmation = ({ closeModal }) => {
  const { testData } = usePCRTest()
  return (
    <Dialog
      title="Result saved"
      footer={
        <>
          <Button to={`/diagnostics/${testData.data.id}`}>View result</Button>
          <Button variant="secondary" onClick={() => closeModal()}>
            Back to diagnostics
          </Button>
        </>
      }
    >
      {testData.data.testResult &&
      testData.data.testResult.reading &&
      testData.data.testResult.reading.data ? (
        <FormLegend>
          You can now view your result and show your device to prove you
          recently tested {testData.data.testResult.reading.data.toUpperCase()}{" "}
          for COVID-19.
        </FormLegend>
      ) : (
        <FormLegend>
          You can now view your result and show your device to prove your
          current COVID infection status
        </FormLegend>
      )}
    </Dialog>
  )
}

Confirmation.propTypes = {
  closeModal: PropTypes.func.isRequired,
}
