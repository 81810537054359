import React from "react"
import { Link, useLocation } from "@reach/router"

interface Props {
  to: string
  icon: "thermometer" | "calendar" | "survey" | "vaccine"
  name: string
  variant?: "diagnostics"
}

export const SidebarItem = ({ to, icon, name, variant }: Props) => {
  const location = useLocation()

  const isPartiallyActive = ({
    isPartiallyCurrent,
  }: {
    isPartiallyCurrent: boolean
  }) => {
    return isPartiallyCurrent
      ? { className: "sidebar-item active" }
      : { className: "sidebar-item" }
  }

  const isActive = ({ isCurrent }: { isCurrent: boolean }) => {
    const isDiagnosticSubRoute = location.pathname.includes("/diagnostics/")
    return isCurrent || isDiagnosticSubRoute
      ? { className: "sidebar-item active" }
      : { className: "sidebar-item" }
  }

  return (
    <li>
      <Link
        getProps={variant === "diagnostics" ? isActive : isPartiallyActive}
        to={to}
      >
        <span className={`icon-${icon} sidebar-item-icon`} />
        <span className="sidebar-item-name">{name}</span>
      </Link>
    </li>
  )
}
