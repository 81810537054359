import React from "react"
import { useToasts } from "react-toast-notifications"

import { Helmet } from "react-helmet"
import { SEOTitleTemplate } from "../../../constants"
import { ButtonWithIcon, Query } from "../../shared"
import { ReportingGroup } from "./ReportingGroup"
import { useQuery } from "../../../hooks"
import { JoinReportingGroupModal } from "./JoinReportingGroupModal"
import { statusReportingGroup } from "../../../types"

export const StatusReporting = () => {
  const { addToast } = useToasts()
  const { data, setData, error, loading } = useQuery({
    url: "/status-reporting-groups/all-user-groups",
  })
  const [newReportingGroupModalVisible, setNewReportingGroupModalVisibility] =
    React.useState(false)
  return (
    <div>
      <Helmet title={SEOTitleTemplate({ title: "Status reporting" })} />
      <ButtonWithIcon
        iconPosition="left"
        icon="plus"
        onClick={() => setNewReportingGroupModalVisibility(true)}
      >
        Join new reporting group
      </ButtonWithIcon>
      {loading || error ? (
        <Query
          additionalClasses="reporting-group-loader"
          loading={loading}
          error={error}
          action="load reporting groups"
        />
      ) : (
        data?.map((reportingGroup: statusReportingGroup) => (
          <ReportingGroup
            addToast={addToast}
            key={reportingGroup.statusReportingGroup.id}
            id={reportingGroup.statusReportingGroup.id}
            code={reportingGroup.statusReportingGroup.code}
            name={reportingGroup.statusReportingGroup.name}
            definition={reportingGroup.statusReportingGroup}
            patient={reportingGroup.patient}
            liveReport={reportingGroup.liveReport}
            setReportingGroups={setData}
          />
        ))
      )}
      <JoinReportingGroupModal
        addToast={addToast}
        dismissFunc={() => setNewReportingGroupModalVisibility(false)}
        isVisible={newReportingGroupModalVisible}
        setReports={setData}
      />
    </div>
  )
}
