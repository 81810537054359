import React from "react"
import PropTypes from "prop-types"
import { useField } from "formik"
import { Label } from ".."

export const InputDateOfBirth = ({ label, name, placeholder }) => {
  const [field, meta, helpers] = useField(name)

  const handleChange = (event) => {
    const currentValue = event.target.value

    if (currentValue.length === 2 || currentValue.length === 5) {
      helpers.setValue(event.target.value + "/")
    } else {
      helpers.setValue(event.target.value)
    }
  }

  return (
    <div className={meta.touched && meta.error ? " form-error" : ""}>
      {label && <Label field={name}>{label}</Label>}
      <input
        className={`input input-date-of-birth${
          meta.touched && meta.error ? " input-error" : ""
        }`}
        type="text"
        name={name}
        id={name}
        placeholder={placeholder}
        {...field}
        onChange={handleChange}
      />
      <span className="input-error-text">{meta.touched ? meta.error : ""}</span>
    </div>
  )
}

InputDateOfBirth.defaultProps = {
  placeholder: "mm/dd/yyyy",
}

InputDateOfBirth.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
}
