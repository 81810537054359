import React from "react"
import { useToasts } from "react-toast-notifications"
import { Helmet } from "react-helmet"

import { NewPatientModal } from "./NewPatientModal"
import { Patient } from "./Patient"
import { useQuery, useAuth } from "../../../hooks"
import { SEOTitleTemplate } from "../../../constants"
import { checkFeaturePermission } from "../../../utilities"
import { AddNewButton } from "../AddNewButton"
import { Query } from "../../shared"
import { patientType } from "../../../types"

export const Patients = () => {
  const { addToast } = useToasts()
  const authHook = useAuth()
  const { data, setData, error, loading } = useQuery({
    url: "/patient/all",
  })

  const [newPatientModalVisible, setNewPatientModalVisibility] =
    React.useState(false)

  return (
    <>
      <Helmet title={SEOTitleTemplate({ title: "Your patients" })} />
      <section className="account-items">
        {checkFeaturePermission({
          planTier: authHook?.planTier,
          feature: "multiple-patients",
        }) && (
          <AddNewButton
            onClick={() => setNewPatientModalVisibility(true)}
            heading="New patient"
          />
        )}
        {loading || error ? (
          <Query
            additionalClasses="account-items-loader account-items-loader-patients"
            loading={loading}
            error={error}
            action="load patients"
          />
        ) : (
          data &&
          data.map((patient: patientType) => (
            <Patient
              accountHolder={patient.accountHolder}
              addToast={addToast}
              address={patient.address}
              dateOfBirth={patient.dateOfBirth}
              firstName={patient.firstName}
              gender={patient.gender}
              id={patient.id}
              key={patient.id}
              lastName={patient.lastName}
              phone={patient.phone}
              race={patient.race}
              relationshipToAccountHolder={patient?.relationshipToAccountHolder}
              setPatients={setData}
            />
          ))
        )}
      </section>
      {checkFeaturePermission({
        planTier: authHook?.planTier,
        feature: "multiple-patients",
      }) && (
        <NewPatientModal
          addToast={addToast}
          dismissFunc={() => setNewPatientModalVisibility(false)}
          isVisible={newPatientModalVisible}
          setPatients={setData}
        />
      )}
    </>
  )
}
