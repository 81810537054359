import React from "react"
import PropTypes from "prop-types"

export const FormSection = ({ columns, children, additionalClasses }) => (
  <section
    className={`form-section form-section-${columns}${
      additionalClasses ? ` ${additionalClasses}` : ""
    }`}
  >
    {children}
  </section>
)

FormSection.defaultProps = {
  columns: 2,
  additionalClasses: "",
}

FormSection.propTypes = {
  columns: PropTypes.number,
  children: PropTypes.node.isRequired,
  additionalClasses: PropTypes.string,
}
