import React from "react"
import { Link, useLocation } from "@reach/router"

import { SidebarItem } from "./SidebarItem"
import { IconButton } from "../../shared"

import { ReactComponent as GTTLogoLight } from "../../../assets/GotTheTest-logo-light.svg"

interface Props {
  mobileSidebarVisible: boolean
  closeSidebar: () => void
}

export const Sidebar = ({ mobileSidebarVisible, closeSidebar }: Props) => {
  const location = useLocation()

  React.useEffect(() => {
    closeSidebar()
    return () => {
      closeSidebar()
    }
  }, [location.pathname]) // eslint-disable-line

  return (
    <div className={`sidebar${mobileSidebarVisible ? " sidebar-active" : ""}`}>
      <Link to="/">
        <GTTLogoLight className="sidebar-logo" />
      </Link>
      <div className="sidebar-mobile-hide-button">
        <IconButton
          icon="arrow-left"
          onClick={closeSidebar}
          variant="sidebar"
        />
      </div>
      <ul className="sidebar-item-group">
        <SidebarItem
          to="/"
          name="Diagnostics"
          icon="thermometer"
          variant="diagnostics"
        />
        <SidebarItem to="/appointments" name="Appointments" icon="calendar" />
        <SidebarItem to="/immunizations" name="Immunizations" icon="vaccine" />
        <SidebarItem to="/surveys" name="Surveys" icon="survey" />
      </ul>
    </div>
  )
}
