import React from "react"
import { Helmet } from "react-helmet"
import { useToasts } from "react-toast-notifications"
import axios from "axios"

import { Query, Button, ResultFilters } from "../../shared"
import { SEOTitleTemplate } from "../../../constants"
import { handleAPIError } from "../../../utilities"
import { useSurveys } from "../../../hooks"
import { SurveysDropDown } from "./SurveysDropdown"
import { SurveyResponsesTable } from "./SurveyResponsesTable"
import { SurveyResponsesCards } from "./SurveyResponsesCards"
import { Survey } from "./Survey"
import { surveyType, filterStateType, surveyResponseType } from "../../../types"

export const SurveyResponses = () => {
  const { addToast } = useToasts()
  const { surveys, surveysError } = useSurveys()
  const [error, setError] = React.useState(null)
  const [data, setData] = React.useState<Array<surveyResponseType> | null>(null)
  const [loading, setLoading] = React.useState(true)

  const [newResponseModalVisible, setNewResponseModalVisibility] =
    React.useState(false)
  const [survey, setSurvey] = React.useState({
    id: "",
    name: "",
    description: "",
    outcomeCalculation: {
      outcomeIfFalse: "",
      outcomeIfTrue: "",
      type: "",
    },
    questions: [
      {
        dataType: "",
        id: "",
        label: "",
        type: "",
      },
    ],
  })
  // Filters
  const [touched, setTouched] = React.useState(false)
  const [mobileFiltersVisible, setMobileFiltersVisibility] =
    React.useState(false)
  const [filters, setFilters] = React.useState<filterStateType>({
    patient: {
      selected: {
        value: undefined,
        label: undefined,
      },
      all: [],
    },
    rangeStart: null,
    rangeEnd: null,
  })
  const [dateRangeTouched, setDateRangeTouched] = React.useState({
    before: false,
    after: false,
  })

  const populateNewReponseModal = (survey: surveyType) => {
    setSurvey(survey)
    setNewResponseModalVisibility(true)
  }

  React.useEffect(() => {
    if (error) {
      addToast(handleAPIError(error, "load survey reesponses"), {
        appearance: "error",
      })
    }
  }, [error]) // eslint-disable-line

  React.useEffect(() => {
    if (surveysError) {
      addToast(handleAPIError(surveysError, "load survey options"), {
        appearance: "error",
      })
    }
  }, [surveysError]) // eslint-disable-line

  React.useEffect(() => {
    const query = async () => {
      setLoading(true)
      setMobileFiltersVisibility(false)
      try {
        const response = await axios({
          method: "post",
          url: "/surveys/responses/all",
          data: {
            patientId: filters.patient.selected.value,
            afterTime: filters.rangeStart
              ? filters.rangeStart.toISOString()
              : "",
            beforeTime: filters.rangeEnd ? filters.rangeEnd.toISOString() : "",
          },
        })
        setData(response.data)
      } catch (error: any) {
        setError(error)
      }
      setLoading(false)
    }
    query()

    return () => {
      setError(null)
      setData(null)
      setLoading(false)
    }
  }, [filters.patient.selected, filters.rangeStart, filters.rangeEnd])

  return (
    <>
      <Helmet title={SEOTitleTemplate({ title: "Your survey responses" })} />
      <header className="list-page-header">
        <h1 className="heading-extra-large">Survey responses</h1>
        <SurveysDropDown
          surveys={surveys ? surveys : []}
          populateNewReponseModal={populateNewReponseModal}
        />
      </header>
      <ResultFilters
        mobileFiltersVisible={mobileFiltersVisible}
        setMobileFiltersVisibility={setMobileFiltersVisibility}
        filters={filters}
        setTouched={setTouched}
        setFilters={setFilters}
        setDateRangeTouched={setDateRangeTouched}
        dateRangeTouched={dateRangeTouched}
        variant="surveys"
      />
      {loading || error ? (
        <Query
          additionalClasses="list-page-loader"
          loading={loading}
          error={error}
          action="load survey responses"
        />
      ) : data && data.length > 0 ? (
        <>
          <SurveyResponsesCards data={data} />
          <SurveyResponsesTable data={data} />
        </>
      ) : touched ? (
        <div className="list-page-empty">
          <p className="list-page-empty-description">
            Sorry, there are no results matching your criteria
          </p>
          <Button
            onClick={() => {
              setFilters((filters: filterStateType) => ({
                patient: {
                  ...filters.patient,
                  selected: { value: undefined, label: undefined },
                },
                rangeStart: null,
                rangeEnd: null,
              }))
              setDateRangeTouched({
                before: false,
                after: false,
              })
            }}
            variant="secondary"
          >
            Clear all filters
          </Button>
        </div>
      ) : (
        <div className="list-page-empty">
          <p className="list-page-empty-description">
            You haven't responded to any surveys yet
          </p>
          <Button onClick={() => populateNewReponseModal(surveys[0])}>
            Complete your first survey response
          </Button>
        </div>
      )}
      <Survey
        surveyDefinition={survey}
        isVisible={newResponseModalVisible}
        dismissFunc={() => setNewResponseModalVisibility(false)}
        setSurveys={setData}
      />
    </>
  )
}
