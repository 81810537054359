import React from "react"
import { Router } from "@reach/router"

import { SurveyResponses } from "./SurveyResponses"
import { ViewSurveyResponse } from "./ViewSurveyResponse"

export const Surveys = () => (
  <Router>
    <SurveyResponses path="/" />
    <ViewSurveyResponse path="/:id" />
  </Router>
)
