export const addDateToTime = (sampledDate: string, sampledTime: Date) => {
  const sampledDateArr = sampledDate.split(":")

  return new Date(
    sampledTime.setHours(
      parseInt(sampledDateArr[0]),
      parseInt(sampledDateArr[1]),
      0,
      0
    )
  ).toISOString()
}
