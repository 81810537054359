import React from "react"
import { createClient } from "contentful"

const PlanContext = React.createContext()

export const usePlan = () => {
  return React.useContext(PlanContext)
}

export const ProvidePlan = ({ children }) => {
  const plan = useProvidePlan()
  return <PlanContext.Provider value={plan}>{children}</PlanContext.Provider>
}

const useProvidePlan = () => {
  const [plan, setPlan] = React.useState({})
  const [loading, setLoading] = React.useState(true)
  const [allPlans, setAllPlans] = React.useState([])
  const [allPermissions, setAllPermissions] = React.useState([])
  const [planSelected, setPlanSelected] = React.useState(false)

  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  })

  const formatPlanBenefits = (planData) => {
    let formattedPlanBenefits = []

    allPermissions.forEach((permission) => {
      if (
        planData?.fields?.permissions.some(
          (benefit) => benefit?.sys?.id === permission?.sys?.id
        )
      ) {
        formattedPlanBenefits.push({
          text: permission?.fields?.name,
          icon: "tick-circle-fill",
        })
      } else {
        formattedPlanBenefits.push({
          text: permission?.fields?.name,
          icon: "cross-circle-fill",
        })
      }
    })
    return formattedPlanBenefits
  }

  React.useEffect(() => {
    const getPlans = async () => {
      const response = await client.getEntries({
        content_type: "subscriptions",
      })
      const subscriptionsMaster = response?.items?.find(
        (item) => item?.fields?.name === "Subscriptions master"
      )

      setAllPlans(subscriptionsMaster?.fields?.plans)
      setAllPermissions(subscriptionsMaster?.fields?.permissions)
      setLoading(false)
    }
    getPlans()

    return () => {
      setAllPlans([])
      setAllPermissions([])
    }
  }, []) //eslint-disable-line

  const selectPlan = (plan) => {
    setPlan(plan)
    setPlanSelected(true)
  }

  const resetPlan = () => {
    setPlan({})
    setPlanSelected(false)
  }

  return {
    plan,
    allPlans,
    loading,
    allPermissions,
    planSelected,
    formatPlanBenefits,
    selectPlan,
    resetPlan,
  }
}
