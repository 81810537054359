import React from "react"
import { useTable, useSortBy } from "react-table"
import moment from "moment-timezone"
import { useNavigate } from "@reach/router"

import { formatImmunizationDoseAsText } from "../../../../utilities"
import { immunizationRecordType } from "../../../../types"

export const ImmunizationsTable = ({ data }: { data: any }) => {
  const navigate = useNavigate()
  const columns = React.useMemo(
    () => [
      {
        Header: "Immunization ID",
        accessor: "id", // accessor is the "key" in the data
        Cell: ({ value }: { value: string }) => (
          <span className="list-page-table-body-cell-text">{value}</span>
        ),
      },
      {
        Header: "Manufacturer",
        accessor: "vaccineManufacturer",
        Cell: ({ value }: { value: string }) => (
          <span className="list-page-table-body-cell-text list-page-table-body-cell-bold">
            {value}
          </span>
        ),
      },
      {
        Header: "Dose",
        accessor: "dose",
        Cell: ({ row }: { row: any }) => (
          <span className="list-page-table-body-cell-text">
            {formatImmunizationDoseAsText({
              immunizationRecord: data.find(
                (record: immunizationRecordType) => record.id === row.values.id
              ),
            })}
          </span>
        ),
      },
      {
        Header: "Patient",
        accessor: "patient.fullName",
        Cell: ({ value }: { value: string }) => (
          <span className="list-page-table-body-cell-text ">{value}</span>
        ),
      },
      {
        Header: "Date",
        accessor: "vaccinationDate",
        Cell: ({ value }: { value: Date }) => (
          <span className="list-page-table-body-cell-text ">{value}</span>
        ),
      },
      {
        Header: "Vaccine",
        accessor: "name",
        Cell: ({ value }: { value: string }) => (
          <span className="list-page-table-body-cell-text">{value}</span>
        ),
      },
      {
        Header: "Saved",
        accessor: "created",
        Cell: ({ value }: { value: Date }) => (
          <span className="list-page-table-body-cell-final-column">
            <span className="list-page-table-body-cell-text">
              {moment(value).format("MM/DD/YYYY hh:mm A")}
            </span>
            <span className="icon-chevron-right list-page-table-body-cell-final-column-icon" />
          </span>
        ),
      },
    ],
    [data]
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy)

  return (
    <table className="list-page-table" {...getTableProps()}>
      <thead className="list-page-table-header">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any, index) => (
              <th
                className={`list-page-table-header-cell${
                  column.isSorted ? " list-page-table-header-cell-sorted" : ""
                }${
                  index === 0 || index === 3
                    ? " list-page-table-header-cell-desktop"
                    : ""
                }`}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                <span className="list-page-table-header-cell-layout">
                  <span className="list-page-table-header-cell-text">
                    {column.render("Header")}
                  </span>
                  <span className="icon-sort list-page-table-header-cell-icon" />
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="list-page-table-body" {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)
          return (
            <tr
              className="list-page-table-body-row"
              {...row.getRowProps()}
              onClick={() => navigate(`/immunizations/${row.cells[0].value}`)}
            >
              {row.cells.map((cell, index) => {
                return (
                  <td
                    className={`list-page-table-body-cell${
                      index === 0 || index === 3
                        ? " list-page-table-body-cell-desktop"
                        : ""
                    }`}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
