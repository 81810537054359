export const convertFullNameToInitials = (fullName: string) => {
  const nameArray = fullName.split(" ")
  let firstInitial
  let lastInitial

  if (fullName) {
    if (nameArray.length > 0) {
      const firstName = nameArray[0]
      const lastName = nameArray[nameArray.length - 1]
      firstInitial = firstName.slice(0, 1).toUpperCase()
      lastInitial = lastName.slice(0, 1).toUpperCase()
    } else {
      firstInitial = fullName.slice(0, 1).toUpperCase()
    }
  } else {
    firstInitial = "?"
  }

  return lastInitial ? firstInitial + lastInitial : firstInitial
}
