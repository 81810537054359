import React from "react"
import ImageUploading from "react-images-uploading"
import PropTypes from "prop-types"

import { Button } from "../.."
import { UploadedFile } from ".."

export const ImageUploader = ({
  photos,
  updatePhotos,
  updatePdfs,
  illustration,
  variant,
  pdf,
  pdfs,
  removePdf,
}) => {
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    updatePhotos(imageList)
  }
  const inputFileRef = React.useRef(null)

  return (
    <>
      <div className="image-uploader-position">
        <ImageUploading
          value={photos}
          onChange={onChange}
          maxNumber={1}
          dataURLKey="data_url"
          acceptType={["jpg", "png", "gif"]}
          maxFileSize={5242880} // 5MB
        >
          {({
            imageList,
            onImageUpload,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            <div className={`image-uploader image-uploader-${variant}`}>
              {photos.length === 0 && (
                <section
                  className="image-uploader-button"
                  type="button"
                  style={isDragging ? { color: "red" } : null}
                  {...dragProps}
                >
                  {illustration && (
                    <div className="image-uploader-illustration">
                      {illustration}
                    </div>
                  )}
                  {pdfs.length === 0 && (
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() => onImageUpload()}
                    >
                      <span className="image-uploader-button-text">
                        <span className="image-uploader-desktop">
                          Click to upload photo
                        </span>
                        <span className="image-uploader-mobile">
                          Tap to take a photo
                        </span>
                      </span>
                    </Button>
                  )}
                  {pdf && (
                    <div className="image-uploader-pdf">
                      <input
                        type="file"
                        name="file"
                        onChange={updatePdfs}
                        accept="application/pdf"
                        className="image-uploader-pdf-input"
                        ref={inputFileRef}
                      />
                      <Button
                        variant="secondary"
                        type="button"
                        onClick={() => {
                          inputFileRef.current.click()
                        }}
                      >
                        <span className="image-uploader-button-text">
                          <span className="image-uploader-desktop">
                            {pdfs.length === 0
                              ? "Click to upload pdf"
                              : "Replace pdf"}
                          </span>
                          <span className="image-uploader-mobile">
                            {pdfs.length === 0
                              ? "Tap to upload a pdf"
                              : "Replace pdf"}
                          </span>
                        </span>
                      </Button>
                    </div>
                  )}
                  <span className="image-uploader-button-helper-text">
                    (Maximum file size: 5mb)
                  </span>
                </section>
              )}
              {imageList.map((image, index) => (
                <div key={index} className="image-uploader-photo">
                  <img src={image.data_url} alt="" width="112" />
                  <button
                    className="button button-secondary image-uploader-photo-replace-button"
                    onClick={() => onImageUpdate(index)}
                    type="button"
                  >
                    Replace
                  </button>
                  <button
                    className="button button-secondary image-uploader-photo-remove-button"
                    onClick={() => onImageRemove(index)}
                    type="button"
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
          )}
        </ImageUploading>
      </div>
      {pdfs.length > 0 && removePdf && (
        <div className="image-uploader-files">
          {pdfs.map((pdf) => (
            <UploadedFile
              key={`${pdf.name}${pdf.size}`}
              name={pdf.name}
              size={pdf.size}
              removeFunc={removePdf}
            />
          ))}
        </div>
      )}
    </>
  )
}

ImageUploader.defaultProps = {
  illustration: <></>,
  pdf: false,
  updatePdfs: () => {},
  pdfs: [],
  removePdf: () => {},
}

ImageUploader.propTypes = {
  illustration: PropTypes.node.isRequired,
  photos: PropTypes.array.isRequired,
  updatePhotos: PropTypes.func.isRequired,
  variant: PropTypes.string,
  pdf: PropTypes.bool,
  pdfs: PropTypes.array,
  updatePdfs: PropTypes.func,
  removePdf: PropTypes.func,
}
