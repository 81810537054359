import React from "react"

interface Props {
  children: React.ReactNode
  href: string
}

export const ButtonLink = ({ children, href = "" }: Props) => {
  return (
    <a href={href} target="_blank" rel="noreferrer" className="button-link">
      {children}
    </a>
  )
}
