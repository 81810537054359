import React from "react"
import PropTypes from "prop-types"
import { useToasts } from "react-toast-notifications"

import { handleAPIError } from "../../../utilities"
import { Loader } from ".."

export const Query = ({
  additionalClasses,
  loading,
  error,
  colour,
  action,
}) => {
  const { addToast } = useToasts()

  React.useEffect(() => {
    if (error && error.response && error.response.status) {
      if (error.response.status === 401) {
        addToast(`Could not ${action}, your login session has expired`, {
          appearance: "error",
        })
      }
    }
  }, [error]) // eslint-disable-line

  if (loading) {
    return (
      <div
        className={`query${additionalClasses ? ` ${additionalClasses}` : ""}`}
      >
        <Loader colour={colour} />
      </div>
    )
  } else if (error) {
    return (
      <div
        className={`query-error${
          additionalClasses ? ` ${additionalClasses}` : ""
        }`}
      >
        <span className="icon-alert-circle-fill query-error-icon" />
        <p className="query-error-message">{handleAPIError(error, action)}</p>
      </div>
    )
  } else {
    return null
  }
}

Query.defaultProps = {
  colour: "orange",
  additionalClasses: "",
}

Query.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  colour: PropTypes.oneOf(["orange", "grey"]),
  additionalClasses: PropTypes.string,
  action: PropTypes.string.isRequired,
}
