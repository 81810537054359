import React from "react"
import PropTypes from "prop-types"
import { useToasts } from "react-toast-notifications"

import { Modal } from "../../../shared"
import { Introduction } from "./Introduction"
import { Step1 } from "./Step1"
import { Step2 } from "./Step2"
import { NewVaccinationModalConfirmation } from "./NewVaccinationModalConfirmation"

const defaultVaccinationData = {
  step: 1,
  attestation: false,
  data: {
    created: "",
    diseaseTargeted: "",
    dose: "",
    id: "",
    medicalProvider: "",
    name: "",
    userId: "",
    vaccinationDate: "",
    vaccineManufacturer: "",
    vaccineManufacturerBatch: "",
    images: [],
  },
}

export const NewImmunizationModal = ({
  modalVisible,
  dismissFunc,
  setVaccinations,
}) => {
  const { addToast } = useToasts()
  const closeModal = () => {
    dismissFunc()
    setVaccinationData(defaultVaccinationData)
  }

  const [vaccinationData, setVaccinationData] = React.useState(
    defaultVaccinationData
  )

  return (
    <Modal
      title="New immunization"
      dismissFunc={closeModal}
      isVisible={modalVisible}
      header={vaccinationData.step !== 4}
      size={vaccinationData.step === 4 ? "medium" : "large"}
    >
      {vaccinationData.step === 1 ? (
        <Introduction setVaccinationData={setVaccinationData} />
      ) : vaccinationData.step === 2 ? (
        <Step1
          vaccinationData={vaccinationData}
          setVaccinationData={setVaccinationData}
          setVaccinations={setVaccinations}
          addToast={addToast}
        />
      ) : vaccinationData.step === 3 ? (
        <Step2
          vaccinationData={vaccinationData}
          setVaccinations={setVaccinations}
          setVaccinationData={setVaccinationData}
          addToast={addToast}
        />
      ) : vaccinationData.step === 4 ? (
        <NewVaccinationModalConfirmation
          vaccinationId={vaccinationData.data.id}
          closeModal={closeModal}
        />
      ) : null}
    </Modal>
  )
}

NewImmunizationModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  dismissFunc: PropTypes.func.isRequired,
  setVaccinations: PropTypes.func.isRequired,
}
