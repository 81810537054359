import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import "yup-phone"
import axios from "axios"

import {
  AddressFinder,
  Button,
  Form,
  FormSection,
  Input,
  InputSelect,
  InputDateOfBirth,
  Checkbox,
  Modal,
  ModalBody,
  ModalFooter,
  FormLegend,
} from "../../../shared"
import { genderOptions, raceOptions, dateRegex } from "../../../../constants"
import { handleAPIError } from "../../../../utilities"
import { patientFormType, patientType, toastType } from "../../../../types"

interface Props {
  dismissFunc: () => void
  isVisible: boolean
  addToast: toastType
  setPatients: (arg0: any) => void
}

export const NewPatientModal = ({
  dismissFunc,
  isVisible,
  addToast,
  setPatients,
}: Props) => {
  const [searchedAddress, setSearchedAddress] = React.useState()
  const [searchedAddressError, setSearchedAddressError] = React.useState("")
  const [manualAddressVisible, setManualAddressVisibility] =
    React.useState(false)

  const onSubmit = async (
    values: patientFormType,
    { setSubmitting }: { setSubmitting: any }
  ) => {
    if (values.address === "") {
      setSearchedAddressError("Please find or enter an address")
      setManualAddressVisibility(true)
    } else {
      try {
        const response = await axios({
          method: "post",
          url: "/patient/create",
          data: { patients: [values] },
        })
        addToast("Patient created", {
          appearance: "success",
        })
        const newPatient = response.data
        setPatients((oldPatients: Array<patientType>) => [
          newPatient[0],
          ...oldPatients,
        ])
        dismissFunc()
      } catch (error: any) {
        console.log(error, error.response)
        addToast(handleAPIError(error, "create patient"), {
          appearance: "error",
        })
        setSubmitting(false)
      }
    }
  }

  React.useEffect(() => {
    if (!isVisible) {
      setManualAddressVisibility(false)
    }
  }, [isVisible])

  return (
    <Modal dismissFunc={dismissFunc} isVisible={isVisible} title="New patient">
      <Formik
        initialValues={{
          accountHolder: false,
          relationshipToAccountHolder: "",
          firstName: "",
          lastName: "",
          dateOfBirth: "",
          gender: "",
          race: "",
          phone: "+1 ",
          address: "",
        }}
        validationSchema={Yup.object().shape({
          accountHolder: Yup.boolean().oneOf([false]),

          firstName: Yup.string().required(
            "Please enter the patient's first name"
          ),
          lastName: Yup.string().required(
            "Please enter the patient's last name"
          ),
          relationshipToAccountHolder: Yup.string().required(
            "Please enter the patient's relationship to you"
          ),
          dateOfBirth: Yup.string()
            .required("Please enter the patient's date of birth")
            .matches(dateRegex, "Please enter a date in the format mm/dd/yyyy"),
          gender: Yup.string().required("Please select your gender"),
          race: Yup.string().required("Please select your race"),
          phone: Yup.string()
            .phone(
              "US",
              true,
              "Please enter a US phone number including the country code '+1'"
            )
            .required("Please your phone number"),
          address: Yup.string(),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <ModalBody>
              <div className="not-visible">
                <FormLegend variant="formElements">
                  <Checkbox
                    name="accountHolder"
                    label="This is my information"
                  />
                </FormLegend>
              </div>
              <FormSection>
                <Input name="firstName" label="First name" />
                <Input name="lastName" label="Last name" />
                <InputDateOfBirth name="dateOfBirth" label="Date of birth" />
                <Input
                  name="relationshipToAccountHolder"
                  label="Relationship to you"
                />
                <InputSelect
                  name="gender"
                  label="Gender"
                  options={genderOptions}
                />
                <InputSelect name="race" label="Race" options={raceOptions} />
                <Input name="phone" label="Mobile" placeholder="+11234567890" />
              </FormSection>
              <FormSection>
                <AddressFinder
                  searchedAddress={searchedAddress}
                  onChange={(address) => {
                    setSearchedAddress(address)
                    setFieldValue(
                      "address",
                      address && address.label ? address.label : ""
                    )
                  }}
                  error={searchedAddressError}
                >
                  {manualAddressVisible ? (
                    ""
                  ) : (
                    <button
                      onClick={() => setManualAddressVisibility(true)}
                      className="label-link"
                    >
                      Enter manually
                    </button>
                  )}
                </AddressFinder>
                <div className={manualAddressVisible ? "" : "not-visible"}>
                  <Input
                    name="address"
                    label="Enter address"
                    placeholder="Full address"
                  />
                </div>
              </FormSection>
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Create new patient
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
