import React from "react"
import PropTypes from "prop-types"
import axios from "axios"

import {
  Button,
  ModalBody,
  FormLegend,
  ModalFooter,
  ModalImageContainer,
} from "../../../../../../shared"
import { handleAPIError } from "../../../../../../../utilities"
import { useSelfTest } from "../.."

export const Step3 = ({ addToast, images, inteliSwabImages }) => {
  const { testData, setTestData } = useSelfTest()
  const [loading, setLoading] = React.useState(false)

  const logSampleTime = async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "post",
        url: "/tests/update",
        data: {
          testId: testData.data.id,
          updates: {
            sampledTime: new Date().toISOString(),
          },
        },
      })
      const updatedTest = response.data

      setTestData((testData) => ({
        step: testData.step + 1,
        verifiedResult: false,
        data: { ...updatedTest },
      }))
      // setLoading not used to prevent 'memory leak'
    } catch (error) {
      addToast(handleAPIError(error, "log sample time"), {
        appearance: "error",
      })
      setLoading(false)
    }
  }
  return (
    <>
      <ModalBody>
        {testData?.data?.manufacturer === "Orasure Inteliswab" ? (
          <>
            <FormLegend>
              <p>
                Please remove your InteliSwab from the packaging and rotate in
                each nostril 15 times
              </p>
            </FormLegend>
            <ModalImageContainer
              images={inteliSwabImages}
              svg={true}
              wide={true}
            />
          </>
        ) : (
          <>
            <FormLegend>
              <p>
                Collect a sample according to your test manufacturer
                instructions
              </p>
            </FormLegend>
            <ModalImageContainer images={images} />
          </>
        )}
      </ModalBody>
      <ModalFooter
        backFunc={() =>
          setTestData((testData) => ({
            step: testData.step - 1,
            verifiedResult: false,
            data: { ...testData.data },
          }))
        }
      >
        <Button
          loading={loading}
          disabled={loading}
          onClick={() => logSampleTime()}
        >
          Create sample
        </Button>
      </ModalFooter>
    </>
  )
}

Step3.propTypes = {
  addToast: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  inteliSwabImages: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.object.isRequired,
      alt: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}
