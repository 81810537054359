import React from "react"
import PropTypes from "prop-types"
import { Link } from "@reach/router"

export const NavLink = ({ children, to, url, tabIndex }) => {
  if (to) {
    return (
      <li className="nav-link">
        <Link to={to}>{children}</Link>
      </li>
    )
  } else if (url) {
    return (
      <li className="nav-link">
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          tabIndex={tabIndex ? 0 : -1}
        >
          {children}
        </a>
      </li>
    )
  } else {
    return null
  }
}

NavLink.defaultProps = {
  to: "",
  url: "",
  tabIndex: true,
  closeAllDropdowns: null,
}

NavLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  url: PropTypes.string,
  tabIndex: PropTypes.bool,
  closeAllDropdowns: PropTypes.func,
}
