import React from "react"
import { Helmet } from "react-helmet"
import { Formik } from "formik"
import * as Yup from "yup"
import "yup-phone"
import axios from "axios"
import { useToasts } from "react-toast-notifications"
import { navigate } from "@reach/router"
import { getAuth, updateProfile } from "firebase/auth"
import { useQueryParam, StringParam } from "use-query-params"

import {
  dateRegex,
  genderOptions,
  raceOptions,
  SEOTitleTemplate,
  firebaseApp,
} from "../../../constants"
import {
  AddressFinder,
  Checkbox,
  Form,
  Input,
  InputDateOfBirth,
  InputSelect,
  Button,
} from "../../shared"
import { handleAPIError } from "../../../utilities"
import { useAuth, usePatients } from "../../../hooks"
import { patientFormType } from "../../../types"

export const CreateUserPatient = () => {
  const { addToast } = useToasts()
  const authHook = useAuth()
  const auth = getAuth(firebaseApp)
  const user = auth.currentUser
  const [firstName] = useQueryParam("firstName", StringParam)
  const [lastName] = useQueryParam("lastName", StringParam)
  const { rawPatients, error: patientsError, loading } = usePatients()

  const [searchedAddress, setSearchedAddress] = React.useState()
  const [searchedAddressError, setSearchedAddressError] = React.useState("")
  const [manualAddressVisible, setManualAddressVisibility] =
    React.useState(false)
  const onSubmit = async (
    values: patientFormType,
    { setSubmitting }: { setSubmitting: any }
  ) => {
    if (values.address === "") {
      setSearchedAddressError("Please find or enter an address")
      setManualAddressVisibility(true)
    } else {
      try {
        if (authHook?.updatingDummyPatient) {
          await axios({
            method: "post",
            url: "/patient/update",
            data: { patientId: rawPatients[0].id, updates: values },
          })
        } else {
          await axios({
            method: "post",
            url: "/patient/create",
            data: { patients: [values] },
          })
        }

        await updateDisplayName(values.firstName + " " + values.lastName)
        authHook?.setSignedUp(true)
        authHook?.setUpdatingDummyPatient(false)
        navigate("/")
      } catch (error) {
        addToast(handleAPIError(error, "create patient"), {
          appearance: "error",
        })
        setSubmitting(false)
      }
    }
  }

  const updateDisplayName = async (newName: string) => {
    if (authHook?.user) {
      try {
        if (user) {
          await updateProfile(user, {
            displayName: newName,
          })
        } else {
          addToast(
            "Could not update patient profile, user could not be found",
            {
              appearance: "error",
            }
          )
        }
      } catch (error) {
        addToast(handleAPIError(error, "update patient profile"), {
          appearance: "error",
        })
      }
    } else {
      addToast("Could not update patient profile, you are not signed in", {
        appearance: "error",
      })
    }
  }

  React.useEffect(() => {
    if (patientsError) {
      addToast(handleAPIError(patientsError, "get patient to update"), {
        appearance: "error",
      })
    }
  }, [patientsError]) // eslint-disable-line

  return (
    <>
      <Helmet title={SEOTitleTemplate({ title: "Your details" })} />
      <Formik
        initialValues={{
          accountHolder: true,
          firstName: firstName ? firstName : "",
          lastName: lastName ? lastName : "",
          dateOfBirth: "",
          gender: "",
          race: "",
          phone: "+1 ",
          address: "",
        }}
        validationSchema={Yup.object().shape({
          accountHolder: Yup.boolean().oneOf([true]),
          firstName: Yup.string().required("Please enter your first name"),
          lastName: Yup.string().required("Please enter your last name"),
          dateOfBirth: Yup.string()
            .required("Please enter your date of birth")
            .matches(dateRegex, "Please enter a date in the format mm/dd/yyyy"),
          gender: Yup.string().required("Please select your gender"),
          race: Yup.string().required("Please select your race"),
          phone: Yup.string()
            .phone(
              "US",
              true,
              "Please enter a US phone number including the country code '+1'"
            )
            .required("Please your phone number"),
          address: Yup.string(),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form variant="card">
            <h1 className="heading-large form-card-heading-central">
              Your details
            </h1>
            <p className="form-card-paragraph text-center">
              Almost done! To save records you first need to create yourself as
              a patient.
            </p>
            <div className="not-visible">
              <Checkbox name="accountHolder" label="This is my information" />
            </div>
            <Input name="firstName" label="First name" />
            <Input name="lastName" label="Last name" />
            <InputDateOfBirth name="dateOfBirth" label="Date of birth" />
            <div />
            <InputSelect name="gender" label="Gender" options={genderOptions} />
            <InputSelect name="race" label="Race" options={raceOptions} />
            <Input name="phone" label="Mobile" placeholder="+11234567890" />
            <AddressFinder
              searchedAddress={searchedAddress}
              onChange={(address) => {
                setSearchedAddress(address)
                setFieldValue(
                  "address",
                  address && address.label ? address.label : ""
                )
              }}
              error={searchedAddressError}
            >
              {manualAddressVisible ? (
                ""
              ) : (
                <button
                  onClick={() => setManualAddressVisibility(true)}
                  className="label-link"
                >
                  Enter manually
                </button>
              )}
            </AddressFinder>
            <div className={manualAddressVisible ? "" : "not-visible"}>
              <Input
                name="address"
                label="Enter address"
                placeholder="Your full address"
              />
            </div>
            <Button
              type="submit"
              disabled={isSubmitting || loading}
              loading={isSubmitting}
              formCard={true}
            >
              Start using GotTheTest
            </Button>
            <Button
              type="button"
              onClick={() => authHook?.signOut()}
              variant="secondary"
              formCard={true}
            >
              Log out
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}
