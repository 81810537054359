import { StyleSheet, Font } from "@react-pdf/renderer"

import HelveticaRegular from "../../../../assets/fonts/Helvetica-Regular.ttf"
import HelveticaBold from "../../../../assets/fonts/Helvetica-Bold.ttf"

Font.register({
  family: "Helvetica",
  src: HelveticaRegular,
})

Font.register({
  family: "Helvetica-Bold",
  src: HelveticaBold,
})

const font = {
  fontSize: 8,
  lineHeight: 1, // Relvative ratio, can't use px
}

const tabelCellPadding = {
  paddingHorizontal: 6,
  paddingVertical: 5,
}

const tableCell = {
  borderStyle: "solid",
  borderWidth: 1,
  borderLeftWidth: 0,
  borderTopWidth: 0,
  ...tabelCellPadding,
}

export const PDFStyles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    backgroundColor: "white",
    color: "black",
    paddingHorizontal: 25,
    paddingVertical: 20,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
  },
  headerLogo: {
    width: 96,
    height: 26,
    display: "block",
    marginRight: 20,
  },
  headerCompanyInfo: {
    ...font,
    width: 140,
    height: 30,
  },
  headerTestID: {
    ...font,
    height: 10,
  },
  headerTestIDBold: {
    ...font,
    fontFamily: "Helvetica-Bold",
    height: 10,
  },
  font: {
    ...font,
  },
  fontCentered: {
    ...font,
    textAlign: "center",
  },
  table: {
    marginTop: 20,
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  tableColumn50: {
    width: "50%",
  },
  tableHeaderCell: {
    ...tabelCellPadding,
    backgroundColor: "black",
    width: "100%",
  },
  tableHeaderCellLabel: {
    ...font,
    fontFamily: "Helvetica-Bold",
    textTransform: "uppercase",
    color: "white",
  },
  tableCell100: {
    ...tableCell,
    width: "100%",
  },
  tableCell50: {
    ...tableCell,
    width: "50%",
  },
  tableCell25: {
    ...tableCell,
    width: "25%",
  },
  tableCell17: {
    ...tableCell,
    width: "17%",
  },
  tableCell16: {
    ...tableCell,
    width: "16%",
  },
  tableCellLabel: {
    fontFamily: "Helvetica-Bold",
    fontSize: 7,
  },
  tableResult2Cell: {
    ...tableCell,
    height: 54,
    paddingTop: 15,
    width: "100%",
  },
  tableResultText: {
    width: "100%",
    fontSize: 12,
    textAlign: "center",
    textTransform: "uppercase",
    marginBottom: 2,
  },
  resultTypes: {
    marginTop: 20,
  },
  resultType: {
    marginBottom: 12,
  },
  resultTypeHeading: {
    ...font,
    textTransform: "uppercase",
    marginBottom: 6,
  },
})
