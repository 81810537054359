import React from "react"
import { Link } from "@reach/router"

interface Props {
  title: string
  dateTime: Date
  data: string
  icon: "tick-circle-fill" | "alert-circle-fill" | "clock"
  link: string
}

export const ListCard = ({ title, dateTime, data, icon, link }: Props) => (
  <Link className="list-card" to={link}>
    <div className="list-card-result">
      {icon ? (
        <span className={`icon-${icon} list-card-result-icon`} />
      ) : (
        <span />
      )}
    </div>
    <div>
      <h2 className="list-card-data list-card-data-bold">{title}</h2>
      <span className="list-card-data">{data}</span>
      <span className="list-card-data">{dateTime}</span>
    </div>
  </Link>
)
