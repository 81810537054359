import React from "react"
import PropTypes from "prop-types"
import GooglePlacesAutocomplete from "react-google-places-autocomplete"
import { useToasts } from "react-toast-notifications"

export const AddressFinder = ({
  label,
  searchedAddress,
  onChange,
  children,
  error,
}) => {
  const { addToast } = useToasts()
  return (
    <div className={error ? "form-error" : ""}>
      <span className="label">
        <span className="label-text">{label}</span>
        {children}
      </span>
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        onLoadFailed={() =>
          addToast(`Could not connect to Google Maps Address finder`, {
            appearance: "error",
          })
        }
        selectProps={{
          searchedAddress,
          onChange: onChange,
          placeholder: "Start typing address",
          className: "input-select",
          classNamePrefix: "react-select",
        }}
      />
      <span className="input-error-text">{error ? error : ""}</span>
    </div>
  )
}
AddressFinder.defaultProps = {
  searchedAddress: undefined,
  label: "Find address",
  children: "",
  error: "",
}

AddressFinder.propTypes = {
  labe: PropTypes.string,
  searchedAddress: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  error: PropTypes.string,
}
