import React from "react"
import { Router } from "@reach/router"

import { ChangeEmail } from "./ChangeEmail"
import { ChangePassword } from "./ChangePassword"
import { StatusReporting } from "./StatusReporting"
import { Patients } from "./Patients"
import { ManageSubscription } from "./ManageSubscription"

import { Tabs } from "../shared"

export const Accounts = () => (
  <>
    <header className="account-page-header">
      <h1 className="heading-extra-large">Settings</h1>
    </header>
    <Tabs
      tabs={[
        {
          name: "Your patients",
          url: "patients",
        },
        {
          name: "Status reporting",
          url: "status-reporting",
        },
        {
          name: "Manage subscription",
          url: "manage-subscription",
        },
        {
          name: "Change email",
          url: "change-email",
        },
        {
          name: "Change password",
          url: "change-password",
        },
      ]}
    />
    <Router>
      <Patients path="/patients" />
      <StatusReporting path="/status-reporting" />
      <ManageSubscription path="/manage-subscription" />
      <ChangeEmail path="/change-email" />
      <ChangePassword path="/change-password" />
    </Router>
  </>
)
