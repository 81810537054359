import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import { useToasts } from "react-toast-notifications"
import { Helmet } from "react-helmet"
import { useQueryParam, StringParam } from "use-query-params"
import { getAuth, updatePassword } from "firebase/auth"
import { navigate } from "@reach/router"

import {
  Form,
  InputPassword,
  Button,
  Checkbox,
  ButtonLink,
  Query,
} from "../../shared"
import { useAuth } from "../../../hooks"
import { SEOTitleTemplate, firebaseApp } from "../../../constants"
import { replaceSpaceWithPlusQueryParams } from "../../../utilities"

type NewPasswordFormValues = {
  password: string
  terms: boolean
}

export const NewAccount = () => {
  const { addToast } = useToasts()
  const authHook = useAuth()
  const [email] = useQueryParam("email", StringParam)
  const [password] = useQueryParam("password", StringParam)
  const [firstName] = useQueryParam("firstName", StringParam)
  const [lastName] = useQueryParam("lastName", StringParam)
  const auth: any = getAuth(firebaseApp)
  const user = auth.currentUser

  const [noAuth, setNoAuth] = React.useState<{
    loading: boolean
    auth: boolean
    error: { message: string } | undefined
  }>({
    loading: true,
    auth: false,
    error: undefined,
  })
  const [loading, setLoading] = React.useState(false)
  const onSubmit = async (
    values: NewPasswordFormValues,
    { setSubmitting }: { setSubmitting: any }
  ) => {
    try {
      await updatePassword(user, values.password)
      setLoading(false)
      authHook?.setUpdatingDummyPatient(true)
      authHook?.setCreatingAccount(true)
      authHook?.setSignedUp(false)

      navigate(
        `/create-account/create-patient?firstName=${firstName}&lastName=${lastName}`
      )
    } catch (error: any) {
      addToast(
        "Could not update password, please refresh the page and try again",
        {
          appearance: "error",
        }
      )
      setSubmitting(false)
    }
  }

  React.useEffect(() => {
    const logUserIn = async () => {
      if (email && password) {
        try {
          const emailParsed = replaceSpaceWithPlusQueryParams(email)

          await authHook?.signIn({
            email: emailParsed,
            password: password,
            errorFunc: () => {
              setNoAuth({
                loading: false,
                auth: false,
                error: { message: "password has already been changed" },
              })
            },
          })

          setNoAuth((cur) => ({
            ...cur,
            loading: false,
            auth: true,
          }))
        } catch (error) {
          setNoAuth({
            loading: false,
            auth: false,
            error: { message: "password has already been changed" },
          })
        }
      } else {
        setNoAuth({
          loading: false,
          auth: false,
          error: { message: "password could not be found" },
        })
      }
    }
    if (noAuth.loading) {
      logUserIn()
    }
  }, [email, password]) // eslint-disable-line

  return (
    <>
      <Helmet title={SEOTitleTemplate({ title: "Create a password" })} />
      <Formik
        initialValues={{
          password: "",
          terms: false,
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string().required("Please enter a password"),
          terms: Yup.boolean().oneOf(
            [true],
            "Please accept the GotTheTest terms of use before creating your account"
          ),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form variant="card">
            {noAuth.loading || noAuth.error ? (
              <Query
                loading={noAuth.loading}
                error={noAuth.error}
                action="find user account"
              />
            ) : (
              <>
                <h1 className="heading-large form-card-heading-central">
                  Create password
                </h1>
                <p className="form-card-paragraph text-center">
                  You’ve been invited to use GotTheTest. Please set up your
                  account by creating a password using the form below.
                </p>
                <InputPassword label="Password" name="password" />
                <Checkbox name="terms" label="I agree to the GotTheTest">
                  <ButtonLink href="https://www.gotthetest.com/legal-pages/terms-of-use/">
                    terms &amp; conditions
                  </ButtonLink>
                </Checkbox>
                <Button
                  type="submit"
                  disabled={isSubmitting || loading}
                  loading={isSubmitting || loading}
                  formCard={true}
                >
                  Create password
                </Button>
                <Button
                  type="button"
                  onClick={() => authHook?.signOut()}
                  variant="secondary"
                  formCard={true}
                >
                  Log out
                </Button>
              </>
            )}
          </Form>
        )}
      </Formik>
    </>
  )
}
