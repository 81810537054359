import React from "react"
import PropTypes from "prop-types"

import { Label } from "../.."

export const InputSelectResult = ({ label, children }) => (
  <div className="input-select-result">
    <Label>{label}</Label>
    <span className="input-select-result-value">{children}</span>
  </div>
)

InputSelectResult.defaultProps = {
  label: "",
}

InputSelectResult.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
}
