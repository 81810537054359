import React from "react"
import { Helmet } from "react-helmet"

import { SEOTitleTemplate } from "../../../../constants"
import {
  Modal,
  ModalFooter,
  Button,
  ButtonList,
  ModalBody,
} from "../../../shared"
import { ReactComponent as BetaIllustration } from "../../../../assets/appointments-new.svg"

interface Props {
  isVisible: boolean
  closeModal: any
}

export const BetaModal = ({ isVisible, closeModal }: Props) => {
  return (
    <Modal
      dismissFunc={closeModal}
      isVisible={isVisible}
      size="medium"
      header={false}
    >
      <Helmet
        title={SEOTitleTemplate({
          title: "Beta Feature",
        })}
      />
      <ModalBody>
        <div className="modal-body-illustration">
          <BetaIllustration />
        </div>
        <h2 className="heading-small text-center mb-2">Feature Coming Soon</h2>
        <p className="text-center mb-3">
          At GotTheTest we're working hard to constantly bring you new features.
        </p>
        <p className="text-center mb-3">
          We're still working on the appointments feature but we'd love your
          feedback on other things you'd like to see in your patient portal.
        </p>
        <p className="text-center mb-3">
          Contact us by clicking the button below to have your say.
        </p>
      </ModalBody>
      <ModalFooter>
        <ButtonList>
          <Button href="https://hipaa.jotform.com/220265104901039">
            Contact us
          </Button>
          <Button variant="secondary" to="/">
            Back to diagnostics
          </Button>
        </ButtonList>
      </ModalFooter>
    </Modal>
  )
}
