import React from "react"

import { Nav } from "./Nav"
import { Sidebar } from "./Sidebar"

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const [mobileSidebarVisible, setMobileSidebarVisibility] =
    React.useState(false)

  React.useEffect(() => {
    // Locking body scroll on most devices when mobile nav open
    const bodyElement = document.body
    if (bodyElement) {
      if (mobileSidebarVisible) {
        bodyElement.style.overflow = "hidden"
      } else {
        bodyElement.style.overflow = "visible"
      }
    }
  }, [mobileSidebarVisible])
  return (
    <>
      <Sidebar
        closeSidebar={() => setMobileSidebarVisibility(false)}
        mobileSidebarVisible={mobileSidebarVisible}
      />
      <Nav openSidebar={() => setMobileSidebarVisibility(true)} />
      <main className="layout">{children}</main>
    </>
  )
}
