import React from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { Link } from "@reach/router"

import {
  Form,
  InputSelect,
  Button,
  FormLegend,
  FormSection,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
} from "../../../shared"
import {
  handleAPIError,
  updateLatestStatusReportingGroup,
  checkFeaturePermission,
} from "../../../../utilities"
import { usePatients, useAuth } from "../../../../hooks"
import {
  patientType,
  statusReportingGroupAccessRight,
  statusReportingGroupReport,
  toastType,
} from "../../../../types"

interface Props {
  addToast: toastType
  dismissFunc: () => void
  isVisible: boolean
  setReports: Function
}

interface JoinReportingGroupValues {
  patientId: string
  code: string
}

export const JoinReportingGroupModal = ({
  addToast,
  dismissFunc,
  isVisible,
  setReports,
}: Props) => {
  const auth = useAuth()
  const { formattedPatients, rawPatients, error, loading } = usePatients()
  const authHook = useAuth()

  const [hasCheckedAccessRights, setHasCheckedAccessRights] =
    React.useState(false)
  const [reportingCodeDefinition, setReportingCodeDefinition] =
    React.useState<any>({
      patient: {
        id: "",
        fullName: "",
      },
      company: {
        id: "",
        name: "",
      },
      statusReportingGroup: {
        id: "",
        name: "",
        accessRights: [
          {
            description: "",
            key: "",
            model: "",
          },
        ],
      },
    })

  const checkCodeAccessRights = async (
    values: JoinReportingGroupValues,
    { setSubmitting }: { setSubmitting: (arg0: boolean) => void }
  ) => {
    try {
      if (
        !checkFeaturePermission({
          planTier: authHook?.planTier,
          feature: "multiple-patients",
        })
      ) {
        values.patientId = rawPatients.find(
          (patient: patientType) => patient.accountHolder
        )?.id
      }
      const response = await axios({
        method: "post",
        url: "/status-reporting-groups/get-permissions",
        data: values,
      })

      setReportingCodeDefinition(response.data)
      setHasCheckedAccessRights(true)
    } catch (error) {
      addToast(handleAPIError(error, "check reporting group access rights"), {
        appearance: "error",
      })
    }

    setSubmitting(false)
  }

  const joinReportingGroup = async (
    values: JoinReportingGroupValues,
    { setSubmitting }: { setSubmitting: (arg0: boolean) => void }
  ) => {
    try {
      if (
        !checkFeaturePermission({
          planTier: authHook?.planTier,
          feature: "multiple-patients",
        })
      ) {
        values.patientId = rawPatients.find(
          (patient: patientType) => patient.accountHolder
        )?.id
      }

      const response = await axios({
        method: "post",
        url: "/status-reporting-groups/join-group",
        data: values,
      })
      const newReportingGroup = response.data
      setHasCheckedAccessRights(false)
      setReports((oldReportingGroups: Array<statusReportingGroupReport>) => [
        ...oldReportingGroups,
        newReportingGroup,
      ])
      updateLatestStatusReportingGroup({
        userId: auth?.user.uid,
        addToast: addToast,
        setFitForWorkReport: authHook?.setFitForWorkReport,
      })
      dismissFunc()
    } catch (error) {
      addToast(handleAPIError(error, "join reporting group"), {
        appearance: "error",
      })
      setSubmitting(false)
    }
  }

  React.useEffect(() => {
    if (error) {
      addToast(handleAPIError(error, "load patients"), {
        appearance: "error",
      })
    }
  }, [error]) // eslint-disable-line

  return (
    <Modal
      dismissFunc={dismissFunc}
      isVisible={isVisible}
      title="Join new reporting group"
    >
      <Formik
        initialValues={{
          patientId: "",
          code: "",
        }}
        validationSchema={Yup.object().shape({
          patientId: checkFeaturePermission({
            planTier: authHook?.planTier,
            feature: "multiple-patients",
          })
            ? Yup.string().required("Please choose a patient")
            : Yup.string(),
          code: Yup.string().required(
            "Please enter a reporting group code to join"
          ),
        })}
        onSubmit={
          hasCheckedAccessRights ? joinReportingGroup : checkCodeAccessRights
        }
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalBody>
              <FormLegend>
                <p>
                  Reporting groups use your GotTheTest account data to report a
                  status.
                </p>
                <p>
                  Enter a reporting group code to review the data a reporting
                  group owner can access.
                </p>
              </FormLegend>
              <FormSection>
                {checkFeaturePermission({
                  planTier: authHook?.planTier,
                  feature: "multiple-patients",
                }) && (
                  <InputSelect
                    loading={loading}
                    label="Patient"
                    name="patientId"
                    options={formattedPatients}
                  >
                    <Link className="label-link" to="/account/patients">
                      New patient
                    </Link>
                  </InputSelect>
                )}
                <Input name="code" label="Reporting group code" />
              </FormSection>
              {hasCheckedAccessRights && (
                <FormLegend>
                  <p>
                    {reportingCodeDefinition.company.name} will be able to
                    access:
                  </p>
                  <ul className="list">
                    {reportingCodeDefinition.statusReportingGroup.accessRights.map(
                      (accessRight: statusReportingGroupAccessRight) => (
                        <li key={`${accessRight.model}.${accessRight.key}`}>
                          {reportingCodeDefinition.patient.accountHolder
                            ? "Your "
                            : `${reportingCodeDefinition.patient.fullName}'s`}{" "}
                          {accessRight.description}
                        </li>
                      )
                    )}
                  </ul>
                  <p>
                    Click &#39;Join reporting group&#39; if you’re happy to
                    continue
                  </p>
                </FormLegend>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                {hasCheckedAccessRights
                  ? "Join reporting group"
                  : "Check reporting group code"}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
