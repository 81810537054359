import React from "react"
import PropTypes from "prop-types"
import { Formik } from "formik"
import * as Yup from "yup"
import { Link } from "@reach/router"
import axios from "axios"

import { useSelfTest } from "../.."
import { usePatients } from "../../../../../../../hooks"
import { handleAPIError, ascendIsOpen } from "../../../../../../../utilities"
import {
  Button,
  ModalFooter,
  ModalBody,
  FormLegend,
  Form,
  FormSection,
  InputSelect,
} from "../../../../../../shared"

export const VerifiedStep1 = ({ addToast }) => {
  const { formattedPatients, error, loading } = usePatients()
  const { setTestData } = useSelfTest()
  const [ascendOpen, setAscendOpen] = React.useState(false)
  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    if (!ascendOpen) {
      addToast(
        "Sorry, our On-Demand video verification service is only available 7am - 7pm (Eastern Time)",
        {
          appearance: "error",
        }
      )
      return
    }

    try {
      const response = await axios({
        method: "post",
        url: "/appointment/on-demand",
        data: { patientId: values.patient, _expand: ["patient"] },
      })

      setTestData((testData) => ({
        step: testData.step + 1,
        verifiedResult: true,
        data: { ...testData.data, appointment: { ...response.data } },
      }))
    } catch (error) {
      addToast(handleAPIError(error, "create on demand appointment"), {
        appearance: "error",
      })
      setSubmitting(false)
    }
  }

  React.useEffect(() => {
    if (error) {
      addToast(handleAPIError(error, "load patient options"), {
        appearance: "error",
      })
    }
  }, [error]) // eslint-disable-line

  React.useEffect(() => {
    setAscendOpen(ascendIsOpen())
    return () => {
      setAscendOpen(false)
    }
  }, [setAscendOpen])

  return (
    <Formik
      initialValues={{
        patient: "",
      }}
      validationSchema={Yup.object().shape({
        patient: Yup.string().required("Please choose a patient"),
      })}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <ModalBody>
            <FormLegend>
              <p>
                You’ve chosen to verify your result via On-Demand video call.
                This service is available 7am - 7pm (Eastern Time)
              </p>
              <p>
                Testing for travel?{" "}
                <a
                  href="https://testfortravel.com/covid19-country-travel-requirements/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Check current international requirements
                </a>
              </p>
            </FormLegend>
            <FormSection>
              <InputSelect
                loading={loading}
                label="Patient"
                name="patient"
                options={formattedPatients}
              >
                <Link className="label-link" to="/account/patients">
                  New patient
                </Link>
              </InputSelect>
            </FormSection>
          </ModalBody>
          <ModalFooter
            backFunc={() =>
              setTestData((testData) => ({
                step: testData.step - 1,
                verifiedResult: false,
                data: { ...testData.data },
              }))
            }
          >
            <Button
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Create video appointment
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}

VerifiedStep1.propTypes = {
  addToast: PropTypes.func.isRequired,
}
