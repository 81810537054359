import React from "react"
import PropTypes from "prop-types"

import { ListCard } from "../../../shared"
import { formatImmunizationDoseAsText } from "../../../../utilities"
import { immunizationRecordType } from "../../../../types"

export const ImmunizationsCards = ({
  data,
}: {
  data: Array<immunizationRecordType>
}) => (
  <div className="list-cards">
    {data.map((vaccination) => (
      <ListCard
        key={vaccination.id}
        title={`${
          vaccination.vaccineManufacturer
        } (${formatImmunizationDoseAsText({
          immunizationRecord: vaccination,
        })})`}
        dateTime={vaccination.vaccinationDate}
        data={vaccination.patient && vaccination.patient.fullName}
        link={`/immunizations/${vaccination.id}`}
        icon="tick-circle-fill"
      />
    ))}
  </div>
)

ImmunizationsCards.propTypes = {
  data: PropTypes.array.isRequired,
}
