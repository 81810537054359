import React from "react"
import PropTypes from "prop-types"

export const Loader = ({ colour }) => (
  <span className={`loader loader-${colour}`} />
)

Loader.defaultProps = {
  colour: "orange",
}

Loader.propTypes = {
  colour: PropTypes.oneOf(["orange", "grey"]),
}
