import axios from "axios"

import { handleAPIError } from "../.."
import { couponStateType, toastType } from "../../../types"

interface Props {
  coupon: couponStateType
  addToast: toastType
  setCoupon: (arg1?: any) => void
}

export const checkCoupon = async ({ coupon, addToast, setCoupon }: Props) => {
  if (coupon.value !== "") {
    setCoupon((coupon: couponStateType) => ({
      ...coupon,
      loading: true,
    }))
    try {
      const response = await axios({
        method: "post",
        url: "/stripe/check-coupon",
        data: { coupon: coupon.value },
      })
      setCoupon((coupon: couponStateType) => ({
        ...coupon,
        loading: false,
        succeeded: true,
        id: response.data.id,
        amount_off: response.data.amount_off,
        percent_off: response.data.percent_off,
      }))
    } catch (error: any) {
      if (error.response.status === 404) {
        setCoupon((coupon: couponStateType) => ({
          ...coupon,
          error: "Sorry, we don't recognise that coupon",
          loading: false,
        }))
      } else {
        addToast(handleAPIError(error, "apply coupon"), {
          appearance: "error",
        })
        setCoupon((coupon: couponStateType) => ({
          ...coupon,
          loading: false,
        }))
      }
    }
  } else {
    setCoupon((coupon: couponStateType) => ({
      ...coupon,
      error: "Please enter a coupon code to apply, or 'Cancel'",
    }))
  }
}
