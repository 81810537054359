import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useToasts } from "react-toast-notifications"

import { Modal } from "../../../../shared"
import { SEOTitleTemplate } from "../../../../../constants"
import { NeedsResult } from "./NeedsResult"
import { checkFeaturePermission } from "../../../../../utilities"
import { useAuth } from "../../../../../hooks"
import { DiagnosticsUpgradeHint } from "../../DiagnosticsUpgradeHint"

const PCRTestContext = React.createContext()

export const usePCRTest = () => {
  return React.useContext(PCRTestContext)
}

const defaultState = {
  step: 1,
  hasResult: false,
  data: {
    patient: "",
    manufacturer: "",
    sampleType: "",
    id: "",
    sampledTime: "",
    cliaLabName: "",
    cliaLabNumber: "",
    orderingPhysician: "",
    npi: "",
  },
}

const useProvidePCRTest = () => {
  const [testData, setTestData] = React.useState(defaultState)
  const { addToast } = useToasts()

  const resetTestData = () => {
    setTestData(defaultState)
  }
  return { testData, setTestData, resetTestData, addToast }
}

export const PCRTestModal = ({ modalVisible, dismissFunc, setTests }) => {
  const [confirmation, setConfirmation] = React.useState(false)
  const { planTier } = useAuth()
  const testData = useProvidePCRTest()
  const closeModal = () => {
    dismissFunc()
    testData.resetTestData()
  }

  React.useEffect(() => {
    if (
      (testData.testData.step !== 5 && !testData.testData.hasResult) ||
      (testData.testData.step !== 4 && testData.testData.hasResult)
    ) {
      setConfirmation(true)
    } else {
      setConfirmation(false)
    }
    return () => {
      setConfirmation(false)
    }
  }, [testData.testData.step, testData.testData.hasResult])

  return (
    <PCRTestContext.Provider value={testData}>
      <Modal
        isVisible={modalVisible}
        dismissFunc={closeModal}
        title="New PCR Test"
        header={confirmation}
        size={confirmation ? "large" : "medium"}
      >
        <Helmet title={SEOTitleTemplate({ title: "New PCR Test" })} />
        {checkFeaturePermission({
          planTier: planTier,
          feature: "diagnostics-results",
        }) ? (
          <NeedsResult
            step={testData.testData.step}
            closeModal={closeModal}
            setTests={setTests}
          />
        ) : (
          <DiagnosticsUpgradeHint variant="Completing PCR self-tests" />
        )}
      </Modal>
    </PCRTestContext.Provider>
  )
}

PCRTestModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  dismissFunc: PropTypes.func.isRequired,
  setTests: PropTypes.func.isRequired,
}
