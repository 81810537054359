import React from "react"

import { ChoosePlan, IconButton } from "../../../shared"

interface Props {
  isVisible: boolean
  dismissFunc: () => void
}

export const ChoosePlanModal = ({ isVisible, dismissFunc }: Props) => {
  React.useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "visible"
    }

    return () => {
      document.body.style.overflow = "visible"
    }
  }, [isVisible])

  if (isVisible) {
    return (
      <div className="choose-plan-modal">
        <header className="choose-plan-modal-header">
          <IconButton icon="cross" onClick={() => dismissFunc()} />
        </header>
        <div className="wrapper-width">
          <ChoosePlan variant="sign-up" />
        </div>
      </div>
    )
  } else {
    return null
  }
}
