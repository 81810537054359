import ReactDOM from "react-dom"
import { ToastProvider } from "react-toast-notifications"
import axios from "axios"
import { LocationProvider, globalHistory } from "@reach/router"
import { QueryParamProvider } from "use-query-params"

import App from "./App"
import { ProvideAuth } from "./hooks"

axios.defaults.baseURL = process.env.REACT_APP_API_BASE

ReactDOM.render(
  <LocationProvider>
    <QueryParamProvider {...{ path: "/" }} reachHistory={globalHistory}>
      <ToastProvider
        placement="bottom-center"
        autoDismiss
        autoDismissTimeout={5000}
      >
        <ProvideAuth>
          <App />
        </ProvideAuth>
      </ToastProvider>
    </QueryParamProvider>
  </LocationProvider>,
  document.getElementById("root")
)
