import React from "react"
import { Helmet } from "react-helmet"
import { useToasts } from "react-toast-notifications"
import axios from "axios"
import { createClient } from "contentful"

import { SEOTitleTemplate } from "../../../constants"
import {
  Query,
  ButtonList,
  Button,
  WarningModal,
  PlanHeader,
} from "../../shared"
import { useAuth } from "../../../hooks"
import {
  handleAPIError,
  getFreePlanMissedFeatures,
  premiumPlanId,
  familyPlanId,
  freePlanId,
} from "../../../utilities"
import { planType } from "../../../types"

export const ManageSubscription = () => {
  const authHook = useAuth()
  const planTier = authHook?.planTier
  const { addToast } = useToasts()
  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  })
  const [downgradeModalVisible, setDowngradeModalVisible] =
    React.useState(false)
  const [downgradeLoading, setDowngradeLoading] = React.useState(false)
  const [allPlans, setAllPlans] = React.useState([])
  const [plan, setPlan] = React.useState({
    fields: {
      cost: 0,
      description: "",
      mostPopular: false,
      name: "",
      permissions: [],
    },
    metadata: {
      tags: [],
    },
    sys: {
      id: "",
    },
  })
  const [freePlanMissedFeatures, setFreePlanMissedFeatures] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()

  const downgradeToFreePlan = async () => {
    setDowngradeLoading(true)
    try {
      await axios({
        method: "post",
        url: "/stripe/remove-subscription",
      })
      setDowngradeModalVisible(false)
      authHook?.setPlanTier(0)
    } catch (error: any) {
      console.log(error, error.response)
      addToast(handleAPIError(error, "downgrade to free plan"), {
        appearance: "error",
      })
    }
    setDowngradeLoading(false)
  }

  React.useEffect(() => {
    const getPlan = async () => {
      try {
        const response = await client.getEntries({
          content_type: "subscriptions",
        })

        const subscriptionsMaster: any = response?.items?.find(
          (item: any) => item?.fields?.name === "Subscriptions master"
        )
        setAllPlans(subscriptionsMaster?.fields?.plans)
        setPlan(
          subscriptionsMaster?.fields?.plans?.find((item: planType) => {
            if (planTier === 0) {
              // Free
              return item?.sys?.id === freePlanId
            } else if (planTier === 1) {
              // Premium
              return item?.sys?.id === premiumPlanId
            } else if (planTier === 2) {
              // Family
              return item?.sys?.id === familyPlanId
            } else {
              // Unknown?
              return "Can't find plan details"
            }
          })
        )
      } catch (error: any) {
        setError(error)
      }

      setLoading(false)
    }
    getPlan()

    return () => {
      setPlan({
        fields: {
          cost: 0,
          description: "",
          mostPopular: false,
          name: "",
          permissions: [],
        },
        metadata: {
          tags: [],
        },
        sys: {
          id: "",
        },
      })
    }
  }, [planTier]) //eslint-disable-line

  React.useEffect(() => {
    if (!loading) {
      setFreePlanMissedFeatures(
        getFreePlanMissedFeatures({
          plan: allPlans.find((item: planType) => {
            if (planTier === 1) {
              // Premium
              return item?.sys?.id === "4C5KYC2gZfCpCNpVYPIpZY"
            } else if (planTier === 2) {
              // Family
              return item?.sys?.id === "22XgSIw5qIzhg7pHROGzoE"
            } else {
              // Unknown?
              return "Can't find plan details"
            }
          }),
          allPlans: allPlans,
        })
      )
    }
    return () => {
      setFreePlanMissedFeatures([])
    }
  }, [allPlans, planTier, loading])

  return (
    <>
      <Helmet title={SEOTitleTemplate({ title: "Manage Your Subscription" })} />
      <section className="manage-subscription">
        <div className="manage-subscription-details">
          {loading || error ? (
            <Query
              additionalClasses="account-items-loader account-items-loader-subscription"
              loading={loading}
              error={error}
              action="load plan details"
            />
          ) : (
            <>
              <PlanHeader
                name={plan?.fields?.name}
                description={plan?.fields?.description}
                originalCost={plan?.fields?.cost}
                discountedCost={0}
                variant="manage-subscription"
                enterprise={planTier === 3}
              />
              <ButtonList>
                {planTier === 0 || planTier === undefined ? (
                  <Button to="/change-plan">Upgrade</Button>
                ) : planTier === 1 ? (
                  <>
                    <Button to="/change-plan">Upgrade</Button>
                    <Button
                      variant="secondary"
                      onClick={() => setDowngradeModalVisible(true)}
                    >
                      Downgrade to Free
                    </Button>
                  </>
                ) : planTier === 2 ? (
                  <>
                    <Button to="/change-plan">Change plan</Button>
                    <Button
                      variant="secondary"
                      onClick={() => setDowngradeModalVisible(true)}
                    >
                      Downgrade to Free
                    </Button>
                  </>
                ) : null}
              </ButtonList>
            </>
          )}
        </div>
        <WarningModal
          isVisible={downgradeModalVisible}
          dismissFunc={() => setDowngradeModalVisible(false)}
          title="Downgrade to Free?"
          deleteButtonText="Downgrade to Free"
          deleteFunc={() => downgradeToFreePlan()}
          deleteButtonLoading={downgradeLoading}
        >
          <>
            <p>You will no longer be able able to:</p>
            <ul className="warning-modal-body-list">
              {freePlanMissedFeatures.map((missedFeature, index) => (
                <li
                  key={missedFeature}
                  className="warning-modal-body-list-item"
                >
                  {missedFeature}
                  {index === freePlanMissedFeatures.length - 1 ? "" : ","}
                </li>
              ))}
            </ul>
          </>
        </WarningModal>
      </section>
    </>
  )
}
